import React, { useEffect, useState, useMemo, SetStateAction } from 'react'

import { ArrowDown } from '@Static/icons/HeaderIcon'
import { currenciesImages, ICurrenciesImages } from '@Static/images/currencies/'

import { useAppSelector, useAppDispatch } from '@App/hooks'

import { currentWallet } from '@Redux/slices/user/selectors'
import { setCurrWallet, userState } from '@Redux/slices/user/UserSlice'

import { IWallet } from '@Interfaces/CommonInterfaces'

import c from './WalletSwitcher.module.scss'

export function WalletSwitcher() {
  const dispatch = useAppDispatch()
  const currWallet = useAppSelector((state) => currentWallet(state))
  const userWallets = useAppSelector((state) => userState(state)?.user_info?.wallets)
  const [isOpen, setIsOpen] = useState(false)
  const [searchWallet, setSearchWallet] = useState('')
  const [fiatWallets, setFiatWallets] = useState<SetStateAction<any>>([])
  const [wallets, setWallets] = useState(fiatWallets)

  useMemo(() => {
    setFiatWallets(userWallets?.filter((wallet: IWallet) => wallet?.type === 'fiat'))
  }, [userWallets])

  useEffect(() => {
    const filterWallets = fiatWallets?.filter(
      (wallet: IWallet) => wallet?.currency_code?.toLowerCase().includes(searchWallet) && wallet?.currency_code,
    )

    setWallets(filterWallets)
  }, [searchWallet, fiatWallets])

  return (
    <div className={c.switcher} onMouseLeave={() => setIsOpen(false)}>
      <div className={c.text}>
        <span>Pay with</span>
      </div>

      <button className={`${c.dropButton} ${isOpen && c.active}`} onClick={() => setIsOpen(!isOpen)}>
        <img
          className={c.logo}
          src={currenciesImages[currWallet as keyof ICurrenciesImages] || currenciesImages.FIAT}
          alt="currency"
        />
        <span className={c.dropButtonText}>{currWallet}</span>
        <ArrowDown className={c.arrowRight} />
      </button>

      <div className={`${c.dropContent} ${isOpen && c.show}`}>
        <div className={c.searchContainer}>
          <input
            className={c.search}
            type="input"
            placeholder="Search"
            onChange={(e) => setSearchWallet(e.target.value)}
          />
        </div>
        {!!wallets?.length && (
          <ul className={c.list}>
            {wallets?.map((wallet: IWallet, i: number) => (
              <li
                key={i}
                className={`${c.listItem} ${currWallet === wallet?.currency_code && c.active} `}
                onClick={() => dispatch(setCurrWallet(wallet?.currency_code))}
              >
                <img
                  className={c.logo}
                  src={currenciesImages[wallet.currency_code] || currenciesImages.FIAT}
                  alt="currency"
                />

                <span>{wallet?.currency_code}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
