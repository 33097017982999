import { ConditionsType, TrialPriceType } from '@Redux/interfaces/loan'

import { getDataWrapper } from '@App/api/helper'
import { $authHost, $host } from '@App/api/index'
import { IDataResponse } from '@App/api/interfaces'

export const getLoanCurrenciesAsync = async (): Promise<IDataResponse<any[]>> =>
  await getDataWrapper($authHost.get(`api/loans/currencies/loanable`))

export const getCollateralCurrenciesAsync = async (): Promise<IDataResponse<any[]>> =>
  await getDataWrapper($authHost.get(`api/loans/currencies/collateral`))

export const getLoanConditionsAsync = async (params: object): Promise<IDataResponse<ConditionsType>> =>
  await getDataWrapper(
    $authHost.get(`api/loans/conditions`, {
      params: params,
    }),
  )

export const getLoanTrialCalcAsync = async (params: object): Promise<IDataResponse<TrialPriceType>> =>
  await getDataWrapper(
    $authHost.get(`api/loans/calculation`, {
      params: params,
    }),
  )
