import { SubscriberFunctionInterface } from './basic_connection'

export type ServiceClass = {
  subscribe: Function
  unsubscribe: Function
  name: string
}

export enum ServiceNames {
  PublicService = 'PublicService',
  ProxyServiceName = 'ProxyService',
}

export enum RequestTypes {
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

export enum Events {
  GetRate = 'get_quotes',
  GetTicker = 'ticker',
  GetOrderBook = 'get_orderbook',
  GetTrades = 'get_trades',
  GetUserEvent = 'get_user',
  UpdateToken = 'update_token',
  SocketStatusConnection = 'socket_status_connection',
}

export enum SocketState {
  Ready = 'ready',
  Closed = 'closed',
}

export interface ISubscribeMethodParams {
  params: string[]
  event: string
  callback: SubscriberFunctionInterface
}

export type IAllConnections = {
  [key: string]: IServiceNamesObject
}

export interface IConnections {
  [key: string]: IConnection
}

export interface IServiceNamesObject {
  callbacks: SubscriberFunctionInterface | null
  connections: IConnections
}

export interface ISubscriber {
  id: string
  type: string
  params: string[]
  service: ServiceClass
  event: string
  time: number
  key: string
  func: SubscriberFunctionInterface
}

export interface IConnection {
  key: string
  event: string
  params: string[]
  service: object
  subscribers: ISubscriber[]
  callback: SubscriberFunctionInterface
}

export interface ISocketStatusConnection {
  data: string
}
