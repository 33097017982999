import { AnyAction, AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getCollateralCurrenciesAsync, getLoanCurrenciesAsync } from '@App/api/loan'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>

interface P2PStateInterface {
  loanCurrencies: any[]
  collateralCurrencies: any[]
  isLoading: boolean
}

const initialState: P2PStateInterface = {
  loanCurrencies: [],
  collateralCurrencies: [],
  isLoading: false,
}

export const getLoanCurrencies = createAsyncThunk('loans/currencies/get', async () => {
  const data = await getLoanCurrenciesAsync()
  return data?.data ? { loanCurrencies: data.data } : { loanCurrencies: [] }
})

export const getCollateralCurrencies = createAsyncThunk('loans/collateral-currencies/get', async () => {
  const data = await getCollateralCurrenciesAsync()
  return data?.data ? { collateralCurrencies: data.data } : { collateralCurrencies: [] }
})

const isPendingAction = (action: AnyAction): action is PendingAction =>
  [getLoanCurrencies.pending.type, getCollateralCurrencies.pending.type].includes(action.type)

export const LoanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoanCurrencies.fulfilled, (state, action) => {
        state.loanCurrencies = action.payload.loanCurrencies
        state.isLoading = false
      })
      .addCase(getCollateralCurrencies.fulfilled, (state, action) => {
        state.collateralCurrencies = action.payload.collateralCurrencies
        state.isLoading = false
      })
      .addMatcher<PendingAction>(isPendingAction, (state) => {
        state.isLoading = true
      })
  },
})

export default LoanSlice.reducer
