import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getCountries } from '@App/api/config'
import { RootState } from '@App/redux/store'

import { ICountry } from './interfaces'

import type { PayloadAction } from '@reduxjs/toolkit'

const countriesSlice = createSlice({
  name: 'countries',
  initialState: {
    data: [],
    isLoading: false,
  } as {
    data: ICountry[]
    isLoading: boolean
  },
  reducers: {
    setCountries(state, action: PayloadAction<ICountry[]>) {
      state.data = action.payload
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountriesAsync.fulfilled, (state, action: any) => {
      state.data = action.payload
      state.isLoading = false
    })
    builder.addCase(getCountriesAsync.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCountriesAsync.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const getCountriesAsync = createAsyncThunk(
  'countries/get',
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    const stateCountries = (getState as () => RootState)().countries.data
    if (!stateCountries.length) {
      const countries = await getCountries()

      if (typeof countries === 'string') {
        return rejectWithValue('error')
      }

      return fulfillWithValue(countries)
    }
    return fulfillWithValue(stateCountries)
  },
)

export const isCountriesLoading = (state: RootState) => state.countries.isLoading
export default countriesSlice.reducer
