import React from 'react'

import cn from 'classnames'

import c from './LoaderCircle.module.scss'

export const LoaderCircle = ({ className = '' }) => {
  return (
    <div className={`${cn(c[className], c.preloader)}`}>
      <svg className={c.preloaderSvg} viewBox="0 0 100 100" preserveAspectRatio="none">
        <circle className={c.preloaderCircle} cx="50" cy="50" r="20" stroke="currentColor" fill="transparent" />
      </svg>
    </div>
  )
}
