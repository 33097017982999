import { BasicServiceConnection, SubscriberFunctionInterface } from '@App/sockets/basic_connection'
import * as types from '@App/sockets/types'
import { WebsocketClientInterface } from '@App/sockets/websocket_context'

const UserServiceDestination = 'go.user_service'

class ProxyServiceConnection extends BasicServiceConnection implements WebsocketClientInterface {
  public name: string = types.ServiceNames.ProxyServiceName
  public path: string = ''

  clearData(): void {
    this.token = ''
  }

  setApiUrl = (api_url: string) => {
    if (this.apiUrl !== api_url) {
      this.path = api_url
      this.apiUrl = api_url
      this.initialize = true

      if (this.token) {
        this.apiUrl = this.path + '?token=' + this.token
      }
    }
  }

  getUserEventSocketMessage(request_type: string) {
    return JSON.stringify({
      request_key: '' + window.performance.now(),
      destination: UserServiceDestination,
      request_type: request_type,
      event: 'get_user',
      payload: {},
    })
  }

  getUpdateTokenSocketMessage(token: string) {
    return JSON.stringify({
      request_key: '' + window.performance.now(),
      destination: 'pubsub',
      request_type: 'request',
      event: 'update_token',
      payload: { token },
    })
  }

  updateTokenActions(token: string) {
    this.isConnected() &&
      this.getConnection().send(
        JSON.stringify({
          request_key: '' + window.performance.now(),
          destination: 'pubsub',
          request_type: 'request',
          event: 'update_token',
          payload: { token },
        }),
      )
  }

  subscribe([token]: string[], event: string, func: SubscriberFunctionInterface): void {
    try {
      if (this.token !== token && token) {
        this.token = token
        if (this.path) {
          this.apiUrl = this.path + '?token=' + token
        }
      }

      if (!this.isConnected() && !this.connection) {
        this.connection = this.establishConnection(func)
      }

      switch (event) {
        case types.Events.GetUserEvent:
          this.connected().then(() => {
            this.getConnection().send(this.getUserEventSocketMessage(types.RequestTypes.Subscribe))
          })
          break
        case types.Events.UpdateToken:
          this.connected().then(() => {
            this.getConnection().send(this.getUpdateTokenSocketMessage(token))
          })
          break
      }
    } catch (error) {
      console.log(error)
    }
  }

  unsubscribe(_: string[], event: string): void {
    if (this.connection) {
      switch (event) {
        case types.Events.GetUserEvent:
          this.connected().then(() => {
            if (this.connection.readyState === 1) {
              this.getConnection().send(this.getUserEventSocketMessage(types.RequestTypes.Unsubscribe))
            }
          })
          break
      }
    }
  }
}

export default new ProxyServiceConnection()
