import { IBackNotification } from '@Redux/interfaces'

import { $authHost, $host } from '@App/api'
import { getDataWrapper } from '@App/api/helper'
import { IDataResponse } from '@App/api/interfaces'
import requestByFilters, { ParamsByFilter } from '@App/helpers/requestByFilters'

export const getNotificationsData = async (
  filters: ParamsByFilter[] = [],
): Promise<IDataResponse<IBackNotification[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters, true)}` : ''
  return await getDataWrapper($authHost.get(`api/me/notifications${filterString}`))
}

export const checkNotification = async (id: string) =>
  await getDataWrapper($authHost.patch(`api/me/notifications/${id}`))
