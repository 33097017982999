import { SVGProps } from 'react'

export function MenuIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M4 4H8V8H4V4ZM4 10H8V14H4V10ZM8 16H4V20H8V16ZM10 4H14V8H10V4ZM14 10H10V14H14V10ZM10 16H14V20H10V16ZM20 4H16V8H20V4ZM16 10H20V14H16V10ZM20 16H16V20H20V16Z" />
    </svg>
  )
}

export function ArrowDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M16.5 8.49023V10.7402L12 15.5102L7.5 10.7402V8.49023H16.5Z" />
    </svg>
  )
}

export function LightThemeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M10.5 2H13.5V5H10.5V2ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM5.98948 3.86891L3.86816 5.99023L5.98948 8.11155L8.1108 5.99023L5.98948 3.86891ZM2 13.5V10.5H5V13.5H2ZM3.86794 18.0095L5.98926 20.1309L8.11058 18.0095L5.98926 15.8882L3.86794 18.0095ZM13.5 19V22H10.5V19H13.5ZM18.01 15.8884L15.8887 18.0098L18.01 20.1311L20.1313 18.0098L18.01 15.8884ZM19 10.5H22V13.5H19V10.5ZM15.8894 5.99001L18.0107 8.11133L20.1321 5.99001L18.0107 3.86869L15.8894 5.99001Z" />
    </svg>
  )
}

export function DarkThemeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M20.9677 12.7676C19.84 13.5449 18.4732 13.9999 17 13.9999C13.134 13.9999 10 10.8659 10 6.99994C10 5.52678 10.4551 4.15991 11.2323 3.03223C6.62108 3.42175 3 7.28797 3 11.9999C3 16.9705 7.02944 20.9999 12 20.9999C16.712 20.9999 20.5782 17.3789 20.9677 12.7676Z" />
    </svg>
  )
}

export function RegisterIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M13.5 6.379V3h-3v3.379l-2.94-2.94-2.12 2.122L7.878 8H4v3h6.75V8h2.5v3H20V8h-3.879l2.44-2.44-2.122-2.12L13.5 6.378zM4 13.5V20h6.75v-6.5H4zM13.25 20H20v-6.5h-6.75V20z"
        fill="currentColor"
      />
    </svg>
  )
}

export function ArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M21 11.9988L13.9289 4.92773L12.1612 6.6955L16.2157 10.75L2.99902 10.75L2.99902 13.25L16.2145 13.25L12.1612 17.3033L13.9289 19.0711L21 12L20.9994 11.9994L21 11.9988Z" />
    </svg>
  )
}

export function SpotGIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M21.5 8.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0z" fill="#76808F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 9.5a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm0 8.5L6 15.5 8.5 13l2.5 2.5L8.5 18z"
        fill="#F8D33A"
      />
      <path d="M9 3H3v6l6-6z" fill="#F8D33A" />
      <path d="M15 21h6v-6l-6 6z" fill="#F8D33A" />
    </svg>
  )
}

export function BinanceConvertIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5a7 7 0 1 1 0 14 7 7 0 0 1 0-14zm0 10l-3-3 3-3 3 3-3 3z"
        fill="#F8D33A"
      />
      <path d="M15 3h6v6l-6-6z" fill="#76808F" />
      <path d="M9 21H3v-6l6 6z" fill="#76808F" />
    </svg>
  )
}

export const AccountIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width={24} height={24} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM14.5 9.5C14.5 10.8807 13.3807 12 12 12C10.6193 12 9.5 10.8807 9.5 9.5C9.5 8.11929 10.6193 7 12 7C13.3807 7 14.5 8.11929 14.5 9.5ZM12 13.9961H8.66662C7.97115 13.9961 7.37518 14.7661 7.12537 15.5161C7.73252 16.4634 9.45831 17.9803 12 18.0023C14.5416 18.0243 16.3061 16.3616 16.8745 15.5161C16.6247 14.7661 16.0288 13.9961 15.3333 13.9961H12Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const CreditCardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <g>
        <path transform="matrix(1 0 0 -1 2 20)" fill="#F0B90B" d="M0 0h20v16H0z" />
        <path fill="#76808F" d="M2 7h20v3H2z" />
        <path fill="#76808F" d="M17 16h3v2h-3z" />
        <path fill="#76808F" d="M4 16h11v2H4z" />
      </g>
    </svg>
  )
}

export const BankIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path fill="#76808F" d="M5 11h2v8H5z"></path>
      <path fill="#76808F" d="M9 11h2v8H9z"></path>
      <path fill="#76808F" d="M13 11h2v8h-2z"></path>
      <path fill="#76808F" d="M17 11h2v8h-2z"></path>
      <path d="M12 2l9 7v2H3V9l9-7z" fill="url(#paint0_linear)"></path>
      <path d="M3 22v-3h18v3H3z" fill="url(#paint1_linear)"></path>
      <circle cx="12" cy="7" r="2" fill="#76808F"></circle>
      <defs>
        <linearGradient id="paint0_linear" x1="3" y1="12" x2="21" y2="12" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F0B90B"></stop>
          <stop offset="1" stopColor="#F8D33A"></stop>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="3" y1="12" x2="21" y2="12" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F0B90B"></stop>
          <stop offset="1" stopColor="#F8D33A"></stop>
        </linearGradient>
      </defs>
    </g>
  </svg>
)

export const NotificationsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C8.13401 3 5 6.13401 5 10L5 13L4 14V16H5L19 16H20L20 14L19 13V10C19 6.13401 15.866 3 12 3ZM12 21C9.94965 21 8.18757 19.7659 7.41602 18H16.5839C15.8124 19.7659 14.0503 21 12 21Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const DiamondIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <g>
        <path d="M11.991 3H3.974L0 6.98 7.991 15 16 6.98 11.991 3z" fill="url(#paint0_linear)"></path>
        <defs>
          <linearGradient id="paint0_linear" x1="8" y1="3" x2="8" y2="15" gradientUnits="userSpaceOnUse">
            <stop stopColor="#F8D12F"></stop>
            <stop offset="1" stopColor="#F0B90B"></stop>
          </linearGradient>
        </defs>
      </g>
    </svg>
  )
}

export const CertificateIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5H21V19H3V5ZM10 10.0003C10 11.1048 9.10457 12.0003 8 12.0003C6.89543 12.0003 6 11.1048 6 10.0003C6 8.8957 6.89543 8.00027 8 8.00027C9.10457 8.00027 10 8.8957 10 10.0003ZM13 9.00027H19V11.5003H13V9.00027ZM19 13.4997H13V15.9997H19V13.4997ZM11 13.4997V15.9997H5V13.4997H11Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const HamburgerIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4H20V7H4V4ZM4 10.5H20V13.5H4V10.5ZM20 17H4V20H20V17Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        d="M6.69806 4.57538L4.57674 6.6967L9.88004 12L4.57674 17.3033L6.69806 19.4246L12.0014 14.1213L17.3047 19.4246L19.426 17.3033L14.1227 12L19.426 6.6967L17.3047 4.57538L12.0014 9.87868L6.69806 4.57538Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const LogOutIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.22266 6V3H6.22266H11.7227V6L6.22266 6L6.22266 18H11.7227V21H6.22266H3.22266V18V6ZM8.59134 13.5V10.5L15.1783 10.5L15.1782 5.98418L21.2079 12L15.1783 17.9797L15.1783 13.5H8.59134Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const WalletIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 8.5C4 6.01472 6.01472 4 8.5 4H20V20H8.5C6.01472 20 4 17.9853 4 15.5V8.5ZM8.5 7H17V10H8.5C7.67157 10 7 9.32843 7 8.5C7 7.67157 7.67157 7 8.5 7ZM13 13H17V17H13V13Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const DashboardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8ZM8 14C5.79086 14 4 15.7909 4 18V20H20V18C20 15.7909 18.2091 14 16 14H8Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const OrdersIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19V21L15.5 18L12 21L8.5 18L5 21V3ZM8 7H12V9.5H8V7ZM12 12H8V14.5H12V12ZM14 7H16.5V9.5H14V7ZM16.5 12H14V14.5H16.5V12Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const BitcoinIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM14.1642 18.5H12.5052V16.9617H11.4948V18.5H9.83585V16.9617H8.32773V7.03828H9.83585V5.5H11.4948V7.03828H12.5052V5.5H14.1642V7.08353C15.4611 7.29466 16.2152 8.2297 16.2152 9.58701C16.2152 10.9594 15.5969 11.5476 14.2848 11.7135V11.8039C15.6572 11.8794 16.5922 12.5731 16.5922 14.1265C16.5922 15.6497 15.5516 16.8863 14.1642 16.9617V18.5ZM13.0632 8.86311H10.4843V11.1102H13.0632C13.6363 11.1102 13.9832 10.8086 13.9832 10.2355V9.73782C13.9832 9.16473 13.6363 8.86311 13.0632 8.86311ZM13.4252 12.8596H10.4843V15.1369H13.4252C13.9983 15.1369 14.3602 14.8202 14.3602 14.2471V13.7494C14.3602 13.1763 13.9983 12.8596 13.4252 12.8596Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const TradeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0002 3.5C12.471 3.5 10.3406 5.20717 9.6983 7.53206C13.2833 7.86295 16.1373 10.7169 16.4682 14.3019C18.7931 13.6597 20.5002 11.5292 20.5002 9C20.5002 5.96243 18.0378 3.5 15.0002 3.5ZM14.5 15C14.5 11.9624 12.0376 9.5 9 9.5C5.96243 9.5 3.5 11.9624 3.5 15C3.5 18.0376 5.96243 20.5 9 20.5C12.0376 20.5 14.5 18.0376 14.5 15ZM6.5 15L9 17.5L11.5 15L9 12.5L6.5 15Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const ThemeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        d="M5.41406 12L2.71875 14.6953L0 12L2.71875 9.28125L5.41406 12ZM12 5.41406L16.6406 10.0547L19.3594 7.33594L12 0L4.64062 7.35938L7.35938 10.0781L12 5.41406ZM21.2812 9.28125L18.5859 12L21.3047 14.7188L24.0234 12L21.2812 9.28125ZM12 18.5859L7.35938 13.9219L4.64062 16.6406L12 24L19.3594 16.6406L16.6406 13.9219L12 18.5859ZM12 14.6953L14.7188 11.9766L12 9.28125L9.28125 12L12 14.6953Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const LanguageIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2307 20.4027C18.2316 19.2481 20.4577 16.5321 20.9137 13.25H16.9718C16.8248 16.1102 16.1791 18.638 15.2307 20.4027ZM14.473 13.25C14.2952 17.3518 13.2556 20.5 11.9998 20.5C10.744 20.5 9.70447 17.3518 9.52667 13.25H14.473ZM14.473 10.75H9.52667C9.70447 6.64821 10.744 3.5 11.9998 3.5C13.2556 3.5 14.2952 6.64821 14.473 10.75ZM16.9718 10.75H20.9137C20.4577 7.46786 18.2316 4.75191 15.2307 3.59731C16.1791 5.36198 16.8248 7.88979 16.9718 10.75ZM7.03566 10.75C7.18282 7.88774 7.82928 5.35836 8.77882 3.59353C5.77291 4.74598 3.54249 7.46427 3.08594 10.75H7.03566ZM7.03566 13.25H3.08594C3.54249 16.5357 5.77291 19.254 8.77882 20.4065C7.82928 18.6416 7.18282 16.1123 7.03566 13.25Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const DollarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        d="M11.3363 8.57657C10.5672 8.72738 10.2354 9.13457 10.2354 9.72274C10.2354 10.2958 10.5672 10.5974 11.3363 10.8086V8.57657Z"
        fill="currentColor"
      ></path>
      <path
        d="M12.6785 15.3933C13.3873 15.1972 13.7191 14.7297 13.7191 14.1566C13.7191 13.7193 13.5382 13.2668 12.6785 13.0104V15.3933Z"
        fill="currentColor"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12.8595 17.0522V18.5H11.1402V17.1125C9.61705 16.9768 8.60661 16.3585 7.85255 15.529L9.20985 14.2923C9.78294 14.8956 10.4918 15.3028 11.3363 15.4234V12.7088C9.07412 12.2413 8.15417 11.2459 8.15417 9.78306C8.15417 8.19954 9.19477 7.14385 11.1402 6.91763V5.5H12.8595V6.91763C14.066 7.05336 14.9558 7.52088 15.6194 8.27494L14.247 9.5116C13.885 9.10441 13.4024 8.77262 12.6785 8.60673V11.1102C14.7899 11.5928 15.8003 12.4524 15.8003 13.9907C15.8003 15.4687 14.8653 16.7204 12.8595 17.0522Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const MarketsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="css-6px2js" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 4H14.5V19H9.5V4ZM3 11H7.5V19H3V11ZM21 9H16.5V19H21V9Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const ModeLightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 2H13.5V5H10.5V2ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM5.98948 3.86891L3.86816 5.99023L5.98948 8.11155L8.1108 5.99023L5.98948 3.86891ZM2 13.5V10.5H5V13.5H2ZM3.86794 18.0095L5.98926 20.1309L8.11058 18.0095L5.98926 15.8882L3.86794 18.0095ZM13.5 19V22H10.5V19H13.5ZM18.01 15.8884L15.8887 18.0098L18.01 20.1311L20.1313 18.0098L18.01 15.8884ZM19 10.5H22V13.5H19V10.5ZM15.8894 5.99001L18.0107 8.11133L20.1321 5.99001L18.0107 3.86869L15.8894 5.99001Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export const ModeDarkIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
      <path
        d="M20.9677 12.7676C19.84 13.5449 18.4732 13.9999 17 13.9999C13.134 13.9999 10 10.8659 10 6.99994C10 5.52678 10.4551 4.15991 11.2323 3.03223C6.62108 3.42175 3 7.28797 3 11.9999C3 16.9705 7.02944 20.9999 12 20.9999C16.712 20.9999 20.5782 17.3789 20.9677 12.7676Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
export const P2PGIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M6.5 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" fill="#F8D33A"></path>
        <path d="M17.5 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="#76808F"></path>
        <path d="M2 13a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v3H2v-3z" fill="#76808F"></path>
        <path d="M13 17a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v3h-9v-3z" fill="#F8D33A"></path>
        <path d="M13 4V2h9v4h-2V4h-7z" fill="#76808F"></path>
        <path d="M11 22v-2H4v-2H2v4h9z" fill="#76808F"></path>
      </g>
    </svg>
  )
}

export const LoanIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M11.0552 5.0552L7 1H17L12.9448 5.0552C16.9178 5.52265 20 8.90138 20 13V22H4V13C4 8.90138 7.0822 5.52265 11.0552 5.0552Z"
          fill="#76808F"
        ></path>
        <path
          d="M12 7C15.3137 7 18 9.68629 18 13C18 16.3137 15.3137 19 12 19C8.68629 19 6 16.3137 6 13C6 9.68629 8.68629 7 12 7ZM12 10.5L9.5 13L12 15.5L14.5 13L12 10.5Z"
          fill="#F8D33A"
        ></path>
      </g>
    </svg>
  )
}

export const FinanceIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M17.4123 19.1912C15.906 20.3267 14.0316 20.9998 12 20.9998C7.02944 20.9998 3 16.9704 3 11.9998C3 7.45343 6.37108 3.69438 10.75 3.08594V8.19612C9.15149 8.72112 7.99725 10.2257 7.99725 11.9998C7.99725 14.2105 9.78934 16.0026 12 16.0026C12.6466 16.0026 13.2574 15.8493 13.7981 15.577L17.4123 19.1912ZM19.1817 17.425C20.323 15.9165 21 14.0372 21 11.9998C21 7.45343 17.6289 3.69438 13.25 3.08594V8.19612C14.8485 8.72112 16.0028 10.2257 16.0028 11.9998C16.0028 12.6524 15.8466 13.2686 15.5695 13.8129L19.1817 17.425Z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  )
}
