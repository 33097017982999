import { FC } from 'react'

import { NavLink } from 'react-router-dom'

import { ArrowRight } from '@App/static/icons/HeaderIcon'

import { ILinkItem } from '@UI/crypto_exchange/desktop/components/common/Header/DropDownLinkData'

import classes from './DropDownMenuItem.module.scss'

export interface IDropDownMenuItemProps {
  item: ILinkItem
  className?: string
}

export const DropDownMenuItem: FC<IDropDownMenuItemProps> = ({ item }) => {
  return (
    <NavLink to={item?.link ? item.link : '/'}>
      <li className={classes.menuLink}>
        <div className={classes.linkIcon}>{item?.icon}</div>
        <div className={classes.linkText}>
          <div className={classes.link}>{item?.title}</div>
          <div className={classes.subtitle}>{item?.subtitle}</div>
        </div>
        <ArrowRight className={classes.arrowRight} />
      </li>
    </NavLink>
  )
}
