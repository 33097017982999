import { $host } from '@App/api'
import { getDataWrapper } from '@App/api/helper'

import { ITermsAndConditions } from '@Interfaces/CommonInterfaces'

export const getUnreadTermsAndConditions = async (): Promise<ITermsAndConditions> => {
  return await getDataWrapper($host.get(`api/terms-and-conditions?language_id=1`))
}

export const patchTermsAndConditionsStatus = async (status_id: number, status: string): Promise<void> => {
  await getDataWrapper($host.patch(`api/terms-and-conditions/status/${status_id}`, { status }))
}

export const createTermsAndConditionsStatus = async (term_id: number, status: string): Promise<void> => {
  await getDataWrapper($host.post(`api/terms-and-conditions/status`, { term_id, status }))
}
