import { ReactElement } from 'react'

import { UserIcon, SecurityIcon } from '@Static/icons/AccountMenuIcon'
import {
  BuyCryptoHistoryIcon,
  ConvertHistoryIcon,
  EarnHistoryIcon,
  MarginOrderIcon,
  OrderIcon,
  P2POrderIcon,
  PaymentHistoryIcon,
} from '@Static/icons/AccountOrdersPageIcons'
import { BankIcon, BinanceConvertIcon, CreditCardIcon, LoanIcon, P2PGIcon, SpotGIcon } from '@Static/icons/HeaderIcon'
import {
  AccountMarginWalletsIcon,
  FiatAndSpotLinkIcon,
  FundingLinkIcon,
  OverviewLinkIcon,
  TransactionHistoryLinkIcon,
} from '@Static/icons/WalletPageIcons'

import { replaceVarsInPath, ROUTE_NAMES } from '@UI/crypto_exchange/desktop/routes/routeNames'

export interface ILinkItem {
  title: string
  subtitle: string | null
  icon: ReactElement
  link?: string
}

export interface IDropDownMenuLinks {
  buyCrypto: ILinkItem[]
  trade: ILinkItem[]
  wallet: ILinkItem[]
  orders: ILinkItem[]
  login: ILinkItem[]
}

export const dropDownMenuLinks = {
  buyCrypto: [
    {
      title: 'Bank deposit',
      subtitle: 'Bank transfer',
      icon: <BankIcon />,
      link: ROUTE_NAMES.FIAT_DEPOSIT,
    },
  ],
  trade: [
    {
      title: 'Spot',
      subtitle: 'Trade crypto with advanced tools',
      icon: <SpotGIcon />,
      link: `${replaceVarsInPath(ROUTE_NAMES.SPOT_WITH_PAIR, ['BTC', 'USDT'])}?type=spot`,
    },
    {
      title: 'Payways Convert',
      subtitle: 'The easiest way to trade',
      icon: <BinanceConvertIcon />,
      link: replaceVarsInPath(ROUTE_NAMES.EXCHANGE_WITH_PAIR, ['BTC', 'USDT']),
    },
    {
      title: 'Margin Trading',
      subtitle: 'Trade with margin',
      icon: <BinanceConvertIcon />,
      link: `${replaceVarsInPath(ROUTE_NAMES.SPOT_WITH_PAIR, ['BTC', 'USDT'])}?type=margin`,
    },
    {
      title: 'P2P',
      subtitle: 'Bank transfer and 100+ options',
      icon: <P2PGIcon />,
      link: `${replaceVarsInPath(ROUTE_NAMES.P2P_WITH_PAIR, ['buy', 'USDT', 'USD'])}`,
    },
  ],
  earn: [
    {
      title: 'Simple Earn',
      subtitle: 'Earn daily rewards on your idle tokens',
      icon: <BinanceConvertIcon />,
      link: ROUTE_NAMES.SIMPLE_EARN,
    },
  ],
  finance: [
    {
      title: 'Loans',
      subtitle: 'Get an instant loan secured by crypto assets',
      icon: <LoanIcon />,
      link: ROUTE_NAMES.LOANS,
    },
  ],
  wallet: [
    {
      title: 'Overview',
      subtitle: null,
      icon: <OverviewLinkIcon width={20} />,

      link: ROUTE_NAMES.DASHBOARD,
    },
    {
      title: 'Fiat and Sport',
      subtitle: '(Deposit & Withdraw)',
      icon: <FiatAndSpotLinkIcon width={20} />,
      link: ROUTE_NAMES.WALLET_MAIN,
    },
    {
      title: 'Margin',
      subtitle: null,
      icon: <AccountMarginWalletsIcon width={20} />,
      link: ROUTE_NAMES.CROSS_MARGIN,
    },
    {
      title: 'Transaction History',
      subtitle: null,
      icon: <TransactionHistoryLinkIcon />,
      link: ROUTE_NAMES.HISTORY_DEPOSIT_CRYPTO,
    },
    {
      title: 'Funding Wallet',
      subtitle: null,
      icon: <FundingLinkIcon width={20} />,
      link: ROUTE_NAMES.WALLET_FUNDING,
    },
    {
      title: 'Earn',
      subtitle: null,
      icon: <EarnHistoryIcon width={20} />,
      link: ROUTE_NAMES.WALLET_EARN,
    },
  ],
  orders: [
    {
      title: 'Spot Order',
      subtitle: null,
      icon: <OrderIcon />,

      link: ROUTE_NAMES.ACCOUNT_SPOT_ORDER_OPEN,
    },
    {
      title: 'Margin Order',
      subtitle: null,
      icon: <MarginOrderIcon />,

      link: ROUTE_NAMES.ACCOUNT_MARGIN_ORDER_OPEN,
    },
    {
      title: 'Buy Crypto History',
      subtitle: '(Deposit & Withdraw)',
      icon: <BuyCryptoHistoryIcon />,

      link: ROUTE_NAMES.ACCOUNT_SPOT_ORDER_BUY_CRYPTO_HISTORY,
    },

    {
      title: 'P2P Orders',
      icon: <P2POrderIcon />,

      link: ROUTE_NAMES.P2P_ORDERS,
    },
    {
      title: 'Convert History',
      subtitle: null,
      icon: <ConvertHistoryIcon />,

      link: ROUTE_NAMES.ACCOUNT_SPOT_ORDER_CONVERT_HISTORY,
    },
    {
      title: 'Payment History',
      subtitle: null,
      icon: <PaymentHistoryIcon />,

      link: ROUTE_NAMES.PAYMENT_HISTORY,
    },
    {
      title: 'Loan History',
      subtitle: null,
      icon: <LoanIcon />,

      link: ROUTE_NAMES.ACCOUNT_LOAN_ORDER_OPEN,
    },
    {
      title: 'Earn History',
      subtitle: null,
      icon: <EarnHistoryIcon />,

      link: ROUTE_NAMES.SIMPLE_EARN_HISTORY,
    },
  ],
  login: [
    {
      title: 'Dashboard',
      subtitle: null,
      icon: <UserIcon />,
      link: ROUTE_NAMES.DASHBOARD,
    },
    {
      title: 'Security',
      subtitle: null,
      icon: <SecurityIcon />,
      link: ROUTE_NAMES.SECURITY,
    },
  ],
}
