import { FC } from 'react'

import { AccountIcon, CertificateIcon, DiamondIcon, RegisterIcon } from '@Static/icons/HeaderIcon'

import { useAppDispatch, useAppSelector } from '@App/hooks'

import { asyncLogout } from '@Redux/slices//user/UserSlice'

import { ILinkItem } from '@UI/crypto_exchange/desktop/components/common/Header/DropDownLinkData'

import { DropDownMenuItemRightMenu } from '../DropDownMenuItemRightMenu'
import dropDownMenuItemClasses from '../DropDownMenuItemRightMenu/DropDownMenuItemRightMenu.module.scss'

import classes from './LoginButton.module.scss'

interface ILoginButtonProps {
  menuLinks: Array<ILinkItem>
  handleLogout?: any
}

export const LoginButton: FC<ILoginButtonProps> = ({ menuLinks, handleLogout }) => {
  const email = useAppSelector((state) => state?.user?.user_info && state?.user?.user_info?.email)
  const dispatch = useAppDispatch()

  const logoutClick = () => {
    dispatch(asyncLogout())
    handleLogout()
  }

  const hiddenEmail = (email: string | null) => {
    if (email) {
      const [login, domen] = email.split('@')
      return `${login.slice(0, 2)}***@${domen}`
    }
    return null
  }

  return (
    <div className={classes.loginButtonContainer}>
      <div className={classes.iconContainer}>
        <AccountIcon />
      </div>
      <div className={classes.dropDownMenu}>
        <div className={classes.dropDownMenuTop}>
          <div className={classes.dropDownMenuEmail}>{hiddenEmail(email)}</div>
          <div className={classes.dropDownMenuLabels}>
            <div className={classes.dropDownMenuLabelDiamond}>
              <DiamondIcon width={18} height={18} />
              <span>Regular User</span>
            </div>
            <div className={classes.dropDownMenuLabelVerified}>
              <CertificateIcon width={16} height={16} />
              <span>Unverified</span>
            </div>
          </div>
        </div>
        <ul className={classes.dropDownMenuBottom}>
          {menuLinks.map((link: ILinkItem, index: number) => {
            return <DropDownMenuItemRightMenu key={index} item={link} />
          })}

          <li onClick={logoutClick} className={`${dropDownMenuItemClasses.dropDownMenuItem} ${classes.borderTop}`}>
            <div className={dropDownMenuItemClasses.dropDownMenuItemIcon}>
              <RegisterIcon />
            </div>
            <div className={dropDownMenuItemClasses.dropDownMenuItemText}>Log Out</div>
          </li>
        </ul>
      </div>
    </div>
  )
}
