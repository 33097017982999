import { IConnection, IAllConnections, ISubscriber, ServiceNames } from '@App/sockets/types'

import { SubscriberFunctionInterface } from './basic_connection'

class Connections {
  private all_connections: IAllConnections = {
    [ServiceNames.PublicService]: {
      callbacks: null,
      connections: {},
    },
    [ServiceNames.ProxyServiceName]: {
      callbacks: null,
      connections: {},
    },
  }

  getDataByServiceName(service_name: string) {
    return this.all_connections[service_name] ? this.all_connections[service_name] : null
  }

  getConnection(service_name: string, key: string) {
    return this.all_connections[service_name].connections[key]
  }

  getConnectionsByServiceName(service_name: string) {
    return this.all_connections[service_name] ? this.all_connections[service_name].connections : {}
  }

  isConnectionsByServiceName(service_name: string) {
    return Object.keys(this.all_connections[service_name].connections).length === 0 ? false : true
  }

  addConnection(service_name: string, connection_key: string, new_connection: IConnection) {
    this.all_connections[service_name].connections[connection_key] = new_connection
  }

  deleteConnection(service_name: string, connection_key: string) {
    delete this.all_connections[service_name].connections[connection_key]
    this.all_connections[service_name].callbacks = null
  }

  addSubscriber(service_name: string, connection_key: string, new_subscriber: ISubscriber) {
    this.all_connections[service_name].connections[connection_key].subscribers.push(new_subscriber)
  }

  deleteSubscriber(service_name: string, connection_key: string) {
    this.all_connections[service_name].connections[connection_key].subscribers.length &&
      this.all_connections[service_name].connections[connection_key].subscribers.splice(0, 1)
  }

  getSubscribersByConnection(service_name: string, connection_key: string) {
    return this.all_connections[service_name].connections[connection_key]?.subscribers || []
  }

  addFunctionSubscriber(service_name: string, func: SubscriberFunctionInterface) {
    this.all_connections[service_name].callbacks = func
  }
}

export const connections = new Connections()
