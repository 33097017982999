import { RootState } from '@Redux/store'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getSimpleEarns, subscribeSimpleEarn } from '@App/api/earn'

import { ISimpleEarn, ISimpleEarnEntitySubscribe } from '@Redux/slices/earn/simple_earn/interfaces'

const simpleEarnsSlice = createSlice({
  name: 'simpleEarns',
  initialState: {
    data: [],
    isLoading: false,
  } as {
    data: ISimpleEarn[]
    isLoading: boolean
  },
  reducers: {
    setSimpleEarns(state, action: PayloadAction<ISimpleEarn[]>) {
      state.data = action.payload
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSimpleEarnsAsync.fulfilled, (state, action: any) => {
      state.data = action.payload
      state.isLoading = false
    })
    builder.addCase(getSimpleEarnsAsync.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSimpleEarnsAsync.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const getSimpleEarnsAsync = createAsyncThunk(
  'simple-earns/get',
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    const stateSimpleEarns = (getState as () => RootState)().simple_earns.data

    if (!stateSimpleEarns.length) {
      const simpleEarns = await getSimpleEarns()
      if (typeof simpleEarns === 'string') {
        return rejectWithValue('error')
      }

      return fulfillWithValue(simpleEarns)
    }
    return fulfillWithValue(stateSimpleEarns)
  },
)

export const subscribeSimpleEarnAsync = createAsyncThunk(
  'simple-earns/subscribe',
  async (params: ISimpleEarnEntitySubscribe) => {
    return await subscribeSimpleEarn(params)
  },
)

export const isSimpleEarnsLoading = (state: RootState) => state.simple_earns.isLoading

export default simpleEarnsSlice.reducer
