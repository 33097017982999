import { FC, useEffect } from 'react'

import { useAppDispatch } from '@App/hooks'
import { Info, Success, Warning } from '@App/static/icons/ToastIcons'

import { activateNotification } from '@Redux/slices//notifications/NotificationSlice'

import { Toast as ToastType } from '../types'

import classes from './Toast.module.scss'

export interface IToast extends ToastType {
  onClose: (id: string) => void
  delay?: number
}

const Toast: FC<IToast> = ({ id, content, type, config: { backgroundColor, color } = {}, onClose, delay }) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(activateNotification({ delay, id }))
  }, [id, delay])

  return (
    <div className={`${classes.toast} ${type}`} style={{ backgroundColor }}>
      <div
        className={`${classes.border} ${
          type === 'info'
            ? classes.info
            : type === 'default'
            ? classes.default
            : type === 'error'
            ? classes.error
            : classes.success
        }`}
      ></div>
      <div
        className={`${classes.iconContainer} ${
          type === 'info'
            ? classes.info
            : type === 'default'
            ? classes.default
            : type === 'error'
            ? classes.error
            : classes.success
        }`}
      >
        {type === 'info' ? (
          <Info color="#bebebe" width={17} />
        ) : type === 'default' ? (
          <Success color="#bebebe" width={17} />
        ) : type === 'error' ? (
          <Warning color="#bebebe" width={17} />
        ) : (
          <Success color="#bebebe" width={17} />
        )}
      </div>
      <div></div>
      <div className={classes.content}>
        <p style={{ color }}>{content}</p>
      </div>
      <div className={classes.close} onClick={() => onClose(id)}></div>
    </div>
  )
}

export default Toast
