import { useEffect, useState } from 'react'

import { useAppDispatch } from '@App/hooks'

import { setConfig } from '@Redux/slices/config/ConfigSlice'
import { checkAsync, logout } from '@Redux/slices/user/UserSlice'

import { Notifications } from '@UI/crypto_exchange/desktop/components/common/Notifications'

import { Header } from './components/common/Header'
import { Loader } from './components/common/Loader'
import Portal from './components/common/Notifications/container'
import ModalContainer from './components/modals/ModalContainer/ModalContainer'
import { RouterContainer } from './routes/RouterContainer'

export function App() {
  const [loading, setLoading] = useState(true)

  const dispatch = useAppDispatch()

  const logoutApp = () => {
    dispatch(logout())
  }

  useEffect(() => {
    dispatch(setConfig())
      .then(() => dispatch(checkAsync()))
      .finally(() => setLoading(false))

    window.addEventListener('logout_global', logoutApp)
    return () => {
      window.removeEventListener('logout_global', logoutApp)
    }
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <div className={'page-wrapper'}>
        <Header />
        <RouterContainer />
      </div>
      <Portal>
        <Notifications delay={5000} />
      </Portal>

      <ModalContainer />
    </>
  )
}
