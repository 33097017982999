import { configureStore } from '@reduxjs/toolkit'

import configReducer from '@Redux/slices/config/ConfigSlice'
import countriesReducer from '@Redux/slices/config/CountriesSlice'
import timezonesReducer from '@Redux/slices/config/TimezonesSlice'
import simpleEarnsReducer from '@Redux/slices/earn/simple_earn/SimpleEarnsSlice'
import loanReducer from '@Redux/slices/finance/loan/loanSlice'
import ModalReducer from '@Redux/slices/modals/ModalSlice'
import NotificationSlice from '@Redux/slices/notifications/NotificationSlice'
import exchangeOrdersReducer from '@Redux/slices/profile/exchange_order/ExchangeOrderSlice'
import InvoicesReducer from '@Redux/slices/profile/invoices/InvoiceSlice'
import loanAccountReducer from '@Redux/slices/profile/loan/LoanSlice'
import userNotificationSlice from '@Redux/slices/profile/notification/NotificationSlice'
import P2PAccountReducer from '@Redux/slices/profile/p2p/P2PProfileSlice'
import PaymentGatewaysReducer from '@Redux/slices/profile/payment/PaymentGatewaysSlice'
import spotOrdersReducer from '@Redux/slices/profile/spot_order/SpotOrderSlice'
import TransactionsReducer from '@Redux/slices/profile/transactions/TransactionSlice'
import WalletsReducer from '@Redux/slices/profile/wallets/WalletSlice'
import QueueTaskSlice from '@Redux/slices/queue_task/QueueTaskSlice'
import rateReducer from '@Redux/slices/rate/RatesSlice'
import FrontServiceConnectionReducer from '@Redux/slices/sockets/FrontServiceConnectionSlice'
import ProxyServiceConnectionSlice from '@Redux/slices/sockets/ProxyServiceConnectionSlice'
import PublicServiceConnectionSlice from '@Redux/slices/sockets/PublicServiceConnectionSlice'
import termsAndConditionsReducer from '@Redux/slices/terms_and_conditions/TermsAndConditionsSlice'
import P2PReducer from '@Redux/slices/trade/p2p/P2PSlice'
import userReducer from '@Redux/slices/user/UserSlice'

export const store = configureStore({
  reducer: {
    front_service_connector: FrontServiceConnectionReducer,
    public_service_connector: PublicServiceConnectionSlice,
    proxy_service_connector: ProxyServiceConnectionSlice,
    config: configReducer,
    countries: countriesReducer,
    paymentGateways: PaymentGatewaysReducer,
    timezones: timezonesReducer,
    notifications: NotificationSlice,
    user: userReducer,
    spot_order: spotOrdersReducer,
    invoices: InvoicesReducer,
    transactions: TransactionsReducer,
    spot_exchange: exchangeOrdersReducer,
    wallets: WalletsReducer,
    modals: ModalReducer,
    simple_earns: simpleEarnsReducer,
    queueTasks: QueueTaskSlice,
    p2p: P2PReducer,
    user_p2p: P2PAccountReducer,
    user_notification: userNotificationSlice,
    rates: rateReducer,
    loans: loanReducer,
    user_loans: loanAccountReducer,
    terms_and_conditions: termsAndConditionsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
