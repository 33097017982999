import { RootState } from '@Redux/store'
// eslint-disable-next-line import/named
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { SubscriberMessageInterface } from '@App/sockets/basic_connection'
import FrontServiceConnection from '@App/sockets/front_service_connection_new'
import { generateRequestKey } from '@App/sockets/helpers'
import { Events, SocketState } from '@App/sockets/types'

import { createNotificationObject, updateNotifications } from '@Redux/slices/notifications/NotificationSlice'
import { addQueueTask } from '@Redux/slices/queue_task/QueueTaskSlice'

import type { IMakeExchangeRateRequest, IMakeSpotOrderRequest, ISimpleResponse } from '@Interfaces/CommonInterfaces'

import type { IFrontServiceConnection, IExchangeRequestMessage, ISpotOrderRequestMessage } from '@Redux/interfaces'

export const EventExchangeResponse = 'service:exchange_response'
export const EventSpotOrderResponse = 'service:spot_order_response'
export const EventMarginOrderResponse = 'service:margin_order_response'

const initialState: IFrontServiceConnection = {
  status: undefined,
  message: '',
  socket_status: SocketState.Closed,
}

export const setConfigFrontServiceConnection = createAsyncThunk('socket/setConfig', async (path: string) => {
  FrontServiceConnection.setApiUrl(path)
})

export const makeExchangeRequest = createAsyncThunk(
  'socket/setConfig',
  async (
    { message, token, user_id, amount, source_currency, target_currency, wallet_type }: IMakeExchangeRateRequest,
    { dispatch },
  ) => {
    const requestMessage: IExchangeRequestMessage = {
      message,
      token,
      user_id,
      amount,
      source_currency,
      target_currency,
      wallet_type,
      request_id: generateRequestKey('exchange'),
    }

    dispatch(
      createNotificationObject({
        id: requestMessage.request_id,
        message: '',
        status: '',
        isResolved: false,
      }),
    )

    FrontServiceConnection.makeExchangeRequest(requestMessage)
  },
)
export const makeSpotOrderRequest = createAsyncThunk(
  'socket/setConfig',
  async (
    { token, user_id, amount, source_currency, target_currency, action, rate, system }: IMakeSpotOrderRequest,
    { dispatch },
  ) => {
    const requestMessage: ISpotOrderRequestMessage = {
      token,
      user_id,
      amount,
      source_currency,
      target_currency,
      action,
      rate,
      request_id: generateRequestKey('spot'),
      system,
    }

    dispatch(
      createNotificationObject({
        id: requestMessage.request_id,
        message: '',
        status: '',
        isResolved: false,
      }),
    )
    FrontServiceConnection.makeSpotOrderRequest(requestMessage)
  },
)

let processMessageFunc: any

function dispatchNotificationAction(dispatch: any, message: SubscriberMessageInterface) {
  dispatch(
    updateNotifications({
      id: message.request_key,
      message:
        !message.payload.status_message && message.payload.status
          ? 'Success'
          : !message.payload.status_message && !message.payload.status
          ? 'ERROR'
          : message.payload.status_message,
      status: message.payload.status ? 'success' : 'error',
      isResolved: true,
    }),
  )
}

const getProcessMessageFunc = (dispatch: any): any => {
  if (processMessageFunc) {
    return processMessageFunc
  }

  processMessageFunc = (message: SubscriberMessageInterface) => {
    switch (message.event) {
      case EventSpotOrderResponse:
        dispatch(
          addQueueTask({
            id: message.request_key,
            type: 'spot',
            status: message.payload.status ? 'success' : 'error',
          }),
        )
        dispatchNotificationAction(dispatch, message)
        break
      case EventMarginOrderResponse:
        dispatch(
          addQueueTask({
            id: message.request_key,
            type: 'margin',
            status: message.payload.status ? 'success' : 'error',
          }),
        )
        dispatchNotificationAction(dispatch, message)
        break
      case EventExchangeResponse:
        dispatchNotificationAction(dispatch, message)
        break
      case Events.SocketStatusConnection:
        dispatch(
          setFrontStatusAsync({
            socket_status: message.status,
          }),
        )
        break
    }
  }

  return processMessageFunc
}

export const setFrontStatusAsync = createAsyncThunk('socket/setFrontStatusAsync', async (data: any) => {
  return data
})

export const setMessagesAsync = createAsyncThunk('socket/setMessagesAsync', async (data: ISimpleResponse) => {
  return data
})

export const subscribeToFrontService = createAsyncThunk('socket/subscribeToFrontService', async (_, { dispatch }) => {
  FrontServiceConnection.unsubscribe(getProcessMessageFunc(dispatch))
  FrontServiceConnection.subscribe(getProcessMessageFunc(dispatch))
  return null
})

export const unsubscribeToFrontService = createAsyncThunk(
  'socket/unsubscribeToFrontService',
  async (_, { dispatch }) => {
    FrontServiceConnection.unsubscribe(getProcessMessageFunc(dispatch))
    return null
  },
)

export const authToFrontService = createAsyncThunk('socket/authToFrontService', async (token: string) => {
  FrontServiceConnection.auth(token)
  return null
})

export const closeFrontServiceSocket = createAsyncThunk('socket/closeProxyServiceSocket', async (_, { dispatch }) => {
  FrontServiceConnection.unsubscribe(getProcessMessageFunc(dispatch))
  FrontServiceConnection.forceCloseSocket()
  return null
})

export const FrontServiceConnectionSlice = createSlice({
  name: 'front_service',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<ISimpleResponse>) => {
      state.message = action.payload.message
      state.status = action.payload.status
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setMessagesAsync.fulfilled, (state, action: PayloadAction<ISimpleResponse>) => {
        state.status = action.payload.status
        state.message = action.payload.message ? action.payload.message : ''
      })
      .addCase(setFrontStatusAsync.fulfilled, (state, action) => {
        state.socket_status = action.payload.socket_status
      })
  },
})

export const { setMessages } = FrontServiceConnectionSlice.actions

export const getHandlerMessage = (state: RootState) => {
  return `Status: ${state.front_service_connector.status}, Message: ${state.front_service_connector.message}`
}

export default FrontServiceConnectionSlice.reducer
