// eslint-disable-next-line import/named
import { TStatus, INotification } from '@Redux/interfaces'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Notification {
  id: string
  message: string
  description?: string
  status: TStatus
}

export interface SimpleNotify {
  message: string
  description?: string
}

const initialState = {
  notifications: [] as INotification[],
}

const clearAllNonGlobalNotifications = (state: { notifications: INotification[] }) =>
  state.notifications.filter((item) => item.id.split('_')[1] === 'global')

const updateState = (notification: INotification, state: { notifications: INotification[] }) =>
  state.notifications.map((item) => (item.id === notification.id ? notification : item))

const filterUnresolvedNotifications = (state: { notifications: INotification[] }) =>
  state.notifications.map((item) => item.isResolved)

const addNewNotify = (data: Notification, state: any) => {
  if (data) {
    let notify = data
    if (!data.id) {
      notify = { ...notify, id: Date.now().toString() }
    }
    state.notifications.push(notify)
    state.notifications = [...state.notifications]
    return state.notifications
  }
  return state.notifications
}

export const activateNotification = createAsyncThunk(
  'notification/activateNotification',
  async (data: { delay: number | undefined; id: string }, { dispatch }) => {
    await setTimeout(() => {
      dispatch(filterNotification({ id: data.id }))
    }, data.delay)
    return { id: data.id }
  },
)

export const filterNotification = createAsyncThunk('notification/filterNotification', async (data: { id: string }) => {
  return { id: data.id }
})

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addSuccessNotify: (state, action: PayloadAction<SimpleNotify>) => {
      state.notifications = addNewNotify({ ...action.payload, status: 'success', id: Date.now().toString() }, state)
    },

    addErrorNotify: (state, action: PayloadAction<SimpleNotify>) => {
      state.notifications = addNewNotify({ ...action.payload, status: 'error', id: Date.now().toString() }, state)
    },

    deleteNotify: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((note) => note.id !== action.payload)
    },

    // we use isResolved: true if we know that this notification isn`t waiting for any answers from socket

    createNotificationObject: (state, action: PayloadAction<INotification>) => {
      state.notifications = [
        ...state.notifications,
        {
          id: action.payload.id,
          isResolved: action.payload.isResolved || true,
          message: action.payload.message,
          status: action.payload.status,
        },
      ]
    },

    updateNotifications: (state, action: PayloadAction<INotification>) => {
      state.notifications = updateState(action.payload, state)
      filterUnresolvedNotifications(state)
    },

    clearNonGlobalNotifications: (state) => {
      state.notifications = clearAllNonGlobalNotifications(state)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(filterNotification.fulfilled, (state, action: any) => {
      state.notifications = state.notifications.filter((note) => note.id !== action.payload.id)
    })
  },
})

export const {
  deleteNotify,
  addSuccessNotify,
  addErrorNotify,
  updateNotifications,
  createNotificationObject,
  clearNonGlobalNotifications,
} = NotificationSlice.actions

export default NotificationSlice.reducer
