import { FC } from 'react'

import { NavLink } from 'react-router-dom'

import { ILinkItem } from '@UI/crypto_exchange/desktop/components/common/Header/DropDownLinkData'

import classes from './DropDownMenuItemRightMenu.module.scss'

export interface IItemProps {
  item: ILinkItem
  className?: string
}

export const DropDownMenuItemRightMenu: FC<IItemProps> = ({ item }) => {
  return (
    <NavLink to={item?.link ? item.link : '/'} className={classes.dropDownMenuItem}>
      <div className={classes.dropDownMenuItemIcon}>{item?.icon}</div>
      <div className={classes.dropDownMenuItemText}>
        {item?.title}
        {item?.subtitle && <div className={classes.dropDownMenuItemSubtitle}>{item?.subtitle}</div>}
      </div>
    </NavLink>
  )
}
