import {
  IP2POfferRequest,
  P2PGroupOrderProfitLose,
  P2POrder,
  ChangeOffersStatusRequest,
  P2PAccount,
  P2POffer,
  PayMethod,
  Advertiser,
} from '@Redux/interfaces/p2p'

import { $authHost, $host } from '@App/api'
import { getDataWrapper } from '@App/api/helper'
import { IDataResponse } from '@App/api/interfaces'
import requestByFilters, { ParamsByFilter } from '@App/helpers/requestByFilters'

export const getUserAccountsAsync = async (filters: ParamsByFilter[] = []): Promise<IDataResponse<P2PAccount[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''

  return await getDataWrapper($authHost.get(`api/p2p/accounts${filterString}`))
}

export const getUserAccountAsync = async (id: string): Promise<IDataResponse<P2PAccount>> =>
  await getDataWrapper($authHost.get(`api/p2p/accounts/${id}`))

export const deleteP2PAccountAsync = async (id: string): Promise<IDataResponse<null>> =>
  await getDataWrapper($authHost.delete(`api/p2p/accounts/${id}`))

export const getP2PPayMethod = async (id: string): Promise<IDataResponse<PayMethod>> =>
  await getDataWrapper($authHost.get(`api/p2p/pay-methods/${id}`))

export const createP2PPayAccount = async (data: object): Promise<IDataResponse<P2PAccount>> =>
  await getDataWrapper($authHost.post('api/p2p/accounts', data))

export const updateP2PPayAccount = async (id: string, data: object): Promise<IDataResponse<P2PAccount>> =>
  await getDataWrapper($authHost.put(`api/p2p/accounts/${id}`, data))

export const getUserP2pOffersData = async (filters: ParamsByFilter[] = []): Promise<IDataResponse<P2POffer[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''
  return await getDataWrapper($authHost.get(`api/me/p2p/offers${filterString}`))
}

export const getP2POrdersData = async (filters: ParamsByFilter[] = []): Promise<IDataResponse<P2POrder[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''
  return await getDataWrapper($authHost.get(`api/me/p2p/orders${filterString}`))
}

export const changeP2POffersStatus = async (data: ChangeOffersStatusRequest) =>
  await getDataWrapper($authHost.patch(`api/p2p/offers/status`, data))

export const cloneP2POffer = async (id: number) => await getDataWrapper($authHost.post(`api/p2p/offers/${id}/clone`))

export const getP2POrdersProfitLoseData = async (
  fiat: string,
  coin: string = 'all',
  filters: ParamsByFilter[] = [],
): Promise<IDataResponse<P2PGroupOrderProfitLose>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''
  return await getDataWrapper($authHost(`api/me/p2p/orders/profit-lose/${coin}/${fiat}/${filterString}`))
}

export const getP2POfferInfo = async (id: number): Promise<IDataResponse<P2POffer>> =>
  await getDataWrapper($authHost.get(`api/p2p/offers/${id}`))

export const updateP2POffer = async (id: number, data: IP2POfferRequest) => {
  try {
    await getDataWrapper($authHost.put(`api/p2p/offers/${id}`, data))
    return { status: true, message: 'Success' }
  } catch (e: any) {
    return { status: false, message: e.response.data.message }
  }
}

export const closeP2POffer = async (id: number | string) =>
  await getDataWrapper($authHost.delete(`api/p2p/offers/${id}`))

export const getP2POrder = async (id: string): Promise<IDataResponse<P2POrder>> =>
  await getDataWrapper($authHost.get(`api/p2p/orders/${id}`))

export const updateP2POrderStatus = async (id: string, data: object): Promise<IDataResponse<P2POrder>> =>
  await getDataWrapper($authHost.patch(`api/p2p/orders/${id}/status`, data))

export const getUserTradeStatistic = async (id: string): Promise<IDataResponse<Advertiser>> =>
  await getDataWrapper($host.get(`api/p2p/trade-statistic/${id}`))

export const confirmOrder = async (order_id: string, data: any): Promise<IDataResponse<P2POrder>> =>
  await getDataWrapper($authHost.post(`api/p2p/orders/${order_id}/confirm`, data))

export const deleteOrderImage = async (order_id: number, image_id: number): Promise<IDataResponse<P2POrder>> =>
  await getDataWrapper($authHost.delete(`api/p2p/orders/${order_id}/image/${image_id}`))
