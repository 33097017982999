import { BasicServiceConnection, SubscriberFunctionInterface } from '@App/sockets/basic_connection'
import * as types from '@App/sockets/types'

import { generateRequestKey } from './helpers'
const CurrencyServiceDestination = 'go.currency_service'

class PublicServiceConnection extends BasicServiceConnection {
  public name: string = types.ServiceNames.PublicService
  public path: string = ''

  getRateSocketMessage(source_currency: string, target_currency: string, request_type: string): string {
    return JSON.stringify({
      destination: CurrencyServiceDestination,
      request_type: request_type,
      event: types.Events.GetRate,
      request_key: generateRequestKey(types.Events.GetRate),
      payload: {
        source_currency,
        target_currency,
      },
    })
  }

  getTickerSocketMessage(request_type = types.RequestTypes.Subscribe): string {
    return JSON.stringify({
      destination: CurrencyServiceDestination,
      request_type: request_type,
      event: types.Events.GetTicker,
      request_key: generateRequestKey(types.Events.GetTicker),
      payload: { period: '24' },
    })
  }

  getOrderBookSocketMessage(source_currency: string, target_currency: string, request_type: string): string {
    return JSON.stringify({
      destination: CurrencyServiceDestination,
      request_type: request_type,
      event: types.Events.GetOrderBook,
      request_key: generateRequestKey(types.Events.GetOrderBook),
      payload: { source_currency, target_currency },
    })
  }

  getTradesSocketMessage(source_currency: string, target_currency: string, request_type: string): string {
    return JSON.stringify({
      destination: CurrencyServiceDestination,
      request_type: request_type,
      event: types.Events.GetTrades,
      request_key: generateRequestKey(types.Events.GetTrades),
      payload: { source_currency, target_currency },
    })
  }

  // TODO: Remove all subscriptions, divide one by one
  subscribe([source_currency, target_currency]: string[], event: string, func: SubscriberFunctionInterface): void {
    try {
      if (!this.getConnection()) {
        this.connection = this.establishConnection(func)
      }

      this.connected().then(() => {
        switch (event) {
          case types.Events.GetRate:
            this.getConnection().send(
              this.getRateSocketMessage(source_currency, target_currency, types.RequestTypes.Subscribe),
            )
            break
          case types.Events.GetOrderBook:
            this.getConnection().send(
              this.getOrderBookSocketMessage(source_currency, target_currency, types.RequestTypes.Subscribe),
            )
            break
          case types.Events.GetTicker:
            this.getConnection().send(this.getTickerSocketMessage(types.RequestTypes.Subscribe))
            break
          case types.Events.GetTrades:
            this.getConnection().send(
              this.getTradesSocketMessage(source_currency, target_currency, types.RequestTypes.Subscribe),
            )
            break
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  unsubscribe([source_currency, target_currency]: string[], event: string) {
    if (this.connection) {
      switch (event) {
        case types.Events.GetRate:
          this.connected().then(() => {
            this.getConnection().send(
              this.getRateSocketMessage(source_currency, target_currency, types.RequestTypes.Unsubscribe),
            )
          })
          break
        case types.Events.GetOrderBook:
          this.connected().then(() => {
            this.getConnection().send(
              this.getOrderBookSocketMessage(source_currency, target_currency, types.RequestTypes.Unsubscribe),
            )
          })
          break
        case types.Events.GetTicker:
          this.connected().then(() => {
            this.getConnection().send(this.getTickerSocketMessage(types.RequestTypes.Unsubscribe))
          })
          break
        case types.Events.GetTrades:
          this.connected().then(() => {
            this.getConnection().send(
              this.getTradesSocketMessage(source_currency, target_currency, types.RequestTypes.Unsubscribe),
            )
          })
          break
      }
    }
  }
}
export default new PublicServiceConnection()
