import React from 'react'

import { LoaderBlocks } from '@UI/crypto_exchange/desktop/components/common/Loader/components/LoaderBlocks'
import { LoaderCircle } from '@UI/crypto_exchange/desktop/components/common/Loader/components/LoaderCircle'
import { LoaderRound } from '@UI/crypto_exchange/desktop/components/common/Loader/components/LoaderRound'

export const PreloaderTypes = {
  ROUND: 'round',
  BLOCKS: 'blocks',
  CIRCLE: 'circle',
}

interface LoaderProps {
  type?: 'round' | 'blocks' | 'circle'
  className?: string
  size?: 'small' | 'medium' | ''
}

export const Loader = ({ type, className = '', size = '' }: LoaderProps) => {
  switch (type) {
    case PreloaderTypes.ROUND:
      return <LoaderRound className={className} />
    case PreloaderTypes.BLOCKS:
      return <LoaderBlocks className={className} size={size} />
    case PreloaderTypes.CIRCLE:
      return <LoaderCircle className={className} />
    default:
      return <LoaderRound className={className} />
  }
}
