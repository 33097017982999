import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAppSelector } from '@App/hooks'

import { MODAL_STATE, MODAL_TYPES } from '@Redux/slices/modals/enums'
import { closeModal, setModal } from '@Redux/slices/modals/ModalSlice'

import { TERMS_AND_CONDITIONS_STATUS } from '@UI/crypto_exchange/desktop/components/modals/TermAndConditionsModal/enums'

import { Loader } from '../components/common/Loader'

import { ROUTE_NAMES } from './routeNames'

export const PrivateRoute = () => {
  const dispatch = useDispatch()
  const user = useAppSelector((state) => state.user)
  const { loading, data: terms } = useAppSelector((state) => state.terms_and_conditions)
  const location = useLocation()

  useEffect(() => {
    if (terms.length > 0) {
      terms.forEach((term) => {
        if (term.available_urls.length > 0) {
          const filtered = term.available_urls.filter((url) => location.pathname.indexOf(url.value) !== -1)

          if (filtered.length > 0 && term.status !== TERMS_AND_CONDITIONS_STATUS.ACCEPTED) {
            dispatch(closeModal())
            dispatch(
              setModal({
                modalType: MODAL_TYPES.TERMS_AND_CONDITIONS,
                data: term,
                isOpen: MODAL_STATE.OPEN,
              }),
            )
          }
        } else {
          dispatch(closeModal())
          dispatch(
            setModal({
              modalType: MODAL_TYPES.TERMS_AND_CONDITIONS,
              data: term,
              isOpen: MODAL_STATE.OPEN,
            }),
          )
        }
      })
    }
  }, [terms])

  if (user.check_load || loading) {
    return <Loader />
  }

  return (
    <React.Suspense fallback={<Loader />}>
      {user.login ? <Outlet /> : <Navigate to={ROUTE_NAMES.LOGIN} />}
    </React.Suspense>
  )
}
