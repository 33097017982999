import { useEffect, useState, SetStateAction, useRef } from 'react'

import ReactModal from 'react-modal'

import { useOnClickOutside } from '@App/hooks'

import { ModalTypes } from '../modalTypes'

export default function ModalWrapper({
  isOpen = false,
  setIsOpen,
  children = null,
  timeOutModal = 0,
  withOutsideClick = true,
  styles = null,
}: ModalTypes) {
  const [scrollBarWidth, setScrollBarWidth] = useState<SetStateAction<number | null>>(null)

  const modalRef = useRef(null)

  const onClickOutside = () => {
    withOutsideClick && setIsOpen()
  }

  useEffect(() => {
    function getScrollBarWidth() {
      if (!document.body.classList.contains('overflow'))
        setScrollBarWidth(window.innerWidth - document.body.offsetWidth)
    }

    getScrollBarWidth()

    // 'resize' event to calculate scrollbar width
    window.addEventListener('resize', getScrollBarWidth)
    return () => window.removeEventListener('resize', getScrollBarWidth)
  }, [isOpen])

  useOnClickOutside(modalRef, onClickOutside)

  ReactModal.setAppElement('body')

  const customStyles = () => {
    const defaultStyles = {
      overlay: {
        top: '0',
        left: '0',
        zIndex: '500',
        width: '100%',
        height: '100%',
        background: 'unset',
        overflow: 'hidden auto',
      },
      content: {
        inset: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0',
        border: 'unset',
        minHeight: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '0',
      },
    }

    const overlay = { ...defaultStyles?.overlay, ...styles?.overlay }
    const content = { ...defaultStyles?.content, ...styles?.content }

    return { ...defaultStyles, overlay, content }
  }

  return (
    <ReactModal isOpen={isOpen} style={customStyles()}>
      <div ref={modalRef}>{children}</div>
    </ReactModal>
  )
}
