import { getDataJWTWrapper, getDataWrapper } from '@App/api/helper'
import { $authHost } from '@App/api/index'

import { IDepositRequest } from '@Interfaces/CommonInterfaces'

export const deposit = async (data: IDepositRequest) => await getDataJWTWrapper($authHost.post('api/me/deposit', data))

export const createPaymentIntent = async (data: any) => await getDataWrapper($authHost.post('api/me/intent', data))
export const withdrawal = async (currency: string, value: number) =>
  await getDataJWTWrapper($authHost.post('api/me/withdrawal', { currency, value }))

export const changeNickname = async (nickname: string) => await getDataWrapper($authHost.patch('api/me', { nickname }))

export const generateSecretKey = async () =>
  await getDataWrapper($authHost.post('api/me/two-factor/generate-secret-key'))

export const sendEmailVerificationCode = async () =>
  await getDataWrapper($authHost.post('api/me/send-email-verify-code'))

export const sendNewEmailVerificationCode = async (email: string) =>
  await getDataWrapper($authHost.post(`api/me/send-email-verify-code-for-change`, { email: email }))

export const editEmail = async (data: any) => await getDataWrapper($authHost.post(`api/me/email/change`, data))

export const enable2FA = async (code: string, secretKey: string) => {
  try {
    return await getDataWrapper(
      $authHost.post('api/me/two-factor/enable', {
        verification_code: code,
        two_fa_secret: secretKey,
      }),
    )
  } catch (e: any) {
    return e.response.data.message
  }
}

export const disable2FA = async (code: string) => {
  try {
    return await getDataWrapper(
      $authHost.post('api/me/two-factor/disable', {
        verification_code: code,
      }),
    )
  } catch (e: any) {
    return e.response.data.message
  }
}
