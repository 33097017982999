import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getTransactionHistoryDetails } from '@App/api/profile/transactions'
import { ParamsByFilter } from '@App/helpers/requestByFilters'

interface TransactionStateInterface {
  transactions: any
  pagination: {
    page: number
    per_page: number
    last_page: number
    from: number
    to: number
    total: number
    tab: number
  }
  isLoading: boolean
}

const initialState: TransactionStateInterface = {
  transactions: [],
  pagination: {
    from: 0,
    last_page: 0,
    page: 0,
    per_page: 0,
    to: 0,
    total: 0,
    tab: 0,
  },
  isLoading: false,
}

export const getTransactionAsync = createAsyncThunk('transaction/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getTransactionHistoryDetails(filters)
  if (data?.data && data?.meta) {
    return {
      transactions: data?.data,
      pagination: { ...data?.meta },
    }
  } else {
    return initialState
  }
})

export const TransactionSlice = createSlice({
  name: 'Transactions',
  initialState,
  reducers: {
    getTransactions: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactionAsync.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(getTransactionAsync.fulfilled, (state, action: any) => {
      state.transactions = action.payload.transactions
      state.pagination = action.payload.pagination
      state.isLoading = false
    })
  },
})

export const { getTransactions } = TransactionSlice.actions

export default TransactionSlice.reducer
