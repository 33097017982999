import { IBackNotification } from '@Redux/interfaces'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getNotificationsData } from '@App/api/profile/notification'
import { ParamsByFilter } from '@App/helpers/requestByFilters'

interface NotificationInterface {
  notifications: IBackNotification[]
}

const initialState: NotificationInterface = {
  notifications: [],
}

export const getNotificationsAsync = createAsyncThunk(
  'my/notifications/get',
  async (filters: ParamsByFilter[] = []) => {
    const data = await getNotificationsData(filters)

    if (data?.data) {
      return {
        notifications: data.data.map((notification: any) => {
          return notification
        }),
      }
    } else {
      return { notifications: [] }
    }
  },
)

export const NotificationSlice = createSlice({
  name: 'Notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotificationsAsync.fulfilled, (state, action: any) => {
      state.notifications = action.payload.notifications
    })
  },
})

export default NotificationSlice.reducer
