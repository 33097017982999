import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getSpotOrdersAsync } from '@App/api/profile/spot_order'
import { ParamsByFilter } from '@App/helpers/requestByFilters'

interface SpotOrdersStateInterface {
  spotOrders: any
  spotOrdersHistory: any
}

const initialState: SpotOrdersStateInterface = {
  spotOrders: [],
  spotOrdersHistory: [],
}

export const getSpotOrderAsync = createAsyncThunk('users/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getSpotOrdersAsync(filters)

  if (data?.data) {
    return {
      spot_orders: data.data.map((spot_order: any) => {
        return spot_order
      }),
    }
  } else {
    return { spot_orders: [] }
  }
})

export const SpotOrdersSlice = createSlice({
  name: 'spot_orders',
  initialState,
  reducers: {
    getSpotOrders: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getSpotOrderAsync.fulfilled, (state, action: any) => {
      state.spotOrders = action.payload.spot_orders
    })
  },
})

export const { getSpotOrders } = SpotOrdersSlice.actions

export default SpotOrdersSlice.reducer
