import { useCallback, useEffect } from 'react'

import { DarkThemeIcon, LightThemeIcon } from '@Static/icons/HeaderIcon'
import { PaywaysLogo } from '@Static/icons/PaywaysLogo'
import { Link } from 'react-router-dom'

import localStorageManager from '@App/cookies/local_storage_manager'
import { useAppDispatch, useAppSelector } from '@App/hooks'
import { isLogin, logout } from '@App/redux/slices/user/UserSlice'

import { changeTheme, userState } from '@Redux/slices/user/UserSlice'

import { ROUTE_NAMES } from '@UI/crypto_exchange/desktop/routes/routeNames'

import { AuthorhizationButtons } from './components/AuthorhizationButtons'
import { DropDownMenuItem } from './components/DropDownMenuItem'
import { MenuItem } from './components/MenuItem'
import { WalletSwitcher } from './components/WalletSwitcher'
import { dropDownMenuLinks } from './DropDownLinkData'
import classes from './Header.module.scss'

export const Header = () => {
  const dispatch = useAppDispatch()
  const { theme } = useAppSelector((state) => userState(state))
  const login = useAppSelector((state) => isLogin(state))

  const handleLogout = useCallback(() => {
    dispatch(logout())
  }, [])

  useEffect(() => {
    document.documentElement.dataset.theme = theme === 'dark' ? 'dark' : 'light'
    localStorageManager.set('theme', theme === 'dark' ? 'dark' : 'light')
  }, [theme])

  return (
    <header className={classes.header}>
      <nav className={classes.leftMenu}>
        <Link className={classes.paywaysLink} to={ROUTE_NAMES.HOME}>
          <PaywaysLogo className={classes.paywaysLogo} />
        </Link>

        <MenuItem title={'Buy Crypto'} isDropDownMenu isWalletDisplay isLogin={login}>
          <ul className={classes.dropDownMenu}>
            {login && <WalletSwitcher />}
            {dropDownMenuLinks.buyCrypto.map((item, index) => (
              <DropDownMenuItem key={index} item={item} />
            ))}
          </ul>
        </MenuItem>

        <MenuItem title={'Trade'} isDropDownMenu>
          <ul className={classes.dropDownMenu}>
            {dropDownMenuLinks.trade.map((item, index) => (
              <DropDownMenuItem key={index} item={item} />
            ))}
          </ul>
        </MenuItem>
        <MenuItem title={'Markets'} isDropDownMenu={false} link={ROUTE_NAMES.MARKETS_LIST} />
        <MenuItem title={'Earn'} isDropDownMenu link={ROUTE_NAMES.SIMPLE_EARN}>
          <ul className={classes.dropDownMenu}>
            {dropDownMenuLinks.earn.map((item, index) => (
              <DropDownMenuItem key={index} item={item} />
            ))}
          </ul>
        </MenuItem>
        <MenuItem title={'Finance'} isDropDownMenu>
          <ul className={classes.dropDownMenu}>
            {dropDownMenuLinks.finance.map((item, index) => (
              <DropDownMenuItem key={index} item={item} />
            ))}
          </ul>
        </MenuItem>
      </nav>
      <nav className={classes.rightMenu}>
        <AuthorhizationButtons login={login} handleLogout={handleLogout} />

        <div className={classes.language}>English</div>
        <i className={classes.separator} />
        <div className={classes.currency}>USD</div>
        <i className={classes.separator} />
        <div
          className={classes.mode}
          onClick={() => {
            dispatch(changeTheme())
          }}
        >
          {theme === 'dark' ? <LightThemeIcon /> : <DarkThemeIcon />}
        </div>
      </nav>
    </header>
  )
}
