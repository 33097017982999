import { IAssetOverviewObject, ILoan } from '@Redux/interfaces/loan'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IMeta } from '@App/api/interfaces'
import { getLoansData, getAssetOverviewData } from '@App/api/profile/loan'
import { ParamsByFilter } from '@App/helpers/requestByFilters'

interface StateInterface {
  asset_overview: IAssetOverviewObject
  loans: {
    data: ILoan[]
    pagination: IMeta
  }
  isLoading: boolean
}

const paginationInitialState = {
  from: 0,
  last_page: 0,
  current_page: 0,
  per_page: 0,
  to: 0,
  total: 0,
  path: '',
}

const initialState: StateInterface = {
  asset_overview: {
    dept: [],
    collateral: [],
  },
  loans: {
    data: [],
    pagination: paginationInitialState,
  },
  isLoading: false,
}

export const getAssetOverviewAsync = createAsyncThunk('account/loans/asset-overview/get', async () => {
  const data = await getAssetOverviewData()

  if (data?.data) {
    return {
      asset_overview: {
        dept: data.data.dept,
        collateral: data.data.collateral,
      },
    }
  } else {
    return {
      asset_overview: {
        dept: [],
        collateral: [],
      },
    }
  }
})

export const getLoansAsync = createAsyncThunk('account/loans/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getLoansData(filters)

  if (data?.data) {
    return {
      loans: {
        data: data.data,
        pagination: data.pagination,
      },
    }
  } else {
    return {
      loans: {
        data: [],
        pagination: paginationInitialState,
      },
    }
  }
})

export const LoanAccount = createSlice({
  name: 'accountLoans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAssetOverviewAsync.fulfilled, (state, action) => {
      state.asset_overview = action.payload.asset_overview
      state.isLoading = false
    })
    builder.addCase(getLoansAsync.fulfilled, (state, action) => {
      state.loans = action.payload.loans
      state.isLoading = false
    })
  },
})

export default LoanAccount.reducer
