export enum MODAL_STATE {
  OPEN = 'MODAL_OPEN',
  CLOSE = 'MODAL_CLOSE',
}

export enum MODAL_TYPES {
  BORROW_REPAY = 'BORROW_REPAY',
  CHANGE_NICKNAME = 'CHANGE_NICKNAME',
  DELETE_TRUST_DEVICE = 'DELETE_TRUST_DEVICE',
  EMAIL_CHANGE_VERIFICATION = 'EMAIL_CHANGE_VERIFICATION',
  MARGIN_TRANSFER = 'MARGIN_TRANSFER',
  SELECT_CURRENCY = 'SELECT_CURRENCY',
  REQUEST_APPROVED = 'REQUEST_APPROVED',
  FUND_ACCOUNT = 'FUND_ACCOUNT',
  CONFIRM_MODAL = 'CONFIRM_MODAL',
  MARKETS_MODAL = 'MARKETS_MODAL',
  ORDERS_LEFT_MODAL = 'ORDERS_LEFT_MODAL',
  SIMPLE_EARN_MODAL = 'SIMPLE_EARN_MODAL',
  P2P_OFFER_CONFIRM_MODAL = 'P2P_OFFER_CONFIRM_MODAL',
  P2P_OFFER_CREATED_MODAL = 'P2P_OFFER_CREATED_MODAL',
  SELECT_P2P_PAY_METHOD = 'SELECT_P2P_PAY_METHOD',
  SELECT_P2P_ACCOUNT = 'SELECT_P2P_ACCOUNT',
  ADD_P2P_PAY_ACCOUNT = 'ADD_P2P_PAY_ACCOUNT',
  P2P_PAYMENT_CONFIRMATION = 'P2P_PAYMENT_CONFIRMATION',
  NOTIFICATION_INFO = 'NOTIFICATION_INFO',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  NONE = 'NONE',
  LOAN_BORROW_MODAL = 'LOAN_BORROW_MODAL',
  RENEWAL_MODAL = 'RENEWAL_MODAL',
  LOAN_REPAY_MODAL = 'LOAN_REPAY_MODAL',
  LOAN_ADJUST_LTV_MODAL = 'LOAN_ADJUST_LTV_MODAL',
}
