import { P2PGroupOrderProfitLose, P2POrder, P2PAccount, P2POffer } from '@Redux/interfaces/p2p'
import { AnyAction, AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IMeta } from '@App/api/interfaces'
import {
  getUserP2pOffersData,
  getUserAccountsAsync,
  getP2POrdersProfitLoseData,
  getP2POrdersData,
  getP2POfferInfo,
} from '@App/api/profile/p2p'
import { ParamsByFilter } from '@App/helpers/requestByFilters'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>

const paginationInitialState = {
  from: 0,
  last_page: 0,
  current_page: 0,
  per_page: 0,
  to: 0,
  total: 0,
  path: '',
}

interface P2PStateInterface {
  p2p_accounts: P2PAccount[]
  p2p_offers: {
    data: P2POffer[]
    pagination: IMeta
  }
  p2pOrders: {
    data: P2POrder[]
    pagination: IMeta
  }
  profit_lose_p2p_orders: {
    data: P2PGroupOrderProfitLose
    pagination: IMeta
  }
  p2p_offer_info: P2POffer | null
  isLoading: boolean
}

const initialState: P2PStateInterface = {
  p2p_accounts: [],
  p2p_offers: {
    data: [],
    pagination: paginationInitialState,
  },
  p2pOrders: {
    data: [],
    pagination: paginationInitialState,
  },
  profit_lose_p2p_orders: {
    data: {},
    pagination: paginationInitialState,
  },
  p2p_offer_info: null,
  isLoading: false,
}

export const getP2PAccountsAsync = createAsyncThunk(
  'account/p2p_accounts/get',
  async (filters: ParamsByFilter[] = []) => {
    const data = await getUserAccountsAsync(filters)
    if (data?.data) {
      return {
        p2p_accounts: data?.data,
      }
    } else {
      return {
        p2p_accounts: [],
      }
    }
  },
)

export const getUserP2pOffersAsync = createAsyncThunk(
  'account/p2p_offers/get',
  async (filters: ParamsByFilter[] = []) => {
    const data = await getUserP2pOffersData(filters)

    if (data?.data) {
      return {
        p2p_offers: {
          data: data.data,
          pagination: data.meta,
        },
      }
    } else {
      return {
        p2p_offers: {
          data: [],
          pagination: paginationInitialState,
        },
      }
    }
  },
)

export const getP2POrdersDataAsync = createAsyncThunk(
  'account/p2p/orders/get',
  async (filters: ParamsByFilter[] = []) => {
    const data = await getP2POrdersData(filters)

    if (data?.data) {
      return {
        p2pOrders: {
          data: data.data,
          pagination: data.meta,
        },
      }
    } else {
      return {
        p2pOrders: {
          data: [],
          pagination: paginationInitialState,
        },
      }
    }
  },
)

export const getP2POrdersProfitLoseDataAsync = createAsyncThunk(
  'p2p/orders/profit-lose/get',
  async ({ fiat, coin = 'all', filters = [] }: { fiat: string; coin: string; filters?: ParamsByFilter[] }) => {
    const data = await getP2POrdersProfitLoseData(fiat, coin, filters)

    if (data?.data) {
      return {
        profit_lose_p2p_orders: {
          data: data.data,
          pagination: data.meta,
        },
      }
    } else {
      return {
        profit_lose_p2p_orders: {
          data: {},
          pagination: paginationInitialState,
        },
      }
    }
  },
)

export const getP2POfferInfoAsync = createAsyncThunk('account/p2p/offer_info/get', async (id: number) => {
  const data = await getP2POfferInfo(id)
  if (data?.data) {
    return {
      p2p_offer_info: data?.data,
    }
  } else {
    return { p2p_offer_info: null }
  }
})

const isPendingAction = (action: AnyAction): action is PendingAction =>
  [
    getP2POfferInfoAsync.pending.type,
    getP2POrdersProfitLoseDataAsync.pending.type,
    getP2POrdersDataAsync.pending.type,
    getP2PAccountsAsync.pending.type,
  ].includes(action.type)

export const P2PProfileSlice = createSlice({
  name: 'p2p',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserP2pOffersAsync.fulfilled, (state, action) => {
        state.p2p_offers = action.payload.p2p_offers
        state.isLoading = false
      })
      .addCase(getP2POfferInfoAsync.fulfilled, (state, action: any) => {
        state.p2p_offer_info = action.payload.p2p_offer_info
        state.isLoading = false
      })
      .addCase(getP2PAccountsAsync.fulfilled, (state, action: any) => {
        state.p2p_accounts = action.payload.p2p_accounts
        state.isLoading = false
      })
      .addCase(getP2POrdersDataAsync.fulfilled, (state, action: any) => {
        state.p2pOrders = action.payload.p2pOrders
        state.isLoading = false
      })
      .addCase(getP2POrdersProfitLoseDataAsync.fulfilled, (state, action: any) => {
        state.profit_lose_p2p_orders = action.payload.profit_lose_p2p_orders
        state.isLoading = false
      })
      .addMatcher<PendingAction>(isPendingAction, (state) => {
        state.isLoading = true
      })
  },
})

export default P2PProfileSlice.reducer
