class CookieManagerInstance {
  create(key: any) {
    const json = JSON.stringify([])

    this.set(key, json)

    return this
  }

  set(key: string, value: string | number | boolean, age = 31536000, domain = this.getDomain()) {
    const date = new Date()
    date.setTime(date.getTime() + age * 1000)
    const expires = date

    const options = {
      expires: expires.toUTCString(),
      path: '/',
      domain: domain,
    }

    value = encodeURIComponent(value)

    var updatedCookie = key + '=' + value + ''

    for (const propName in options) {
      updatedCookie += ';' + propName
      // @ts-ignore
      const propValue = options[propName]
      if (propValue !== true) {
        updatedCookie += '=' + propValue
      }
    }
    if (typeof window !== 'undefined') {
      document.cookie = updatedCookie + ';'
    }

    return this
  }

  get(key: string) {
    if (typeof window !== 'undefined') {
      const matches = document.cookie.match(
        new RegExp('(?:^|; )' + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
      )
      try {
        // @ts-ignore
        return decodeURIComponent(matches[1])
      } catch (e) {
        return void 0
      }
    }

    return undefined
  }

  remove(key: string) {
    this.set(key, '', -1)
  }

  getDomain() {
    const segments = document.location.hostname.split('.')

    //TODO uncommect after add language
    // segments.splice(0, segments.length - 2)

    const domain = segments.join('.')

    // if (domain !== 'localhost') {
    //   domain = '.' + domain
    // }

    return domain || null
  }

  checkCookieByKey(key: string) {
    return !!this.get(key)
  }
}

const cookieManager = new CookieManagerInstance()
export default cookieManager
