import React from 'react'

import { lazily } from 'react-lazily'
import { Route, Routes } from 'react-router-dom'

import { Loader } from '../components/common/Loader'

import { PrivateRoute } from './PrivateRoutes'
import { ROUTE_NAMES } from './routeNames'

const { HomeContainer } = lazily(() => import('@UI/crypto_exchange/desktop/pages/home/containers/HomeContainer'))
const { ExchangeOrderContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/exchange/containers/ExchangeOrderContainer'),
)
const { SpotOrderContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/spot/containers/SpotOrderContainer'),
)
const { MarketsContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/markets/containers/MarketsContainer'),
)
const { SimpleEarnContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/simple_earn/containers/SimpleEarnContainer'),
)

const { AccountEarnHistory } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/earn/AccountEarnHistory'),
)

const { LoginContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/authorization_pages/containers/LoginContainer'),
)
const { VerificationContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/authorization_pages/containers/VerificationContainer'),
)
const { RegistrationContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/authorization_pages/containers/RegistrationContainer'),
)
const { Dashboard } = lazily(() => import('@UI/crypto_exchange/desktop/pages/account/components/Dashboard'))

const { Security } = lazily(() => import('@UI/crypto_exchange/desktop/pages/account/components/Security'))

const { Settings } = lazily(() => import('@UI/crypto_exchange/desktop/pages/account/components/Settings'))

const { GoogleAuthenticator } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/google_authenticator/containers/GoogleAuthenticator'),
)

const { AccountContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account/containers/AccountContainer'),
)

const { AccountOrdersContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/AccountOrdersContainer/AccountOrdersContainer'),
)
const { AccountOrdersSpotOpen } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/spot_orders/AccountOrdersSpotOpen'),
)
const { AccountOrdersSpotHistory } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/spot_orders/AccountOrdersSpotHistory'),
)
const { AccountOrdersConvertHistory } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/AccountOrdersConvertHistory'),
)
const { AccountOrdersBuyCryptoHistory } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/AccountOrdersBuyCryptoHistory'),
)
const { AccountMarginOpenOrders } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/margin_orders/AccountMarginOpenOrders'),
)
const { AccountMarginHistoryOrders } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/margin_orders/AccountMarginHistoryOrders'),
)
const { AccountMarginTradeHistory } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/margin_orders/AccountMarginTradeHistory'),
)
const { AccountMarginCapitalFlow } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/margin_orders/AccountMarginCapitalFlow'),
)

const { AccountMarginBorrowOrders } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/margin_orders/AccountMarginBorrowOrders'),
)

const { AccountMarginRepayOrders } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/margin_orders/AccountMarginRepayOrders'),
)

const { AccountMarginInterestOrders } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/margin_orders/AccountMarginInterestOrders'),
)

const { PaymentHistory: AccountOrdersPaymentHistory } = lazily(
  () => import('@App/ui/crypto_exchange/desktop/pages/account_orders/AccountOrdersPaymentHistory'),
)

const { WalletContainer } = lazily(() => import('@UI/crypto_exchange/desktop/pages/wallet/containers/WalletContainer'))
const { DepositContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/deposit/containers/DepositContainer'),
)

const { FiatAndSpotContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/fiat_and_spot/containers/FiatAndSpotContainer'),
)
const { TransactionHistoryContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/transaction_history/containers/TransactionHistoryContainer'),
)

// const { DepositItemContainer } = lazily(
//   () => import('@UI/crypto_exchange/desktop/pages/deposit_item/AccountOrdersContainer/DepositItemContainer'),
// )
// const { WithdrawalItemContainer } = lazily(
//   () => import('@UI/crypto_exchange/desktop/pages/withdrawal_item/AccountOrdersContainer/WithdrawalItemContainer'),
// )

const { ResetPasswordLinkContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/authorization_pages/containers/ResetPasswordLinkContainer'),
)
const { ResetPasswordContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/authorization_pages/containers/ResetPasswordContainer'),
)

const { FiatDeposit } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/fiat_deposit/containers/FiatDepositContainer'),
)

const { MarginWalletContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/margin_wallet/containers/MarginWalletContainer'),
)

const { CrossMarginContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/margin_wallet_sections/containers/CrossMarginContainer'),
)

const { IsolatedMarginContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/margin_wallet_sections/containers/IsolatedMarginContainer'),
)

const { DepositResult } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/deposit_result/containers/DepositResultContainer'),
)

const { ChangePassword } = lazily(() => import('@UI/crypto_exchange/desktop/pages/change_password/containers'))

const { DeviceManagement } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/device_management/containers/DeviceManagement'),
)

const { ChangeEmailAuthenticatorContainer } = lazily(
  () =>
    import('@UI/crypto_exchange/desktop/pages/change_email_authenticator/containers/ChangeEmailAuthenticatorContainer'),
)

const { FundingContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/funding/containers/FundingContainer'),
)

const { Payment } = lazily(() => import('@UI/crypto_exchange/desktop/pages/account/components/Payment'))

const { CreateP2PAccount } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account/components/Payment/components/CreateP2PAccount'),
)

const { UpdateP2PAccount } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account/components/Payment/components/UpdateP2PAccount'),
)

const { NotFoundPageContainer } = lazily(() => import('@UI/crypto_exchange/desktop/pages/not_found_page'))

const { P2PContainer } = lazily(() => import('@UI/crypto_exchange/desktop/pages/P2P/containers/P2PContainer'))

const { P2POfferCreateContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/P2P/offer/create/containers/P2POfferCreateContainer'),
)

const { P2POfferUpdateContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/P2P/offer/update/containers/P2POfferUpdateContainer'),
)

const { P2POffersContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/P2P/offer/list/public/containers/P2POffersContainer'),
)

const { AccountP2pOffersContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/P2P/offer/list/private/containers/AccountP2pOffersContainer'),
)

const { P2POrdersContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/P2P/order/list/private/containers/P2POrdersContainer'),
)

const { P2PAdvertiserContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/P2P/user/public/containers/P2PAdvertiserContainer'),
)

const { P2PUserCenterContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/P2P/user/private/containers/P2PUserCenterContainer'),
)

const { OrderContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/P2P/order/create/containers/OrderContainer'),
)

const { LoanContainer } = lazily(() => import('@UI/crypto_exchange/desktop/pages/loan/containers/LoanContainer'))

const { ListContainer } = lazily(() => import('@UI/crypto_exchange/desktop/pages/loan/active/containers/ListContainer'))

const { LoanListContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/loan/list/containers/LoanListContainer'),
)

const { LoanDataContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/loan/data/containers/LoanDataContainer'),
)

const { AccountLoanOpenOrders } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/loan_orders/AccountLoanOpenOrders'),
)

const { AccountLoanRepaymentHistoryOrders } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/loan_orders/AccountLoanRepaymentHistoryOrders'),
)

const { LtvAdjustmentHistory } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/loan_orders/LtvAdjustmentHistory'),
)

const { AccountLoanLiquidationOrders } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/loan_orders/AccountLoanLiquidationOrders'),
)

const { OrdersHistory } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/account_orders/loan_orders/OrdersHistory'),
)

const { EarnWalletContainer } = lazily(
  () => import('@UI/crypto_exchange/desktop/pages/earn/earn_wallet/containers/EarnWalletContainer'),
)

export const RouterContainer = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        <Route path={ROUTE_NAMES.HOME} element={<HomeContainer />} />
        <Route path={ROUTE_NAMES.LOGIN} element={<LoginContainer />} />
        <Route path={ROUTE_NAMES.TWO_FA} element={<VerificationContainer />} />
        <Route path={ROUTE_NAMES.REGISTRATION} element={<RegistrationContainer />} />
        <Route path={ROUTE_NAMES.RESET_PASSWORD_LINK} element={<ResetPasswordLinkContainer />} />
        <Route path={ROUTE_NAMES.RESET_PASSWORD} element={<ResetPasswordContainer />} />
        <Route path={ROUTE_NAMES.SPOT_WITH_PAIR} element={<SpotOrderContainer />} />
        <Route path={ROUTE_NAMES.SPOT} element={<SpotOrderContainer />} />
        <Route path={ROUTE_NAMES.MARKETS_LIST} element={<MarketsContainer />} />
        <Route path={ROUTE_NAMES.SIMPLE_EARN} element={<SimpleEarnContainer />} />

        <Route path={ROUTE_NAMES.P2P} element={<P2PContainer />}>
          <Route path={ROUTE_NAMES.P2P_WITH_PAIR} element={<P2POffersContainer />} />
          <Route path={ROUTE_NAMES.P2P_ADVERTISER_DETAIL} element={<P2PAdvertiserContainer />} />
        </Route>

        <Route path={ROUTE_NAMES.LOANS} element={<LoanContainer />}>
          <Route path="" element={<LoanListContainer />} />
          <Route path={ROUTE_NAMES.LOANS_DATA} element={<LoanDataContainer />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path={ROUTE_NAMES.ACCOUNT} element={<AccountContainer />}>
            <Route path={ROUTE_NAMES.DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTE_NAMES.SECURITY} element={<Security />} />
            <Route path={ROUTE_NAMES.SETTINGS} element={<Settings />} />
            <Route path={ROUTE_NAMES.PAYMENT} element={<Payment />} />

            <Route path={ROUTE_NAMES.PAYMENT_P2P_ACCOUNT_CREATE} element={<CreateP2PAccount />} />
            <Route path={ROUTE_NAMES.PAYMENT_P2P_ACCOUNT_EDIT} element={<UpdateP2PAccount />} />
          </Route>

          <Route path={ROUTE_NAMES.ACCOUNT_ORDER} element={<AccountOrdersContainer />}>
            <Route path={ROUTE_NAMES.ACCOUNT_SPOT_ORDER_OPEN} element={<AccountOrdersSpotOpen />} />
            <Route path={ROUTE_NAMES.ACCOUNT_SPOT_ORDER_HISTORY} element={<AccountOrdersSpotHistory />} />
            <Route path={ROUTE_NAMES.ACCOUNT_SPOT_ORDER_CONVERT_HISTORY} element={<AccountOrdersConvertHistory />} />
            <Route path={ROUTE_NAMES.PAYMENT_HISTORY} element={<AccountOrdersPaymentHistory />} />
            <Route
              path={ROUTE_NAMES.ACCOUNT_SPOT_ORDER_BUY_CRYPTO_HISTORY}
              element={<AccountOrdersBuyCryptoHistory />}
            />
            <Route
              path={ROUTE_NAMES.ACCOUNT_SPOT_ORDER_BUY_CRYPTO_HISTORY_WITH_TYPE}
              element={<AccountOrdersBuyCryptoHistory />}
            />
            <Route path={ROUTE_NAMES.ACCOUNT_MARGIN_ORDER_OPEN} element={<AccountMarginOpenOrders />} />
            <Route path={ROUTE_NAMES.ACCOUNT_MARGIN_ORDER_HISTORY} element={<AccountMarginHistoryOrders />} />
            <Route path={ROUTE_NAMES.ACCOUNT_MARGIN_TRADE_HISTORY} element={<AccountMarginTradeHistory />} />
            <Route path={ROUTE_NAMES.ACCOUNT_MARGIN_CAPITAL_FLOW} element={<AccountMarginCapitalFlow />} />
            <Route path={ROUTE_NAMES.ACCOUNT_MARGIN_ORDER_BORROW} element={<AccountMarginBorrowOrders />} />
            <Route path={ROUTE_NAMES.ACCOUNT_MARGIN_ORDER_REPAY} element={<AccountMarginRepayOrders />} />
            <Route path={ROUTE_NAMES.ACCOUNT_MARGIN_ORDER_INTEREST} element={<AccountMarginInterestOrders />} />
            <Route path={ROUTE_NAMES.ACCOUNT_LOAN_ORDER_OPEN} element={<AccountLoanOpenOrders />} />
            <Route path={ROUTE_NAMES.ACCOUNT_LOAN_ORDER_REPAY} element={<AccountLoanRepaymentHistoryOrders />} />
            <Route path={ROUTE_NAMES.ACCOUNT_LOAN_ORDER_HISTORY} element={<OrdersHistory />} />
            <Route path={ROUTE_NAMES.ACCOUNT_LOAN_ORDER_LTV} element={<LtvAdjustmentHistory />} />
            <Route path={ROUTE_NAMES.ACCOUNT_LOAN_ORDER_LIQUIDATION} element={<AccountLoanLiquidationOrders />} />
            <Route path={ROUTE_NAMES.SIMPLE_EARN_HISTORY} element={<AccountEarnHistory />} />
          </Route>

          <Route path={ROUTE_NAMES.ENABLE_GOOGLE_AUTHENTICATOR} element={<GoogleAuthenticator type={'enable'} />} />
          <Route path={ROUTE_NAMES.DISABLE_GOOGLE_AUTHENTICATOR} element={<GoogleAuthenticator type={'disable'} />} />
          <Route path={ROUTE_NAMES.CHANGE_EMAIL_AUTHENTICATOR} element={<ChangeEmailAuthenticatorContainer />} />

          <Route path={ROUTE_NAMES.CHANGE_PASSWORD} element={<ChangePassword />} />
          <Route path={ROUTE_NAMES.DEVICE_MANAGEMENT} element={<DeviceManagement />} />

          <Route path={ROUTE_NAMES.EXCHANGE_WITH_PAIR} element={<ExchangeOrderContainer />} />
          <Route path={ROUTE_NAMES.EXCHANGE} element={<ExchangeOrderContainer />} />

          <Route path={ROUTE_NAMES.DEPOSIT} element={<DepositContainer />} />
          <Route path={ROUTE_NAMES.DEPOSIT_ITEM} element={<DepositContainer />} />

          <Route path={ROUTE_NAMES.FIAT_DEPOSIT} element={<FiatDeposit />} />
          <Route path={ROUTE_NAMES.DEPOSIT_RESULT} element={<DepositResult />} />

          <Route path={ROUTE_NAMES.WALLET} element={<WalletContainer />}>
            <Route path={ROUTE_NAMES.WALLET_MAIN} element={<FiatAndSpotContainer />} />
            <Route path={ROUTE_NAMES.HISTORY_DEPOSIT_CRYPTO} element={<TransactionHistoryContainer />} />
            <Route path={ROUTE_NAMES.HISTORY_DEPOSIT_CRYPTO_WITH_TYPE} element={<TransactionHistoryContainer />} />
            <Route path={ROUTE_NAMES.MARGIN} element={<MarginWalletContainer />}>
              <Route path={ROUTE_NAMES.CROSS_MARGIN} element={<CrossMarginContainer />} />
              <Route path={ROUTE_NAMES.ISOLATED_MARGIN} element={<IsolatedMarginContainer />} />
            </Route>
            <Route path={ROUTE_NAMES.WALLET_FUNDING} element={<FundingContainer />} />
            <Route path={ROUTE_NAMES.WALLET_EARN} element={<EarnWalletContainer />} />
          </Route>

          <Route path={ROUTE_NAMES.P2P} element={<P2PContainer />}>
            <Route path={ROUTE_NAMES.P2P_OFFERS_OPEN} element={<P2POfferCreateContainer />} />
            <Route path={ROUTE_NAMES.P2P_OFFERS_UPDATE} element={<P2POfferUpdateContainer />} />
            <Route path={ROUTE_NAMES.P2P_USER_CENTER} element={<P2PUserCenterContainer />} />
            <Route element={<PrivateRoute />}>
              <Route path={ROUTE_NAMES.P2P_MY_OFFERS} element={<AccountP2pOffersContainer />} />
              <Route path={ROUTE_NAMES.P2P_ORDERS} element={<P2POrdersContainer />} />
              <Route path={ROUTE_NAMES.P2P_ORDER} element={<OrderContainer />} />
            </Route>
          </Route>

          <Route path={ROUTE_NAMES.LOANS} element={<LoanContainer />}>
            <Route path={ROUTE_NAMES.ACTIVE_LOANS} element={<ListContainer />} />
          </Route>
          {/* <Route path={ROUTE_NAMES.WITHDRAWAL_ITEM} element={<WithdrawalItemContainer />} /> */}
        </Route>
        <Route path="*" element={<NotFoundPageContainer />} />
      </Routes>
    </React.Suspense>
  )
}
