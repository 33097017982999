import { FC, ReactNode } from 'react'

import { ArrowDown } from '@Static/icons/HeaderIcon'
import { NavLink } from 'react-router-dom'

import { useAppSelector } from '@App/hooks'

import { currentWallet } from '@Redux/slices/user/selectors'

import classes from './MenuItem.module.scss'

export interface IMenuItemProps {
  title?: string
  isDropDownMenu?: boolean
  iconTitle?: ReactNode
  children?: ReactNode
  isWalletDisplay?: boolean
  isLogin?: boolean
  link?: string
}

export const MenuItem: FC<IMenuItemProps> = ({
  title = '',
  isDropDownMenu = false,
  iconTitle = null,
  children = null,
  isWalletDisplay = false,
  isLogin = false,
  link = '',
}) => {
  const currWallet = useAppSelector((state) => currentWallet(state))

  if (!link)
    return (
      <div className={classes.menuItem}>
        <div className={classes.menuItemContent}>
          {title && title}
          {iconTitle && iconTitle}
          {isWalletDisplay && isLogin && <div className={classes.currentWallet}>{currWallet}</div>}
          {isDropDownMenu && <ArrowDown className={classes.arrowDown} />}
        </div>
        {isDropDownMenu && children}
      </div>
    )

  return (
    <NavLink className={classes.menuItem} to={link}>
      <div className={classes.menuItemContent}>
        {title && title}
        {iconTitle && iconTitle}
        {isWalletDisplay && isLogin && <div className={classes.currentWallet}>{currWallet}</div>}
        {isDropDownMenu && <ArrowDown className={classes.arrowDown} />}
      </div>
      {isDropDownMenu && children}
    </NavLink>
  )
}
