import jwtDecode from 'jwt-decode'

export const getDataWrapper = async <T>(fn: any): Promise<T> => {
  const { data } = await fn
  return data
}

export const getDataJWTWrapper = async <T>(fn: any): Promise<T> => {
  const { data } = await fn
  return jwtDecode(data.data)
}
