export enum ROUTE_NAMES {
  HOME = '/',
  EXCHANGE = '/trade/exchange/',
  EXCHANGE_WITH_PAIR = '/trade/exchange/:source_currency/:target_currency',
  SPOT = '/trade/spot/',
  SPOT_WITH_PAIR = '/trade/spot/:source_currency/:target_currency',
  LOGIN = '/login',
  TWO_FA = '/verification',
  REGISTRATION = '/registration',
  RESET_PASSWORD = '/password-reset/:token',
  RESET_PASSWORD_LINK = '/password-reset-link',
  ACCOUNT = '/account',
  MARKETS_LIST = '/markets/',
  SIMPLE_EARN = '/simple-earn/',
  SIMPLE_EARN_HISTORY = '/account/orders/simple-earn/history',

  ACCOUNT_ORDER = '/account/orders',

  ACCOUNT_SPOT_ORDER_OPEN = '/account/orders/spot/open',
  ACCOUNT_SPOT_ORDER_HISTORY = '/account/orders/spot/history',
  ACCOUNT_SPOT_ORDER_CONVERT_HISTORY = '/account/orders/convert/history',
  ACCOUNT_SPOT_ORDER_BUY_CRYPTO_HISTORY = '/account/orders/buy-crypto/history',
  ACCOUNT_SPOT_ORDER_BUY_CRYPTO_HISTORY_WITH_TYPE = '/account/orders/buy-crypto/history/:type',
  PAYMENT_HISTORY = '/account/orders/payment/history',

  ACCOUNT_MARGIN_ORDER_OPEN = '/account/orders/margin/open',
  ACCOUNT_MARGIN_ORDER_HISTORY = '/account/orders/margin/history',
  ACCOUNT_MARGIN_TRADE_HISTORY = '/account/orders/margin/trade',
  ACCOUNT_MARGIN_CAPITAL_FLOW = '/account/orders/margin/capital-flow',
  ACCOUNT_MARGIN_ORDER_BORROW = '/account/orders/margin/borrow',
  ACCOUNT_MARGIN_ORDER_REPAY = '/account/orders/margin/repay',
  ACCOUNT_MARGIN_ORDER_INTEREST = '/account/orders/margin/interest',

  ACCOUNT_LOAN_ORDER_OPEN = '/account/orders/loan/open',
  ACCOUNT_LOAN_ORDER_REPAY = '/account/orders/loan/repay',
  ACCOUNT_LOAN_ORDER_LTV = '/account/orders/loan/ltv',
  ACCOUNT_LOAN_ORDER_LIQUIDATION = '/account/orders/loan/liquidation',
  ACCOUNT_LOAN_ORDER_HISTORY = '/account/orders/loan/history',

  DEPOSIT = '/account/deposit/',
  FIAT_DEPOSIT = '/account/fiat-deposit',
  DEPOSIT_ITEM = '/account/deposit/:currency',

  DEPOSIT_RESULT = '/account/deposit/result',
  // DEPOSIT_RESULT = '/account/deposit/:invoice/result',

  SECURITY = '/account/security',
  DEVICE_MANAGEMENT = '/device-management',
  DASHBOARD = '/account/dashboard',
  SETTINGS = '/account/settings',
  ENABLE_GOOGLE_AUTHENTICATOR = '/enable-google-authenticator',
  DISABLE_GOOGLE_AUTHENTICATOR = '/disable-google-authenticator',
  CHANGE_PASSWORD = '/change-password',
  CHANGE_EMAIL_AUTHENTICATOR = '/change-email-authenticator',
  PAYMENT = '/account/payment',

  WALLET = '/wallet/',
  WALLET_MAIN = '/wallet/main',
  MARGIN = '/wallet/margin/',
  CROSS_MARGIN = '/wallet/margin/cross',
  ISOLATED_MARGIN = '/wallet/margin/isolated',
  WALLET_FUNDING = '/wallet/funding',
  WITHDRAWAL_ITEM = '/account/withdrawal/:currency',
  HISTORY_DEPOSIT_CRYPTO = '/wallet/history',
  HISTORY_DEPOSIT_CRYPTO_WITH_TYPE = '/wallet/history/:type',
  WALLET_EARN = '/wallet/earn/',

  P2P = '/trade/p2p/',
  P2P_WITH_PAIR = '/trade/p2p/:payment_type/:source_currency/:target_currency',
  P2P_OFFERS_OPEN = '/trade/p2p/offers/create',
  P2P_OFFERS_UPDATE = '/trade/p2p/offers/edit/:id',
  P2P_USER_CENTER = '/trade/p2p/user-center',
  P2P_MY_OFFERS = '/trade/p2p/myoffers',
  PAYMENT_P2P_ACCOUNT_CREATE = '/account/payment/p2p/:pay_method_id/create',
  PAYMENT_P2P_ACCOUNT_EDIT = '/account/payment/p2p/:account_id/edit',
  P2P_ORDERS = '/trade/p2p/myorders',
  P2P_ADVERTISER_DETAIL = '/trade/p2p/advertiser/:id',
  P2P_ORDER = '/trade/p2p/order-detail/:id',

  LOANS = '/loans',
  ACTIVE_LOANS = '/loans/active',
  LOANS_DATA = '/loans/data',
}

export function replaceVarsInPath(sourcePath: string, vars: any[]): string {
  const chunks = sourcePath.split('/')
  const newChunks = []

  for (const pathChunk of chunks) {
    if (RegExp(/:/).test(pathChunk)) {
      newChunks.push(vars.shift())
    } else {
      newChunks.push(pathChunk)
    }
  }

  return newChunks.join('/')
}
