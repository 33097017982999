import cookieManager from '@App/cookies/cookie_manager'

class AuthCookieManagerInstance {
  clearTokenCookie() {
    cookieManager.remove('access_token')
    cookieManager.remove('refresh_token')
  }

  setAccessToken(value: string) {
    cookieManager.set('access_token', value)
  }

  setRefreshToken(value: string) {
    cookieManager.set('refresh_token', value)
  }

  getAccessToken() {
    return cookieManager.get('access_token')
  }

  getRefreshToken() {
    return cookieManager.get('refresh_token')
  }

  existsTokens() {
    return !!(cookieManager.get('access_token') && cookieManager.get('refresh_token'))
  }

  getAccessTokenWithDefault() {
    return cookieManager.get('access_token') ? cookieManager.get('access_token') : ''
  }
}

const authCookieManager = new AuthCookieManagerInstance()

export default authCookieManager
