import { useEffect } from 'react'

// eslint-disable-next-line import/named
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import type { RootState, AppDispatch } from '@Redux/store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler && handler(event)
    }

    document.addEventListener('mousedown', listener, { passive: true })

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

export function useOnHoverOutside(ref: any, handler: any) {
  useEffect(
      () => {
        const listener = (event: any) => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mouseover", listener);
        return () => {
          document.removeEventListener("mouseout", listener);
        };
      },
      [ref, handler]
  );
}
