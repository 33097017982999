import { store } from '@Redux/store'
import axios from 'axios'
import { detectIncognito } from 'detectincognitojs'

import authCookieManager from '@App/cookies/auth_cookie_manager'

const addAuthHeader = async (config: any) => {
  const access_token = authCookieManager.getAccessToken()

  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`
  }
}

const interceptor = async (config: any) => {
  config.headers['visitor-key'] = await getVisitorId()
  config.headers['domain'] = document.location.host

  await addAuthHeader(config)

  return config
}

const $host = axios.create({
  baseURL: process.env.PAYWAYS_API_URL,
})

$host.interceptors.request.use(interceptor)

function createFingerprint(): string {
  const fingerprint: string[] = []

  fingerprint.push(navigator.userAgent || '')
  fingerprint.push(navigator.language || '')
  fingerprint.push(navigator.platform || '')
  fingerprint.push((window.screen.width || 0).toString())
  fingerprint.push((window.screen.height || 0).toString())
  fingerprint.push(new Date().getTimezoneOffset().toString())
  fingerprint.push((navigator as any).deviceMemory || '')
  fingerprint.push((window as any).screen.isExtended || '')
  fingerprint.push(window.screen.orientation.type || '')
  fingerprint.push(navigator.vendor || '')
  fingerprint.push((navigator.cookieEnabled || false).toString())
  fingerprint.push((navigator.pdfViewerEnabled || false).toString())
  fingerprint.push((window.screen.colorDepth || 0).toString())
  fingerprint.push((navigator.hardwareConcurrency || 0).toString())

  Object.values(navigator.plugins).forEach((plugin) => {
    fingerprint.push(plugin.name)
  })

  detectIncognito().then((incognito) => {
    fingerprint.push(incognito.isPrivate.toString())
  })

  const fingerprintString = fingerprint.join('')
  const hash = generateHash(fingerprintString)

  return hash.toString(16)
}

function generateHash(value: string): bigint {
  let hash = BigInt(0)

  if (value.length === 0) {
    return hash
  }

  for (let i = 0; i < value.length; i++) {
    const char = BigInt(value.charCodeAt(i))
    hash = (hash << BigInt(2)) - hash + char
  }

  return hash
}
async function getVisitorId() {
  const fingerprint = createFingerprint()

  return fingerprint
}

$host.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      authCookieManager.clearTokenCookie()

      // window.location.reload()
    }

    return Promise.reject(error)
  },
)

const $authHost = axios.create({
  baseURL: process.env.PAYWAYS_API_URL,
})

// $authHost.interceptors.request.use((request) => {
//   if (request.method === 'post' || request.method === 'put') {
//     const request_data = new FormData()
//     request_data.append('json_data', JSON.stringify(request.data))
//     request.data = request_data
//   }
//
//   return request
// })

$authHost.interceptors.request.use(interceptor)

$authHost.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 403 || err.response.status === 401) {
      authCookieManager.clearTokenCookie()

      store.dispatch({
        type: 'user/clientLogout',
      })
    }

    return Promise.reject(err)
  },
)

export { $host, $authHost }
