import { $host, $authHost } from '@App/api/index'
import { IDataResponse } from '@App/api/interfaces'

import { ISimpleEarnEntity, ISimpleEarn, ISimpleEarnEntitySubscribe } from '@Redux/slices/earn/simple_earn/interfaces'

export const getSimpleEarns = async () => {
  try {
    const response = await $host.get<IDataResponse<ISimpleEarn[]>>('/api/simple-earns')
    if (typeof response === 'string') {
      return response as string
    }

    return response.data.data
  } catch (e: any) {
    return e.response.data.message
  }
}

export const subscribeSimpleEarn = async (params: ISimpleEarnEntitySubscribe) => {
  try {
    const response = await $authHost.post<IDataResponse<ISimpleEarnEntity[]>>(`/api/me/simple-earns/subscribe`, params)

    if (typeof response === 'string') {
      return response as string
    }

    return response.data.data
  } catch (e: any) {
    return e.response.data.message
  }
}

export const turnOnAutoTopUp = async (durationId: number) =>
  await $authHost.post<IDataResponse<any>>(`/api/me/simple-earns/${durationId}/auto-subscribe/turn-on`)

export const turnOffAutoTopUp = async (durationId: number) =>
  await $authHost.post<IDataResponse<any>>(`/api/me/simple-earns/${durationId}/auto-subscribe/turn-off`)
