import { RootState } from '@Redux/store'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IPublicServiceConnection } from '@App/redux/interfaces'
import PublicServiceConnection from '@App/sockets/public_service_connection_new'
import SocketMiddleware from '@App/sockets/socket_middleware'
import { Events, SocketState } from '@App/sockets/types'

import { getMarket } from '@Redux/slices/config/ConfigSlice'

import {
  IActualOrderBook,
  IActualRate,
  IActualTradesHistory,
  ICurrenciesSourceTarget,
  ITickerData,
  ITickerEntity,
  ITickerEntityData,
} from '@Interfaces/CommonInterfaces'
import { TradesEntity } from '@Interfaces/Entities'
const initialState: IPublicServiceConnection = {
  rates: {},
  rates_ticker: {
    entities: {},
    period: '',
  },
  order_book_by_market: {},
  trades_history_by_market: {},
  socket_status: SocketState.Closed,
}
export const setConfigPublicServiceConnection = createAsyncThunk('socket/setConfig', async (path: string) => {
  PublicServiceConnection.setApiUrl(path)
})
let processMessageFunc: any
const getProcessMessageFunc = (dispatch: any): (() => {}) => {
  if (processMessageFunc) {
    return processMessageFunc
  }
  processMessageFunc = (message: any) => {
    switch (message.event) {
      case Events.GetRate:
        const rate = message.payload.rate
        const rate_currency = message.payload.source_currency
        const rate_target_currency = message.payload.target_currency
        dispatch(
          setRateAsync({
            key: rate_currency + '_' + rate_target_currency,
            rate,
            time: message.payload.time,
            source_currency: rate_currency,
            target_currency: rate_target_currency,
          }),
        )
        break
      case Events.GetOrderBook:
        const order_book_source_currency = message.payload.source_currency
        const order_book_target_currency = message.payload.target_currency
        const order_book_market = message.payload.market
        dispatch(
          setOrderBookAsync({
            market: order_book_market,
            source_currency: order_book_source_currency,
            target_currency: order_book_target_currency,
            asks: message.payload.asks,
            bids: message.payload.bids,
          }),
        )
        break
      case Events.GetTrades:
        const trades_history_source_currency = message.payload.source_currency
        const trades_history_target_currency = message.payload.target_currency
        dispatch(
          setTradesHistoryAsync({
            source_currency: trades_history_source_currency,
            target_currency: trades_history_target_currency,
            trades: message.payload.trades,
          }),
        )
        break
      case Events.GetTicker:
        dispatch(
          setTickerDataAsync({
            entities: message.payload.entities,
            period: message.payload.period,
          }),
        )
        break
      case Events.SocketStatusConnection:
        dispatch(
          setPublicStatusAsync({
            socket_status: message.status,
          }),
        )
        break
    }
  }
  return processMessageFunc
}
export const setPublicStatusAsync = createAsyncThunk('socket/setPublicStatusAsync', async (data: any) => {
  return data
})
export const setRateAsync = createAsyncThunk('socket/setRateAsync', async (data: IActualRate) => {
  return data
})
export const setOrderBookAsync = createAsyncThunk('socket/setOrderBookAsync', async (data: IActualOrderBook) => {
  return data
})
export const setTradesHistoryAsync = createAsyncThunk(
  'socket/setTradesHistoryBookAsync',
  async (data: IActualTradesHistory) => {
    return data
  },
)
export const setTickerDataAsync = createAsyncThunk('socket/setTickerDataAsync', async (data: ITickerData) => {
  return data
})

export const subscribeToPublicService = createAsyncThunk(
  'socket/subscribeToPublicService',
  async ({ source_currency, target_currency, event }: ICurrenciesSourceTarget, { dispatch }) => {
    const params = []
    if (source_currency && target_currency) {
      params.push(source_currency)
      params.push(target_currency)
    }
    SocketMiddleware.addSubscriber(params, event, PublicServiceConnection, getProcessMessageFunc(dispatch))
    return null
  },
)
export const unsubscribeToPublicService = createAsyncThunk(
  'socket/unsubscribeToPublicService',
  async ({ source_currency, target_currency, event }: ICurrenciesSourceTarget) => {
    const params = []
    if (source_currency && target_currency) {
      params.push(source_currency)
      params.push(target_currency)
    }
    SocketMiddleware.deleteSubscriber(params, event, PublicServiceConnection)
    return { source_currency, target_currency }
  },
)
export const PublicServiceConnectionSlice = createSlice({
  name: 'public_service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setRateAsync.fulfilled, (state, action) => {
        if (
          !state.rates[action.payload.key]?.check_time ||
          Date.now() - state.rates[action.payload.key]?.check_time > 200
        ) {
          state.rates[action.payload.key] = {
            rate: action.payload.rate,
            time: action.payload.time,
            source_currency: action.payload.source_currency,
            target_currency: action.payload.target_currency,
            check_time: Date.now(),
          }
        }
      })
      .addCase(unsubscribeToPublicService.fulfilled, (state, action) => {
        delete state.rates[action.payload.source_currency + '_' + action.payload.target_currency]
      })
      .addCase(setOrderBookAsync.fulfilled, (state, action) => {
        const market = getMarket(action.payload.source_currency, action.payload.target_currency)
        state.order_book_by_market[market] = action.payload
      })
      .addCase(setTradesHistoryAsync.fulfilled, (state, action) => {
        const market = getMarket(action.payload.source_currency, action.payload.target_currency)
        const trades = []
        for (const i in action.payload.trades) {
          trades.push(new TradesEntity(action.payload.trades[i]))
        }
        if (state.trades_history_by_market[market]?.trades?.length) {
          if (
            !state.trades_history_by_market[market].time ||
            Date.now() - state.trades_history_by_market[market].time > 200
          ) {
            state.trades_history_by_market[market] = {
              source_currency: action.payload.source_currency,
              target_currency: action.payload.target_currency,
              trades: trades,
              time: Date.now(),
            }
          }
        } else {
          state.trades_history_by_market[market] = {
            source_currency: action.payload.source_currency,
            target_currency: action.payload.target_currency,
            trades: trades,
            time: Date.now(),
          }
        }
      })
      .addCase(setTickerDataAsync.fulfilled, (state, action) => {
        const allTickers = state.rates_ticker.entities
        const tickersUpdate: ITickerEntity = {}

        Array.isArray(action.payload.entities) &&
          action.payload.entities?.map(
            (entity: ITickerEntityData) =>
              (tickersUpdate[entity.source_currency + '_' + entity.target_currency] = entity),
          )

        state.rates_ticker.entities = Object.assign(allTickers, tickersUpdate)
      })
      .addCase(setPublicStatusAsync.fulfilled, (state, action) => {
        state.socket_status = action.payload.socket_status
      })
  },
})

export const getRates = (root: RootState) => {
  return root.public_service_connector.rates ?? {}
}

export const getRatesByCurrencies = (root: RootState, currency: string, target_currency: string) => {
  return root.public_service_connector.rates[currency + '_' + target_currency]?.rate
}

export const getTickerDataAll = (root: RootState): ITickerData => {
  return root.public_service_connector.rates_ticker ?? {}
}
export const getTickerData = (root: RootState, source: string, target: string) => {
  return root.public_service_connector.rates_ticker?.entities[getMarket(source, target, '_')] ?? {}
}

export const getOrderBookByCurrencies = (root: RootState, currency: string, target_currency: string) => {
  return root.public_service_connector.order_book_by_market[getMarket(currency, target_currency)] ?? {}
}

export const getTradesHistoryByCurrencies = (root: RootState, currency: string, target_currency: string) => {
  return root.public_service_connector.trades_history_by_market[getMarket(currency, target_currency)] ?? {}
}

export default PublicServiceConnectionSlice.reducer
