import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { patchTermsAndConditionsStatus, createTermsAndConditionsStatus } from '@App/api/profile/terms_and_conditions'
import { useAppDispatch } from '@App/hooks'

import { removeTermsAndConditionsFromState } from '@Redux/slices/terms_and_conditions/TermsAndConditionsSlice'

import { ITermsAndConditions } from '@Interfaces/CommonInterfaces'

import { Loader } from '@UI/crypto_exchange/desktop/components/common/Loader'
import { Button } from '@UI/crypto_exchange/desktop/components/controls/Button'
import { ModalTypes } from '@UI/crypto_exchange/desktop/components/modals/modalTypes'
import ModalWrapper from '@UI/crypto_exchange/desktop/components/modals/ModalWrapper'
import { TERMS_AND_CONDITIONS_STATUS } from '@UI/crypto_exchange/desktop/components/modals/TermAndConditionsModal/enums'

import c from './TermAndConditionsModal.module.scss'

interface ITermAndConditionsModalProps {
  currentTerm: ITermsAndConditions
}

export default function TermAndConditionsModal({
  setIsOpen,
  isOpen,
  withOutsideClick,
  currentTerm,
}: ModalTypes & ITermAndConditionsModalProps) {
  const dispatch = useAppDispatch()
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
  const [isSubmitAvailable, setIsSubmitAvailable] = useState<boolean>(false)
  const ref = useRef(null)
  const [refVisible, setRefVisible] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (refVisible && ref.current) {
      checkSubmitAvailable(ref.current)
    }
  }, [refVisible])

  const handleScroll = (event: BaseSyntheticEvent) => {
    checkSubmitAvailable(event.target)
  }

  function checkSubmitAvailable(element: HTMLTextAreaElement) {
    if (!isSubmitAvailable && element.scrollTop + element.clientHeight >= element.scrollHeight) {
      setIsSubmitAvailable(true)
    }
  }

  const closeModal = () => {
    setIsSubmitAvailable(false)
    setIsOpen()
    navigate('/')
  }

  const confirm = () => {
    setIsSubmitAvailable(false)
    setIsSubmitLoading(true)
    if (currentTerm.status_id !== null) {
      patchTermsAndConditionsStatus(currentTerm.status_id, TERMS_AND_CONDITIONS_STATUS.ACCEPTED)
        .then(() => dispatch(removeTermsAndConditionsFromState(currentTerm.id)))
        .finally(() => {
          setIsSubmitLoading(false)
          setIsOpen()
        })
    } else {
      createTermsAndConditionsStatus(currentTerm.id, TERMS_AND_CONDITIONS_STATUS.ACCEPTED)
        .then(() => dispatch(removeTermsAndConditionsFromState(currentTerm.id)))
        .finally(() => {
          setIsSubmitLoading(false)
          setIsOpen()
        })
    }
  }

  return (
    <ModalWrapper setIsOpen={closeModal} isOpen={isOpen} withOutsideClick={withOutsideClick}>
      {isSubmitLoading || currentTerm === undefined ? (
        <Loader />
      ) : (
        <div className={c.container}>
          <div className={c.head}>
            <div className={c.hintHeader}>{currentTerm.title}</div>
          </div>
          <div className={c.wrapper}>
            <textarea
              ref={(el) => {
                ref.current = el
                setRefVisible(!!el)
              }}
              className={`form-control form-control-sm fs-14px ${c.content}`}
              id={'content'}
              value={currentTerm.content}
              disabled={true}
              onScroll={handleScroll}
            />
          </div>
          <div className={c.buttonGroup}>
            <div className={`${c.button} ${c.cancel}`}>
              <Button color="dark" size="medium" onClick={closeModal}>
                Cancel
              </Button>
            </div>
            <div className={`${c.button} ${c.submit}`}>
              <Button onClick={confirm} size="medium" disabled={!isSubmitAvailable}>
                Accept
              </Button>
            </div>
          </div>
        </div>
      )}
    </ModalWrapper>
  )
}
