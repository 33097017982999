import { FC, useCallback, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@App/hooks'

import { clearNonGlobalNotifications, deleteNotify } from '@Redux/slices/notifications/NotificationSlice'

import classes from './Notification.module.scss'
import Toast from './Toast'
import { Position } from './types'

interface IToastContainerProps {
  delay?: number
}

export const Notifications = ({ delay }: IToastContainerProps) => {
  const notifications = useAppSelector((root) => root.notifications.notifications)
  const dispatch = useAppDispatch()

  const location = useLocation()

  const onClose = useCallback((id: string) => {
    dispatch(deleteNotify(id))
  }, [])

  useEffect(() => {
    dispatch(clearNonGlobalNotifications())
  }, [location.pathname])

  if (!notifications.length) return <div></div>

  return (
    <div className={classes.toastContainer}>
      {notifications.map(
        (notification) =>
          notification.message && (
            <Toast
              key={notification.id}
              id={notification.id}
              content={notification.message}
              type={notification.status}
              onClose={onClose}
              delay={delay}
            />
          ),
      )}
    </div>
  )
}
