// * FilterType
// like - substring(inputs)
// eq - = (selects)
// lt - <
// lte - <=
// gt - >
// gte - >=
// btw - between (date-pickers - YYYY_MM_DD,YYYY_MM_DD)
// in - array (multi-selects)

export type FilterType =
  | 'like'
  | 'eq'
  | 'lt'
  | 'lte'
  | 'gt'
  | 'gte'
  | 'btw'
  | 'in'
  | 'page'
  | 'per_page'
  | 'sort'
  | 'is_null'
  | 'not_null'
  | 'getByWalletType'
  | 'getByPeriod'

export type ParamsByFilter = { name: string; value: string | null; filterType: FilterType }

export default function requestByFilters(params: ParamsByFilter[], withNull: boolean = false) {
  return clearEmptyValue(params, withNull).reduce((str, item) => {
    if (item.filterType === 'page' || item.filterType === 'per_page') {
      return `${str}${!str.length ? '' : '&'}${item.filterType}=${item.value}`
    }
    return `${str}${!str.length ? '' : '&'}${item.filterType}[]=${item.name},${item.value}`
  }, '')
}

const clearEmptyValue = (params: ParamsByFilter[], withNull: boolean) =>
  params.filter((item) => ((item.value && !withNull) || (item.value === null && withNull) ? item : null))
