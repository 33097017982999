import { SVGProps } from 'react'

export const PaywaysLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="98" height="49" viewBox="0 0 98 49" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.22 0L0.808594 48.6599L10.8068 48.66L28.2244 0H18.22Z" fill="currentColor" />
      <path d="M82.5738 41.0553C82.5537 41.0409 82.5335 41.0267 82.5131 41.0125L82.5738 41.0553Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9991 48.6535H25.0298L31.7477 38.3537L34.4515 48.6535H32.5153L32.1032 46.6962H28.1617L26.9991 48.6535ZM31.8218 45.3336L31.077 41.7959L28.9668 45.3336H31.8218Z"
        fill="currentColor"
      />
      <path
        d="M35.5252 38.4168H37.7398L39.2044 42.8902L42.2928 38.4168H44.4627L39.8008 44.5452L38.9933 48.6535H37.0524L37.8419 44.6228L35.5252 38.4168Z"
        fill="currentColor"
      />
      <path
        d="M45.1546 38.4168L45.1352 38.4423L46.7712 48.7714L51.4248 42.0513L53.5075 48.8242L59.1512 38.4168H56.9742L53.9723 44.5166L52.1654 38.4203L48.0561 44.6L47.4297 38.4168H45.1546Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.8404 48.6535H56.8711L63.589 38.3537L66.2928 48.6535H64.3566L63.9445 46.6962H60.0031L58.8404 48.6535ZM63.6631 45.3336L62.9184 41.7959L60.8081 45.3336H63.6631Z"
        fill="currentColor"
      />
      <path
        d="M70.8346 48.6535H68.8937L69.6832 44.6228L67.3665 38.4168H69.5812L71.0458 42.8902L74.1341 38.4168H76.304L71.6421 44.5452L70.8346 48.6535Z"
        fill="currentColor"
      />
      <path
        d="M76.9428 48.4507C76.399 48.2439 75.9212 47.9986 75.5076 47.7162L76.1164 46.0343C76.5062 46.3184 76.9786 46.579 77.4298 46.769C78.0165 47.0161 78.5876 47.1438 79.1407 47.1438C79.5589 47.1438 79.9241 47.0386 80.2037 46.7972L80.2089 46.7926C80.4849 46.5417 80.6228 46.2163 80.6228 45.8383C80.6228 45.4963 80.4749 45.2014 80.2138 44.962C79.9792 44.7293 79.5559 44.441 78.9708 44.0996C78.1798 43.6366 77.6345 43.1963 77.3119 42.7842C77.0015 42.3875 76.8406 41.9 76.8406 41.3056C76.8406 40.4212 77.1613 39.7019 77.8078 39.1273L77.8094 39.1259C78.4588 38.5395 79.2783 38.2402 80.2886 38.2402C81.4177 38.2402 82.4197 38.5105 83.3021 39.0469L82.6711 40.7871C82.3556 40.568 82.0061 40.3693 81.6339 40.2139C81.1483 39.9974 80.684 39.8824 80.2445 39.8824C79.8316 39.8824 79.471 39.9801 79.2068 40.2179C78.961 40.438 78.836 40.724 78.836 41.0554C78.836 41.3902 78.9814 41.6746 79.2411 41.8973C79.4694 42.0929 79.9575 42.404 80.6783 42.823C81.396 43.2538 81.8961 43.6819 82.1994 44.1012L82.2017 44.1043C82.5088 44.5167 82.6623 44.9836 82.6623 45.5145C82.6623 46.4822 82.328 47.2664 81.6608 47.8886L81.6594 47.8899C81.0044 48.509 80.1256 48.8301 78.9935 48.8301C78.2916 48.8301 77.6085 48.7039 76.9428 48.4507Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9663 48.6576H17.0195L20.656 38.5097C21.4845 38.4074 22.2456 38.3567 22.94 38.3567C24.3032 38.3567 25.1521 38.6673 25.8106 39.2058C26.3836 39.6744 26.6714 40.4174 26.5556 41.432C26.4727 42.1577 26.3033 42.7208 26.0344 43.1904C25.7662 43.6589 25.3899 44.0502 24.8705 44.4198C23.8574 45.1406 22.6496 45.2986 21.0838 45.299L20.1767 45.2614L18.9663 48.6576ZM23.666 40.2129C23.9081 40.2629 24.1598 40.3512 24.3431 40.5159C24.7053 40.8302 24.783 41.3232 24.7044 41.8097C24.5916 42.5077 24.1602 42.9307 23.6412 43.2646C23.0956 43.6128 22.4581 43.6763 21.8215 43.6763C21.6238 43.6763 21.3946 43.666 21.1348 43.6461L20.7765 43.6185L21.9739 40.2359L22.1308 40.2073C22.3646 40.1646 22.618 40.144 22.8898 40.144C23.1439 40.144 23.4168 40.1615 23.666 40.2129Z"
        fill="currentColor"
      />
      <path d="M42.5742 34.2891L54.8487 0H64.8475L52.575 34.2891H42.5742Z" fill="currentColor" />
      <path d="M22.1602 34.2891L34.4347 0H44.4334L32.1609 34.2891H22.1602Z" fill="currentColor" />
      <path d="M58.7852 34.2891L71.0597 0H81.0584L68.7859 34.2891H58.7852Z" fill="currentColor" />
      <path d="M75 34.2891L87.2745 0H97.2733L85.0008 34.2891H75Z" fill="currentColor" />
    </svg>
  )
}
