import { RootState } from '@Redux/store'
import { createSlice } from '@reduxjs/toolkit'

import { MODAL_STATE, MODAL_TYPES } from './enums'

import type { ModalSliceStateType, SetModalActionType } from './interfaces'
import type { PayloadAction } from '@reduxjs/toolkit'

//@ts-ignore
const initialState: ModalSliceStateType = {
  isOpen: MODAL_STATE.CLOSE,
  data: {},
  modalType: MODAL_TYPES.NONE as MODAL_TYPES,
}

const ModalSlice = createSlice({
  initialState,
  name: 'modals',
  reducers: {
    setModal(state, action: PayloadAction<SetModalActionType>) {
      state.modalType = action.payload.modalType
      state.data = action.payload.data
      state.isOpen = action.payload.isOpen ?? MODAL_STATE.OPEN
    },

    closeModal(state) {
      state.data = {}
      state.isOpen = MODAL_STATE.CLOSE
    },
  },
})

export const isOpen = (state: RootState, modalType: MODAL_TYPES) =>
  state.modals.isOpen && modalType === state.modals.modalType

export default ModalSlice.reducer

export const { closeModal, setModal } = ModalSlice.actions
