import { memo, SVGProps } from 'react'

export function ArrowDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M16.5 8.49023V10.7402L12 15.5102L7.5 10.7402V8.49023H16.5Z" />
    </svg>
  )
}

export function ArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M12.288 12l-3.89 3.89 1.768 1.767L15.823 12l-1.768-1.768-3.889-3.889-1.768 1.768 3.89 3.89z"></path>
    </svg>
  )
}

export function ExportIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M13 2v12.172l4.586-4.586L19 11l-7 7-7-7 1.414-1.414L11 14.172V2h2z" />
      <path d="M21 16h-2v4H5v-4H3v6h18v-6z" />
    </svg>
  )
}

export function OrderIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M6.093 8.889c-.567 0-1.031-.438-1.031-.972 0-.535.464-.973 1.03-.973h12.846V5H6.093C4.38 5 3 6.303 3 7.917v8.166C3 17.697 4.381 19 6.093 19H21V8.889H6.093zm12.845 8.167H6.093c-.567 0-1.031-.438-1.031-.973v-5.415c.33.107.68.165 1.03.165h12.846v6.223z" />
      <path d="M15.845 12.573l-1.453 1.371 1.453 1.38 1.464-1.38-1.464-1.37z" />
    </svg>
  )
}

export function ConvertHistoryIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M15 3.5a5.502 5.502 0 00-5.302 4.032 7.502 7.502 0 016.77 6.77A5.502 5.502 0 0015 3.5zM14.5 15a5.5 5.5 0 10-11 0 5.5 5.5 0 0011 0zm-8 0L9 17.5l2.5-2.5L9 12.5 6.5 15zM9 4H4v5l5-5zm11 16h-5l5-5v5z"
      />
    </svg>
  )
}

export function BuyCryptoHistoryIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-1xcppd7" {...props}>
      <path d="M9.707 12.729h4.5v-2h-4.5v2zM9.707 16.729h4.5v-2h-4.5v2z" />
      <path d="M13.5 3.3H5.7v17.5h12.5V7.9l-4.7-4.6zm2.7 15.5H7.7V5.3h5v3.5h3.5v10z" />
    </svg>
  )
}

export function TransactionSortIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9V6h13.5V2.5l5 5-5 5V9H3zm18 9v-3H7v-3.5l-5 5 5 5V18h14z"
        fill="currentColor"
      />
    </svg>
  )
}

export function NotFoundIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="none" className="mirror css-98k7zx" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 8H26v80h58V28H64V8zM36 37h38v4H36v-4zm0 9h38v4H36v-4zm38 9H36v4h38v-4zm-8 12l4 4-4 4-4-4 4-4zM50 18l-3 3 3 3 3-3-3-3z"
        fill="url(#not-found-data_svg__paint0_linear_22059_32288)"
      />
      <path opacity="0.3" d="M86 50l3-3 3 3-3 3-3-3zM47 21l3-3 3 3-3 3-3-3zM84 28H64V8l20 20z" fill="#929AA5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.172 73.171l14.5-14.5 5.656 5.658-14.5 14.5-5.656-5.657z"
        fill="url(#not-found-data_svg__paint1_linear_22059_32288)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51 48c0-8.837-7.163-16-16-16s-16 7.163-16 16 7.163 16 16 16 16-7.163 16-16zm4 0c0-11.046-8.954-20-20-20s-20 8.954-20 20 8.954 20 20 20 20-8.954 20-20z"
        fill="url(#not-found-data_svg__paint2_linear_22059_32288)"
      />
      <defs>
        <linearGradient
          id="not-found-data_svg__paint0_linear_22059_32288"
          x1="55"
          y1="8"
          x2="55"
          y2="88"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#929AA5" stopOpacity="0.1" />
          <stop offset="1" stopColor="#929AA5" stopOpacity="0.25" />
        </linearGradient>
        <linearGradient
          id="not-found-data_svg__paint1_linear_22059_32288"
          x1="4.172"
          y1="68.75"
          x2="24.328"
          y2="68.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#929AA5" />
          <stop offset="1" stopColor="#76808F" />
        </linearGradient>
        <linearGradient
          id="not-found-data_svg__paint2_linear_22059_32288"
          x1="15"
          y1="48"
          x2="55"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#929AA5" />
          <stop offset="1" stopColor="#76808F" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export function ExportIcon2(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-180rs0v">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 3h-3v6.95l-.303-.003-4.159.04 5.975 5.976 5.975-5.975-4.176-.01-.312-.002V3zm-10 11h3v4h11v-4h3v7h-17v-7z"
        fill="currentColor"
      />
    </svg>
  )
}

export function PaymentHistoryIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M3 5H21V8H3V5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 10.5V19H12.5278C11.5777 17.9385 11 16.5367 11 15C11 13.208 11.7856 11.5994 13.0313 10.5H3ZM5 15H9V17H5V15Z"
      />
      <path d="M20.9687 10.5C20.9792 10.5092 20.9896 10.5185 21 10.5278V10.5H20.9687Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 19C19.2091 19 21 17.2091 21 15C21 12.7909 19.2091 11 17 11C14.7909 11 13 12.7909 13 15C13 17.2091 14.7909 19 17 19ZM15 15L17 13L19 15L17 17L15 15Z"
      />
    </svg>
  )
}

export function EarnHistoryIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-180rs0v">
      <path d="M12.0135 1C9.30088 1 7.10183 3.19905 7.10183 5.91171C7.10183 6.83267 7.3553 7.69443 7.79628 8.43103C5.55306 9.4109 3.95023 11.5837 3.7896 14.1476H2V15.9337H3.91462C4.26878 17.6501 5.27957 19.1265 6.67276 20.0888L5.02451 21.7371L6.28746 23L8.3573 20.9302C9.023 21.1518 9.73515 21.2719 10.4753 21.2719H15.7789L17.3705 22.8635L18.6334 21.6006L17.2935 20.2606L21.6429 15.9112L21.6429 13.2243H19.7091C19.5089 12.122 19.0149 11.1221 18.311 10.3087L20.7459 7.87386H16.5177C16.7798 7.27288 16.9252 6.60927 16.9252 5.91171C16.9252 3.19905 14.7262 1 12.0135 1ZM8.88791 5.91171C8.88791 4.18547 10.2873 2.78608 12.0135 2.78608C13.7398 2.78608 15.1392 4.18547 15.1392 5.91171C15.1392 7.63795 13.7398 9.03734 12.0135 9.03734C10.2873 9.03734 8.88791 7.63795 8.88791 5.91171ZM10.674 5.91171L12.0135 4.57215L13.3531 5.91171L12.0135 7.25127L10.674 5.91171ZM9.7484 13.2344H14.2787V11.4484H9.7484V13.2344Z" />
    </svg>
  )
}

export const SearchCrossIcon = memo((props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      className="bn-sdd-icon css-4rrsbx"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-7.233 0l3.006 3.005-1.768 1.768L12 13.767l-3.005 3.005-1.768-1.768 3.005-3.005-3.005-3.005 1.768-1.767L12 10.23l3.005-3.005 1.768 1.767L13.767 12z"
        fill="currentColor"
      />
    </svg>
  )
})

export function MarginOrderIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-1cywrh5" {...props}>
      <path d="M20 5v6.87h-2V8.42L6.42 20 5 18.58 16.58 7h-3.43V5H20z" fill="currentColor"></path>
      <path
        d="M8.17 5.02c-1.72-.01-3.12 1.39-3.13 3.11-.01 1.72 1.39 3.12 3.11 3.13 1.72.01 3.12-1.39 3.13-3.11v-.02c0-1.72-1.39-3.11-3.11-3.11zm.02 4.24c-.62.01-1.12-.49-1.13-1.11v-.02c0-.61.5-1.11 1.11-1.11.62-.01 1.12.49 1.13 1.11.01.62-.49 1.12-1.11 1.13zM19.09 14.65c-.57-.56-1.34-.9-2.2-.91-1.72 0-3.11 1.39-3.11 3.11s1.39 3.11 3.11 3.11S20 18.57 20 16.85c0-.86-.35-1.64-.91-2.2zm-2.22 3.31c-.61-.01-1.1-.5-1.1-1.11 0-.61.5-1.11 1.11-1.11h.01c.61.01 1.11.51 1.1 1.12-.01.61-.51 1.11-1.12 1.1z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export function P2POrderIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-1cywrh5" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4H18.5H21V6.5V8.5H18.5V6.5H15V4ZM5.83333 9.99805C4.54467 9.99805 3.5 11.0427 3.5 12.3314V13.998H11.5V12.3314C11.5 11.0427 10.4553 9.99805 9.16667 9.99805H5.83333ZM14.8333 16C13.5447 16 12.5 17.0447 12.5 18.3333V20H20.5V18.3333C20.5 17.0447 19.4553 16 18.1667 16H14.8333ZM9 17.5H5.5V15.5H3V17.5V20H5.5H9V17.5ZM19 12.502C19 13.8827 17.8807 15.002 16.5 15.002C15.1193 15.002 14 13.8827 14 12.502C14 11.1212 15.1193 10.002 16.5 10.002C17.8807 10.002 19 11.1212 19 12.502ZM7.5 9C8.88071 9 10 7.88071 10 6.5C10 5.11929 8.88071 4 7.5 4C6.11929 4 5 5.11929 5 6.5C5 7.88071 6.11929 9 7.5 9Z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export function P2PShowMenu(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-1cywrh5" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21a9 9 0 100-18 9 9 0 000 18zM8.75 10.75h-2.5v2.5h2.5v-2.5zm9 0h-2.5v2.5h2.5v-2.5zm-7 0h2.5v2.5h-2.5v-2.5z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export function RefreshIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-1cywrh5" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.997 12.21a8.161 8.161 0 000-.42v.42zm-4.463 3.327l-2.608-2.608h7.07V20l-2.341-2.342A8.003 8.003 0 014.252 14h3.164a5.001 5.001 0 008.118 1.537zM19.747 10A8.003 8.003 0 006.343 6.343L4.001 4v7.071h7.07L8.466 8.464A5.001 5.001 0 0116.585 10h3.162zM4 12L4 11.845v.31A8.126 8.126 0 014 12z"
        fill="currentColor"
      ></path>
    </svg>
  )
}

export function LoanIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="css-1cywrh5" {...props}>
      <path
        d="M18.731 13.827a1.741 1.741 0 011.456.518c.68.68.792 1.823.305 2.758-1.266 2.438-4.209 4.667-8.034 4.878-3.062.17-6.148-.807-9.24-2.902l1.008-1.486c2.782 1.887 5.488 2.743 8.133 2.597 3.135-.174 5.547-2.001 6.541-3.914.143-.274.114-.568.018-.664-.041-.04-.182-.026-.581.337-2.667 2.498-6.129 3.072-10.251 1.75l.547-1.71c1.279.41 2.458.612 3.545.607-2.398-1.845-5.124-1.907-8.335-.195L3 14.818c.463-.249.94-.471 1.427-.668a8.242 8.242 0 01-1.005-3.962C3.422 5.667 7.037 2 11.496 2s8.074 3.667 8.074 8.188a8.245 8.245 0 01-.839 3.639zm-12.549-.229c2.97-.666 5.668.126 8.01 2.369 2.14-1.036 3.584-3.257 3.584-5.778 0-3.538-2.817-6.395-6.28-6.395s-6.28 2.858-6.28 6.394c0 1.23.34 2.403.966 3.41z"
        fill="currentColor"
      ></path>
      <path d="M9.5 9.5l2-2 2 2-2 2-2-2z" fill="currentColor"></path>
    </svg>
  )
}
