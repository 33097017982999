import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import ProxyServiceConnection from '@App/sockets/proxy_connection_new'
import SocketMiddleware from '@App/sockets/socket_middleware'
import { Events, RequestTypes, SocketState } from '@App/sockets/types'

import { asyncLogout, setUserInfo } from '@Redux/slices/user/UserSlice'

import { ISimpleResponse } from '@Interfaces/CommonInterfaces'

import type { IProxyServiceConnection, IProxyServiceMessage } from '@Redux/interfaces'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: IProxyServiceConnection = {
  socket_status: SocketState.Closed,
}

let processMessageFunc: any

const getProcessMessageFunc = (dispatch: any): any => {
  if (processMessageFunc) {
    return processMessageFunc
  }

  processMessageFunc = (message: IProxyServiceMessage) => {
    switch (message.event) {
      case Events.GetUserEvent: {
        if (message?.payload?.user) {
          dispatch(setUserInfo(message.payload.user))
        }
        break
      }

      case Events.UpdateToken: {
        if (!message.status) {
          console.log('work')
          dispatch(asyncLogout())

          // ProxyServiceConnection.unsubscribe(getProcessMessageFunc(dispatch))
          // ProxyServiceConnection.closeSocket()
        }
        break
      }

      case Events.SocketStatusConnection:
        dispatch(
          setProxyStatusAsync({
            socket_status: message.status,
          }),
        )
        break
    }
  }

  return processMessageFunc
}

export const setConfigProxyServiceConnection = createAsyncThunk(
  'socket/setProxyServiceConnectionConfig',
  async (path: string) => {
    ProxyServiceConnection.setApiUrl(path)
  },
)

export const setTokenProxyServiceConnection = createAsyncThunk(
  'socket/setProxyServiceConnectionToken',
  async (token: string, { dispatch }) => {
    SocketMiddleware.addSubscriber(
      [token],
      Events.GetUserEvent,
      ProxyServiceConnection,
      getProcessMessageFunc(dispatch),
    )
  },
)

export const updateTokenProxyServiceConnection = createAsyncThunk(
  'socket/setProxyServiceConnectionToken',
  async (token: string, { dispatch }) => {
    const old_token = ProxyServiceConnection.token

    SocketMiddleware.deleteSubscriber([old_token], Events.UpdateToken, ProxyServiceConnection)
    SocketMiddleware.addSubscriber([token], Events.UpdateToken, ProxyServiceConnection, getProcessMessageFunc(dispatch))

    SocketMiddleware.deleteSubscriber([old_token], Events.GetUserEvent, ProxyServiceConnection)
    SocketMiddleware.addSubscriber(
      [token],
      Events.GetUserEvent,
      ProxyServiceConnection,
      getProcessMessageFunc(dispatch),
    )

    // ProxyServiceConnection.updateToken(token)
    // ProxyServiceConnection.updateTokenActions(token)
  },
)

export const setProxyStatusAsync = createAsyncThunk('socket/setProxyStatusAsync', async (data: any) => {
  return data
})

export const setWalletsAsync = createAsyncThunk('socket/setWalletsAsync', async (data: any) => {
  return data
})

export const setRateAsync = createAsyncThunk('socket/setRateAsync', async (data: any) => {
  return data
})

export const setMessagesAsync = createAsyncThunk('socket/setMessagesAsync', async (data: any) => {
  return data
})

// export const subscribeToProxyService = createAsyncThunk('socket/subscribeToToProxyService', async (_, { dispatch }) => {
//   ProxyServiceConnection.subscribe(getProcessMessageFunc(dispatch))
//   return null
// })

// export const unsubscribeToToProxyService = createAsyncThunk(
//   'socket/unsubscribeToToProxyService',
//   async (_, { dispatch }) => {
//     ProxyServiceConnection.unsubscribe(getProcessMessageFunc(dispatch))
//     return null
//   },
// )

export const closeProxyServiceSocket = createAsyncThunk('socket/closeProxyServiceSocket', async () => {
  SocketMiddleware.deleteSubscriber([ProxyServiceConnection.token], Events.GetUserEvent, ProxyServiceConnection)
  SocketMiddleware.deleteSubscriber([ProxyServiceConnection.token], Events.UpdateToken, ProxyServiceConnection)
  ProxyServiceConnection.forceCloseSocket()
  return null
})

export const ProxyServiceConnectionSlice = createSlice({
  name: 'proxy_service',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<ISimpleResponse>) => {
      state.message = action.payload.message
      state.status = action.payload.status
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProxyStatusAsync.fulfilled, (state, action) => {
      state.socket_status = action.payload.socket_status
    })
  },
})

export default ProxyServiceConnectionSlice.reducer
