import { FC, memo } from 'react'

import { NavLink } from 'react-router-dom'

import { RegisterIcon } from '@App/static/icons/HeaderIcon'
import { ROUTE_NAMES } from '@App/ui/crypto_exchange/desktop/routes/routeNames'

import { Notification } from '@UI/crypto_exchange/desktop/components/common/Header/components/AuthorhizationButtons/components/Notification'

import { dropDownMenuLinks, ILinkItem } from '../../DropDownLinkData'
import { MenuItem } from '../MenuItem'

import classes from './AuthorizationButtons.module.scss'
import { DropDownMenuItemRightMenu } from './components/DropDownMenuItemRightMenu'
import { LoginButton } from './components/LoginButton'

export const AuthorhizationButtons: FC<{ login: boolean; handleLogout: () => void }> = memo(
  ({ login, handleLogout }) => {
    return (
      <div className={classes.authorizationContainer}>
        {login ? (
          <>
            <MenuItem title={'Wallet'} isDropDownMenu>
              <ul className={classes.dropDownMenu}>
                {dropDownMenuLinks.wallet.map((item: ILinkItem, index) => (
                  <DropDownMenuItemRightMenu key={index} item={item} />
                ))}
              </ul>
            </MenuItem>

            <MenuItem title={'Order'} isDropDownMenu>
              <ul className={classes.dropDownMenu}>
                {dropDownMenuLinks.orders.map((item: ILinkItem, index) => (
                  <DropDownMenuItemRightMenu key={index} item={item} />
                ))}
              </ul>
            </MenuItem>

            <LoginButton handleLogout={handleLogout} menuLinks={dropDownMenuLinks.login} />

            <Notification />
          </>
        ) : (
          <>
            <NavLink className={classes.logIn} to={ROUTE_NAMES.LOGIN}>
              Log In
            </NavLink>
            <NavLink className={classes.register} to={ROUTE_NAMES.REGISTRATION}>
              <RegisterIcon className={classes.registerIcon} />
              Register
            </NavLink>
          </>
        )}
      </div>
    )
  },
)
