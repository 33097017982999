import { Component } from 'react'

import ReactDOM from 'react-dom'

export default class Portal extends Component {
  el = document.createElement('div')

  componentDidMount(): void {
    this.el.className = `toast-container top-right`
    document.body.appendChild(this.el)
  }

  componentWillUnmount(): void {
    document.body.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}
