import { connections } from '@App/sockets/connections'
import { ISocketStatusConnection, SocketState } from '@App/sockets/types'
import { WebsocketClientInterface } from '@App/sockets/websocket_context'

export interface SubscriberFunctionInterface {
  (message: SubscriberMessageInterface): void
}

export interface SubscriberMessageInterface {
  event: string
  payload: {
    rate: number
    status_message: string
    status: boolean
    user: any
  }
  request_key: string
  status: string
}

export interface ISubscribeMethodParams {
  params: string[]
  event: string
}

export abstract class BasicServiceConnection implements WebsocketClientInterface {
  public token: string = ''
  protected apiUrl: string = ''

  public name: string = ''

  protected connection: WebSocket | any
  protected state: string = SocketState.Ready
  protected initialize: boolean = false

  protected is_force_close: boolean = false
  protected is_connected: boolean = false

  protected maximum_reconnect_attempts: number = 2
  protected count_to_reconnect: number = 0
  protected reconnect_attempts: number = 0
  protected reconnect_timeout: any = 0

  abstract subscribe(params: string[], event: string, callback: SubscriberFunctionInterface): void

  abstract unsubscribe(params: string[], event: string): void

  setApiUrl = (api_url: string) => {
    if (this.apiUrl !== api_url) {
      this.apiUrl = api_url
      this.initialize = true
    }
  }

  connected(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.is_connected) {
        resolve(true)
        return
      }

      const int = setInterval(() => {
        if (this.is_connected) {
          resolve(true)
          clearInterval(int)
          return
        }
      }, 100)
    })
  }

  isConnected = () => {
    return this.is_connected
  }

  closeSocket = () => {
    if (this.isConnected()) {
      const that = this
      if (this.connection && this.connection.close) {
        return new Promise<void>((resolve) => {
          const close_connection = this.connection.close(1000, 'Close connect')
          if (close_connection) {
            close_connection.then(() => {
              that.is_force_close = true
              that.is_connected = false
              that.state = SocketState.Closed
              that.connection = null
              resolve()
            })
          }
        })
      }
    }
  }

  forceCloseSocket = () => {
    this.is_force_close = true
    const that = this

    if (this.connection && this.connection.close) {
      return new Promise<void>((resolve) => {
        const close_connection = this.connection.close(1000, 'Close connect')
        if (close_connection) {
          console.log('connection closed1')
          close_connection
            .then(() => {
              that.connection = null
              that.is_connected = false
              that.state = SocketState.Closed
              resolve()
            })
            .catch((e: any) => {
              console.log(e.message)
              resolve()
            })
        }
      })
    } else {
      console.log('connection closed2')
      that.connection = null
      that.is_connected = false
      that.state = SocketState.Closed
      return Promise.resolve()
    }
  }

  clearData(): void {}

  protected getPingPongMessage(): string {
    return JSON.stringify({
      event: 'service:ping',
      payload: {
        time: new Date().getTime(),
      },
    })
  }

  refreshIntervalId: any = null

  tryToPingPong = (interval: number) => {
    if (this.isConnected() && this.getConnection()) {
      this.getConnection().send(this.getPingPongMessage())

      if (this.refreshIntervalId) {
        clearInterval(this.refreshIntervalId)
      }

      this.refreshIntervalId = setInterval(() => {
        if (this.getConnection()) {
          this.waitForConnection(() => this.getConnection().send(this.getPingPongMessage()))
        } else {
          clearInterval(this.refreshIntervalId)
        }
      }, interval * 1000)
    }
  }

  waitForConnection = (callback: () => any, interval: number = 50) => {
    if (this.connection && this.connection.readyState === 1) {
      callback()
    } else {
      const that = this
      setTimeout(function () {
        that.waitForConnection(callback, interval)
      }, interval)
    }
  }

  safeConnectionWrapper = (fn: () => any) => {
    if (this.isConnected() && this.getConnection()) {
      fn()
    } else {
      if (this.apiUrl) {
        this.connection = this.establishConnection(() => {})
        this.connected().then(() => {
          fn()
        })
      }
    }
  }

  startPingPong(interval: number) {
    this.waitForConnection(() => this.tryToPingPong(interval))
  }

  log(message: MessageEvent) {
    console.log('==== log === ')
    console.log(message)
  }

  processMessage(message: MessageEvent | ISocketStatusConnection, func: SubscriberFunctionInterface): boolean | void {
    try {
      const json = JSON.parse(message.data)
      const payload = json.payload

      switch (json.event) {
        case 'service:settings':
          if (payload['ping']) {
            this.startPingPong(payload['ping'])
          }
          return true
      }

      func(json)
    } catch (e) {}
  }

  establishConnection(func: SubscriberFunctionInterface): WebSocket {
    const socket = new WebSocket(this.apiUrl)

    socket.onopen = () => {
      this.is_connected = true
      this.reconnect_attempts = 0

      const socketStatusData = JSON.stringify({
        event: 'socket_status_connection',
        status: SocketState.Ready,
      })

      const socketStatusConnection: ISocketStatusConnection = {
        data: socketStatusData,
      }

      this.processMessage(socketStatusConnection, func)
    }

    socket.onmessage = (message: MessageEvent) => {
      this.processMessage(message, func)
    }

    socket.onclose = () => {
      console.log('[STATUS] Socket closed: ' + this.apiUrl + ': ' + this.reconnect_attempts)
      this.is_connected = false
      this.connection = null

      const socketStatusData = JSON.stringify({
        event: 'socket_status_connection',
        status: SocketState.Closed,
      })

      const socketStatusConnection: ISocketStatusConnection = {
        data: socketStatusData,
      }

      if (!this.is_force_close) {
        console.log('force close BASIC CONNECTION')
        this.reconnect(1)
      } else {
        this.is_force_close = false
      }
      this.processMessage(socketStatusConnection, func)
    }

    socket.onerror = (err) => {
      console.log('error', err)
    }

    return socket
  }

  reconnect(maximum_reconnect_attempts: number = 0) {
    console.log('Socket state:', this.state)
    if (this.state === SocketState.Closed) {
      return false
    }

    this.reconnect_timeout = setInterval(() => {
      console.log(
        '[STATUS] Socket reconnecting: ',
        //  + this.apiUrl + ': ' + this.reconnect_attempts + ': ' + this.reconnect_timeout,
      )
      this.reconnect_attempts++

      const connectionsByService = connections.getConnectionsByServiceName(this.name)

      for (const key in connectionsByService) {
        const { params, event, callback } = connectionsByService[key]

        this.subscribe(params, event, callback)
      }

      const real_max_reconnect = maximum_reconnect_attempts
        ? maximum_reconnect_attempts
        : this.maximum_reconnect_attempts

      if (real_max_reconnect && this.reconnect_attempts >= real_max_reconnect) {
        this.state = SocketState.Closed

        console.log('[STATUS] Socket connection finished: ')
        clearInterval(this.reconnect_timeout)
        this.reconnect_attempts = 0
        return false
      }

      if (this.connection) {
        if (this.reconnect_timeout) {
          clearInterval(this.reconnect_timeout)
        }
        return true
      }
    }, 1000)
  }

  async connect(): Promise<WebSocket> {
    if (!this.is_connected) {
      this.connection = this.establishConnection(() => {})
    }

    return new Promise((resolve) => {
      this.connection.onopen = () => resolve(this.connection)
    })
  }

  send = (message: any) => {
    if (this.is_connected && this.connection && this.connection.send) {
      this.connection.send(JSON.stringify(message))
    }
  }

  getConnection(): WebSocket {
    return this.connection
  }
}
