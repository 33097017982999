import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getPaymentGateways } from '@App/api/profile/gateway'
import { RootState } from '@App/redux/store'

import { IPaymentGateway } from './interfaces'

import type { PayloadAction } from '@reduxjs/toolkit'

const paymentGatewaysSlice = createSlice({
  name: 'paymentGateways',
  initialState: {
    data: [],
    isLoading: false,
  } as {
    data: IPaymentGateway[]
    isLoading: boolean
  },
  reducers: {
    setPaymentGateways(state, action: PayloadAction<IPaymentGateway[]>) {
      state.data = action.payload
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentGatewaysAsync.fulfilled, (state, action: any) => {
      state.data = action.payload
      state.isLoading = false
    })
    builder.addCase(getPaymentGatewaysAsync.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPaymentGatewaysAsync.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const getPaymentGatewaysAsync = createAsyncThunk(
  'payment-gateways/get',
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    const statePaymentGateways = (getState as () => RootState)().paymentGateways.data

    if (!statePaymentGateways.length) {
      const paymentGateways = await getPaymentGateways()

      if (typeof paymentGateways === 'string') {
        return rejectWithValue('error')
      }

      return fulfillWithValue(paymentGateways.data)
    }
    return fulfillWithValue(statePaymentGateways)
  },
)

export default paymentGatewaysSlice.reducer
