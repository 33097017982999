import { IConfigState } from '@Redux/interfaces'

import { getDataWrapper } from '@App/api/helper'
import { ICountry, ITimeZone } from '@App/redux/slices/config/interfaces'

import { IDataResponse } from './interfaces'

import { $host, $authHost } from './index'

const header_link = process.env.PAYWAYS_HEADER_LINK

export const fetchConfig = async () => {
  $authHost.interceptors.request.use((config: any) => {
    if (header_link) {
      config.headers.link = header_link
    }

    return config
  })

  return getDataWrapper<IDataResponse<IConfigState>>($host.get('api/config'))
}

export const getCountries = async () => {
  try {
    const response = await $host.get<IDataResponse<ICountry[]>>('/api/countries')
    if (typeof response === 'string') {
      return response as string
    }

    return response.data.data
  } catch (e: any) {
    return e.response.data.message
  }
}

export const getTimezones = async () => {
  try {
    const response = await $host.get<IDataResponse<ITimeZone[]>>('/api/timezones')
    if (typeof response === 'string') {
      return response as string
    }

    return response.data.data
  } catch (e: any) {
    return e.response.data.message as string
  }
}
