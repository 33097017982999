import { IRates, ITickerData, User } from '@App/interfaces/CommonInterfaces'

import { ILanguage } from '../slices/config/interfaces'

export interface ITVHistoryFeed {
  provider: string
  all_symbol_url: string
  history_url: string
  api_key: string
}

export interface IConfigState {
  proxyServiceWsApi: string
  frontServiceWsApi: string
  publicServiceWsApi: string
  coinRateApi: string
  currencies: IConfigCurrency[]
  markets: IConfigMarket[]
  history_feeds: ITVHistoryFeed[]
  languages: ILanguage[] // todo: type this
  isConfigLoading: boolean
}

export class ConfigMarket implements IConfigMarket {
  amount_precision: number
  commission: number
  cross_rate: number
  enable_in_exchange: number
  enable_in_isolated: number
  enable_in_spot: number
  enable_swap_exchange: number
  enabled: boolean
  id: number
  is_popular: number
  isolate_rate: number
  price_precision: number
  source_currency: string
  source_currency_code: string
  target_currency: string
  target_currency_code: string
  title: string

  public constructor(raw_market: any) {
    this.amount_precision = raw_market.amount_precision
    this.commission = raw_market.commission
    this.cross_rate = raw_market.cross_rate
    this.enable_in_exchange = raw_market.enable_in_exchange
    this.enable_in_isolated = raw_market.enable_in_isolated
    this.enable_in_spot = raw_market.enable_in_spot
    this.enable_swap_exchange = raw_market.enable_swap_exchange
    this.enabled = raw_market.enabled
    this.id = raw_market.id
    this.is_popular = raw_market.is_popular
    this.isolate_rate = raw_market.isolate_rate
    this.price_precision = raw_market.price_precision
    this.source_currency = raw_market.source_currency
    this.source_currency_code = raw_market.source_currency_code
    this.target_currency = raw_market.target_currency
    this.target_currency_code = raw_market.target_currency_code
    this.title = raw_market.title
  }

  enableInIsolatedMargin(): boolean {
    return this.isolate_rate > 0 && this.enable_in_isolated == 1
  }

  enableInMargin(): boolean {
    return this.cross_rate > 0
  }

  enableInSpot(): boolean {
    return this.enable_in_spot == 1
  }
}

export interface IConfigCurrency {
  code: string
  title: string
  type: string
  symbol: string
  enabled: boolean
  is_base: boolean
  deleted: boolean | undefined
  deposit_enabled: boolean
  withdrawal_enabled: boolean
  provide_enabled: boolean
  loan_enabled: boolean
}

export interface IConfigMarket {
  amount_precision: number
  commission: number
  enable_in_isolated: number
  enable_in_exchange: number
  enable_in_spot: number
  enable_swap_exchange: number
  cross_rate: number
  isolate_rate: number
  enabled: boolean
  id: number
  is_popular: number
  price_precision: number
  source_currency: string
  source_currency_code: string
  target_currency: string
  target_currency_code: string
  title: string
  enableInMargin: () => boolean
  enableInIsolatedMargin: () => boolean
  enableInSpot: () => boolean
}

// Notification

export interface INotification {
  id: string
  message: string
  description?: string
  status: 'default' | 'success' | 'error' | 'info' | 'warning' | ''
  isResolved?: boolean
}

export interface ISimpleNotify {
  id: string
  message: string
  status: string
}

// common connection

export interface IFrontServiceConnection {
  status: boolean | undefined
  message: string | undefined
  socket_status: 'closed' | 'ready'
}

// currency service

export interface IOrderBookOffer {
  id: number
  price: number
  quantity?: number
}

interface IOrderBookByMarkets {
  [key: string]: IOrderBookByMarket
}

interface IOrderBookByMarket {
  asks: any
  bids: any
}

export interface ITradesEntity {
  is_buyer: boolean
  price: number
  quantity: number
  source_currency: string
  target_currency: string
  timestamp: number

  getTime(): string
}

interface ITradesHistoryByMarkets {
  [key: string]: ITradesHistoryByMarket
}

interface ITradesHistoryByMarket {
  source_currency: string
  target_currency: string
  trades: ITradesEntity[]
  time: number
}

export interface IPublicServiceConnection {
  rates_ticker: ITickerData
  rates: IRates
  order_book_by_market: IOrderBookByMarkets
  trades_history_by_market: ITradesHistoryByMarkets
  socket_status: 'closed' | 'ready'
}

export interface IFrontServiceConnectionSlice {
  socket_status: string
}

export enum WalletTypes {
  TYPE_COIN = 'coin',
  TYPE_FIAT = 'fiat',
  TYPE_SPOT = 'spot',
  TYPE_2P2 = 'p2p',
  TYPE_MARGIN_CROSS = 'margin_cross',
  TYPE_MARGIN_ISOLATED = 'margin_isolated',
  TYPE_P2P = 'p2p',
}

// proxy service
export interface IWallet {
  amount: number
  currency: string
  type: WalletTypes
  enabled: number
  id: number
}

export interface IProxyServiceMessage {
  event: string
  status: number
  payload: {
    user: User
  }
}

export interface IProxyServiceConnection {
  socket_status: string
  status?: boolean
  message?: string | undefined
}

// user

export interface IUser {
  login: boolean
  user_id: number
  username: any | string | null
  user_info: User | null
  is_loading: boolean
  check_load: boolean
  theme: string
  curr_wallet: string
  favorite_ticker_id: []
  is_success: boolean
  required_two_fa: boolean
}

export interface IExchangeRequestMessage {
  message: string
  token: string
  user_id: number
  amount: number | string
  source_currency: string
  target_currency: string
  request_id: string
  wallet_type: string
}

export interface ISpotOrderRequestMessage {
  token: string
  user_id: number | null
  amount: number | null
  source_currency: string
  target_currency: string
  action: string
  rate: number | null
  request_id: string
  system?: 'margin' | 'isolated_margin'
}

export interface ISpotOrderRequest {
  event: string
  request_key: string
  api: string
  token: string
  user_id: number | null
  payload: {
    amount: number
    source_currency: string
    target_currency: string
    action: string
    rate: number | null
    system?: 'margin' | 'isolated_margin'
  }
}

export type TStatus = 'success' | 'error' | 'info' | 'warning' | 'warn' | 'open'

export interface IQueueTask {
  id: string
  type: string
  status: TStatus
}

export interface IBackNotification {
  id: string
  original_type: string
  type: string
  data: IBackNotificationData
  read_at: string
  created_at: string
}

export interface IBackNotificationData {
  title: string
  message: string
  id: string
}

export interface ISimpleEarnEntity {
  id: number
  currency_code: string
  amount: number
  amount_btc: number
  total_amount_fee?: number
  next_day_amount_fee?: number
  duration?: number
  accrue_days?: number
  apr_yesterday?: number
  apr_sum?: number
  tiers?: ISimpleEarnDurationTires[]
  paid_bonus_amount?: number
  apr: number
  is_auto_subscribe: boolean
  is_auto_subscribe_available: boolean
  status: string
  interest_next_date?: string
  interest_end_date?: string
  subscription_date?: string
  redemption_date?: string
  interest_period?: number
}

export interface ISimpleEarnDurationTires {
  id: number
  from_amount: number
  to_amount: number
  apr: number
}

export interface ISimpleEarnEntityGeneralInfo {
  total_value: number
  estimated_positions: number
  total_profit: number
  last_day_profit: number
  total_value_convert: number
  estimated_positions_convert: number
  total_profit_convert: number
  last_day_profit_convert: number
}
