import jwtDecode from 'jwt-decode'

import { $authHost, $host } from '@App/api'
import { getDataWrapper } from '@App/api/helper'
import authCookieManager from '@App/cookies/auth_cookie_manager'
import { IRegisterData, IRegisterResponse } from '@App/redux/slices/user/types'

import { IChangePasswordDataRequest, IJwtDecodeData, IResetDataRequest } from '@Interfaces/CommonInterfaces'

export const login = async (email: string, password: string, verification_code: number | null = null) => {
  try {
    const loginData: {
      username: string
      password: string
      verification_code?: number
    } = { username: email, password }

    if (verification_code) {
      loginData['verification_code'] = verification_code
    }

    const { data } = await $host.post('api/auth/login', loginData)

    const jwtDecodeData = jwtDecode<IJwtDecodeData>(data.data.access_token)

    if (data.data.access_token && data.data.refresh_token) {
      authCookieManager.setAccessToken(data.data.access_token)
      authCookieManager.setRefreshToken(data.data.refresh_token)
    } else {
      return { status: false, message: 'Invalid token' }
    }
    return { ...jwtDecodeData, access_token: data.data.access_token }
  } catch (e: any) {
    return { status: false, message: e.response.data.message, data: e.response.data?.data }
  }
}

export const check = async () => {
  const { data } = await $authHost.get('api/me')

  if (!data) {
    authCookieManager.setAccessToken('')
    return {
      token: null,
      user_info: null,
    }
  } else {
    return {
      token: authCookieManager.getAccessToken(),
      user_info: data.data,
    }
  }
}

export const refreshToken = async (refresh_token: string | undefined) => {
  try {
    const { data } = await $authHost.post('api/auth/refresh-token', { refresh_token: refresh_token })

    if (data.data.access_token && data.data.refresh_token) {
      authCookieManager.setAccessToken(data.data.access_token)
      authCookieManager.setRefreshToken(data.data.refresh_token)
      return data
    } else {
      closeUser()
    }
  } catch (e) {
    closeUser()
  }

  return null
}

const closeUser = () => {
  authCookieManager.clearTokenCookie()
  window.dispatchEvent(new Event('logout_global'))
  return null
}

export const resetPassLink = async (email: string) => {
  try {
    const response = await getDataWrapper($host.post('api/auth/forgot-password', { email }))

    return { ...(response as Object), status: true }
  } catch (e: any) {
    return { ...e.response, status: false }
  }
}

export const resetPass = async (params: IResetDataRequest) => {
  try {
    return await getDataWrapper($host.post('api/auth/reset-password', params))
  } catch (e: any) {
    return e.response.data.message
  }
}

export const register = async (payload: IRegisterData) => {
  try {
    const { data }: { data: IRegisterResponse } = await $host.post('api/auth/signup', payload)

    return data
  } catch (e: any) {
    return e.response.data.message
  }
}

export const logoutPost = async () => {
  const data = await $authHost.post('api/auth/logout')

  if (data) {
    authCookieManager.clearTokenCookie()
  }

  return data
}

export const changePassword = async (params: IChangePasswordDataRequest) => {
  try {
    return await getDataWrapper($authHost.post('api/auth/change-password', params))
  } catch (e: any) {
    return e.response.data.message
  }
}
