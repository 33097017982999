import { ISubscriber } from '@App/sockets/types'

export class Queue {
  public oldestIndex: number = 1
  public newestIndex: number = 1
  public storage: {
    [key: number]: ISubscriber
  } = {}

  size() {
    return this.newestIndex - this.oldestIndex
  }

  enqueue(data: any) {
    this.storage[this.newestIndex] = data
    this.newestIndex++
  }

  dequeue() {
    const oldestIndex = this.oldestIndex
    const newestIndex = this.newestIndex

    if (oldestIndex !== newestIndex) {
      const deletedData = this.storage[oldestIndex]
      delete this.storage[oldestIndex]
      this.oldestIndex++

      return deletedData
    } else {
      return null
    }
  }
}
