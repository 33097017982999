import { IQueueTask } from '@Redux/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  queueTasks: [] as IQueueTask[],
}

const addNewQueueTask = (queueTask: IQueueTask, state: any) => {
  if (queueTask) {
    state.queueTasks.push(queueTask)
  }

  return state.queueTasks
}

export const QueueTaskSlice = createSlice({
  name: 'queueTasks',
  initialState,
  reducers: {
    addQueueTask: (state, action: PayloadAction<IQueueTask>) => {
      const queueTasks = state.queueTasks.filter((queueTask) => action.payload.id === queueTask.id)

      if (queueTasks.length === 0) {
        state.queueTasks = addNewQueueTask({ ...action.payload }, state)
      }
    },

    deleteQueueTask: (state, action: PayloadAction<string>) => {
      state.queueTasks = state.queueTasks.filter((queueTask) => queueTask.id !== action.payload)
    },
  },
})

export const { addQueueTask, deleteQueueTask } = QueueTaskSlice.actions

export default QueueTaskSlice.reducer
