import { ITradesEntity } from '@Redux/interfaces'

export class TradesEntity implements ITradesEntity {
  is_buyer: boolean
  price: number
  quantity: number
  source_currency: string
  target_currency: string
  timestamp: number

  constructor({ is_buyer, price, quantity, source_currency, target_currency, timestamp }: ITradesEntity) {
    this.is_buyer = is_buyer
    this.price = price
    this.quantity = quantity
    this.source_currency = source_currency
    this.target_currency = target_currency
    this.timestamp = timestamp
  }

  getTime() {
    const d = new Date(this.timestamp)
    return d.toLocaleTimeString()
  }
}
