import { nanoid } from 'nanoid'

import { connections } from '@App/sockets/connections'
import { Queue } from '@App/sockets/queue'
import { RequestTypes, ISubscriber, IConnection, ServiceClass } from '@App/sockets/types'

import { BasicServiceConnection, SubscriberFunctionInterface } from './basic_connection'

class SocketMiddleware {
  queue = new Queue()

  addSubscriber(
    params: string[],
    event: string,
    service: BasicServiceConnection,
    func: SubscriberFunctionInterface,
  ): void {
    const key = params.reduce((string, param) => {
      return `${string}_${param}`
    }, `${service.name}_${event}`)

    const time = new Date().getTime()

    const newSubscriber: ISubscriber = {
      id: nanoid(),
      type: 'subscribe',
      params,
      service,
      event,
      time,
      key,
      func,
    }

    this.queue.enqueue(newSubscriber)

    this.workingWithQueue()
  }

  deleteSubscriber(params: string[], event: string, service: ServiceClass) {
    const key = params.reduce((string, param) => {
      return `${string}_${param}`
    }, `${service.name}_${event}`)

    const time = new Date().getTime()

    const newSubscriber = {
      id: nanoid(),
      type: 'unsubscribe',
      params,
      service,
      event,
      time,
      key,
    }

    this.queue.enqueue(newSubscriber)

    this.workingWithQueue()
  }

  workingWithQueue() {
    if (this.queue.size() === 0) return

    const firstSubscriberInQueue: ISubscriber | null = this.queue.dequeue()

    if (firstSubscriberInQueue) {
      const { key, params, type, event, service, func } = firstSubscriberInQueue

      switch (type) {
        case RequestTypes.Subscribe:
          if (connections.getConnection(service.name, key)) {
            connections.addSubscriber(service.name, key, firstSubscriberInQueue)
          } else {
            connections.addFunctionSubscriber(service.name, func)
            const newSocketConnect: IConnection = {
              key,
              subscribers: [firstSubscriberInQueue],
              params,
              event,
              service,
              callback: func,
            }

            service.subscribe(params, event, func)

            connections.addConnection(service.name, key, newSocketConnect)
          }
          break

        case RequestTypes.Unsubscribe:
          if (!connections.getConnection(service.name, key)) break

          connections.deleteSubscriber(service.name, key)

          if (connections.isConnectionsByServiceName(service.name)) {
            service.unsubscribe(params, event)
            connections.deleteConnection(service.name, key)
          }

          break
      }
    }

    this.workingWithQueue()
  }
}

export default new SocketMiddleware()
