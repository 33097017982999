import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getUnreadTermsAndConditions } from '@App/api/profile/terms_and_conditions'
import authCookieManager from '@App/cookies/auth_cookie_manager'

import { ITermsAndConditions } from '@Interfaces/CommonInterfaces'

interface ITermsAndConditionsStateInterface {
  data: ITermsAndConditions[]
  loading: boolean
}

const initialState: ITermsAndConditionsStateInterface = {
  data: [],
  loading: false,
}

export const getTermsAndConditionsAsync = createAsyncThunk('terms-and-conditions/get', async (_, { dispatch }) => {
  if (!authCookieManager.existsTokens()) {
    throw new Error('Invalid token')
  }

  return await getUnreadTermsAndConditions()
})

export const termsAndConditionsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeTermsAndConditionsFromState: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter((item) => item.id !== action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTermsAndConditionsAsync.pending, (state) => {
        state.loading = true
      })
      .addCase(getTermsAndConditionsAsync.fulfilled, (state, action: any) => {
        state.data = action.payload.data
        state.loading = false
      })
  },
})

export const { removeTermsAndConditionsFromState } = termsAndConditionsSlice.actions

export default termsAndConditionsSlice.reducer
