import { $authHost, $host } from '@App/api'
import { getDataWrapper } from '@App/api/helper'
import { IDataResponse } from '@App/api/interfaces'

import { IPaymentGateway } from '@Redux/slices/profile/payment/interfaces'

export const getPaymentGateway = async (type: string) => {
  return await getDataWrapper($authHost.get(`api/payment-gateways/${type}`))
}

export const getPaymentGateways = async (): Promise<IDataResponse<IPaymentGateway[]>> => {
  return await getDataWrapper($authHost.get(`api/payment-gateways/`))
}

export const deposit = async (params: any) => {
  try {
    return await getDataWrapper($authHost.post(`api/me/deposit`, params))
  } catch (e: any) {
    return { error: e.response.data.message }
  }
}
