import { ReactElement } from 'react'

import cn from 'classnames'
import { Link } from 'react-router-dom'

import { Loader } from '@UI/crypto_exchange/desktop/components/common/Loader'

import c from './Button.module.scss'

interface IButonOptions {
  type?: 'button' | 'submit' | 'reset' | undefined
  link?: string
  children: ReactElement | string
  className?: string
  isLoading?: boolean
  onClick?: () => void
  size?: 'small' | 'medium' | ''
  color?: 'dark' | 'green' | 'red' | ''
  disabled?: boolean
}

export const Button = ({
  className = '',
  type,
  isLoading,
  children,
  link,
  onClick,
  disabled = false,
  size = '',
  color = '',
}: IButonOptions) => {
  if (link) {
    return (
      <Link className={`${cn(className, c.button, c[size], c[color])}`} to={link}>
        {children}
      </Link>
    )
  }

  return (
    <button
      className={`${cn(c.button, c[size], c[color], className, (isLoading || disabled) && c.disabled)}`}
      type={type}
      disabled={isLoading || disabled}
      onClick={onClick}
    >
      {!isLoading ? children : <Loader size={size} type={'blocks'} />}
    </button>
  )
}
