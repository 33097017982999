import { IFrontServiceConnection } from '@Redux/interfaces'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import FrontServiceConnection from '@App/sockets/front_service_connection_new'
import { SocketState } from '@App/sockets/types'

import { subscribeToFrontService } from './FrontServiceConnectionSlice'

export const authToFrontServiceCommon = createAsyncThunk(
  'socket/authToFrontService',
  async (token: string, { dispatch }) => {
    FrontServiceConnection.auth(token)
    dispatch(subscribeToFrontService())
    return null
  },
)

const initialState: IFrontServiceConnection = {
  status: undefined,
  message: '',
  socket_status: SocketState.Closed,
}

export const CommonConnectionSlice = createSlice({
  name: 'front_service',
  initialState,
  reducers: {},
})

export default CommonConnectionSlice.reducer
