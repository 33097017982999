import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getExchangesOrderData } from '@App/api/profile/exchange_order'
import { ParamsByFilter } from '@App/helpers/requestByFilters'

interface ExchangeOrdersStateInterface {
  exchangeOrders: any
}

const initialState: ExchangeOrdersStateInterface = {
  exchangeOrders: [],
}

export const getExchangeOrderAsync = createAsyncThunk('exchange-order/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getExchangesOrderData(filters)

  if (data?.data) {
    return {
      exchange_orders: data.data.map((exchange_order: any) => {
        return exchange_order
      }),
    }
  } else {
    return { exchange_orders: [] }
  }
})

export const ExchangeOrdersSlice = createSlice({
  name: 'exchange_orders',
  initialState,
  reducers: {
    getExchangeOrders: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getExchangeOrderAsync.fulfilled, (state, action: any) => {
      state.exchangeOrders = action.payload.exchange_orders
    })
  },
})

export const { getExchangeOrders } = ExchangeOrdersSlice.actions

export default ExchangeOrdersSlice.reducer
