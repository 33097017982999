import { SVGProps } from 'react'

export const Success = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4 12L10 18L20 6" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const Warning = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g transform="rotate(180 774.5 27.5)">
        <g fill="#fff" transform="translate(-.358 -1.639)">
          <circle cx="1.134" cy="1.134" r="1.134" transform="rotate(180 771.246 22.823)" />
          <path d="M1 0a1 1 0 00-1 1v5a1 1 0 002 0V1a1 1 0 00-1-1z" transform="rotate(180 771.17 26.882)" />
        </g>
      </g>
    </svg>
  )
}

export const Info = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g transform="translate(-1533 -39)">
        <g fill="#fff" transform="translate(-.358 -1.639)">
          <circle cx="1.134" cy="1.134" r="1.134" transform="rotate(180 771.246 22.823)" />
          <path d="M1 0a1 1 0 00-1 1v5a1 1 0 002 0V1a1 1 0 00-1-1z" transform="rotate(180 771.17 26.882)" />
        </g>
      </g>
    </svg>
  )
}

export const Close = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <g transform="translate(.077 .077)">
        <g>
          <path
            fill="#fff"
            d="M10.915 9.98l2.853-2.846a.666.666 0 00-.942-.942L9.979 9.044 7.133 6.191a.666.666 0 00-.942.942L9.044 9.98 6.19 12.826a.666.666 0 10.942.942l2.846-2.853 2.846 2.853a.666.666 0 10.942-.942z"
            transform="translate(-2.017 -2.018)"
          />
        </g>
      </g>
    </svg>
  )
}
