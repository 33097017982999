import { ConfigMarket, IConfigState } from '@Redux/interfaces'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { fetchConfig } from '@App/api/config'
import { generateRequestKey } from '@App/sockets/helpers'

import { setConfigFrontServiceConnection } from '@Redux/slices/sockets/FrontServiceConnectionSlice'
import { setConfigProxyServiceConnection } from '@Redux/slices/sockets/ProxyServiceConnectionSlice'
import { setUserExpTimeForToken } from '@Redux/slices/user/UserSlice'

import { createNotificationObject } from '../notifications/NotificationSlice'
import { setConfigPublicServiceConnection } from '../sockets/PublicServiceConnectionSlice'

import type { PayloadAction } from '@reduxjs/toolkit'

export const getMarket = (source_currency: string, target_currency: string, separator: string = '') =>
  [source_currency, target_currency].join(separator)

const initialState: IConfigState = {
  proxyServiceWsApi: '',
  frontServiceWsApi: '',
  publicServiceWsApi: '',
  coinRateApi: '',
  currencies: [],
  markets: [],
  history_feeds: [],
  languages: [],
  isConfigLoading: false,
}

export const setConfig = createAsyncThunk('socket/setConfig', async (_, { dispatch }) => {
  const data = await fetchConfig()

  if (data) {
    dispatch(setConfigFrontServiceConnection(data.data.frontServiceWsApi))
    dispatch(setConfigPublicServiceConnection(data?.data?.publicServiceWsApi))
    dispatch(setConfigProxyServiceConnection(data.data.proxyServiceWsApi))
    dispatch(setUserExpTimeForToken())
  } else {
    dispatch(createNotificationObject({ message: 'Server Error', id: generateRequestKey('global'), status: 'error' }))
  }
  return data.data
})

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateFromApi: (state, action: PayloadAction<IConfigState>) => {
      state.proxyServiceWsApi = action.payload.proxyServiceWsApi
      state.frontServiceWsApi = action.payload.frontServiceWsApi
      state.publicServiceWsApi = action.payload.publicServiceWsApi
      state.coinRateApi = action.payload.coinRateApi
      state.currencies = action.payload.currencies
      state.markets = action.payload.markets.map((m: any) => new ConfigMarket(m))
      state.history_feeds = action.payload.history_feeds
      state.languages = action.payload.languages
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setConfig.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.proxyServiceWsApi = action.payload.proxyServiceWsApi
        state.frontServiceWsApi = action.payload.frontServiceWsApi
        state.publicServiceWsApi = action.payload.publicServiceWsApi
        state.coinRateApi = action.payload.coinRateApi
        state.currencies = action.payload.currencies
        state.markets = action.payload.markets.map((m: any) => new ConfigMarket(m))
        state.history_feeds = action.payload.history_feeds
        state.languages = action.payload.languages
        state.isConfigLoading = false
      }
    })
    builder.addCase(setConfig.pending, (state) => {
      state.isConfigLoading = true
    })
  },
})

export const { updateFromApi } = configSlice.actions

export default configSlice.reducer
