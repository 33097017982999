import { P2PMarket, P2PMarketRangePrice, P2POffer, PayMethod } from '@Redux/interfaces/p2p'
import { AnyAction, AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { IMeta } from '@App/api/interfaces'
import { getP2PMarkets, getP2PMarketRangePrice, getP2POffersData, getP2PPayMethods } from '@App/api/p2p'
import { ParamsByFilter } from '@App/helpers/requestByFilters'

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>

const paginationInitialState = {
  from: 0,
  last_page: 0,
  current_page: 0,
  per_page: 0,
  to: 0,
  total: 0,
  path: '',
}

interface P2PStateInterface {
  p2pOffers: {
    data: P2POffer[]
    pagination: IMeta
  }
  p2pMarkets: P2PMarket[]
  payMethods: PayMethod[]
  marketsRangePrice: P2PMarketRangePrice[]
  isLoading: boolean
}

const initialState: P2PStateInterface = {
  p2pOffers: {
    data: [],
    pagination: paginationInitialState,
  },
  p2pMarkets: [],
  payMethods: [],
  marketsRangePrice: [],
  isLoading: false,
}

export const getP2POffersAsync = createAsyncThunk('p2p/offers/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getP2POffersData(filters)

  if (data?.data) {
    return {
      p2pOffers: {
        data: data?.data,
        pagination: data?.meta,
      },
    }
  } else {
    return {
      p2pOffers: {
        data: data?.data,
        pagination: paginationInitialState,
      },
    }
  }
})

export const getP2PMarketsAsync = createAsyncThunk('p2p/markets/get', async () => {
  const data = await getP2PMarkets()
  return data?.data ? { p2pMarkets: data.data } : { p2pMarkets: [] }
})

export const getP2PPayMethodsAsync = createAsyncThunk('p2p/pay_methods/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getP2PPayMethods(filters)
  return data?.data ? { payMethods: data.data } : { payMethods: [] }
})

export const getP2PMarketRangePriceAsync = createAsyncThunk('p2p/offers/price-ranges/get', async () => {
  const data = await getP2PMarketRangePrice()
  return data?.data ? { marketsRangePrice: data.data } : { marketsRangePrice: [] }
})

const isPendingAction = (action: AnyAction): action is PendingAction =>
  [
    getP2POffersAsync.pending.type,
    getP2PMarketsAsync.pending.type,
    getP2PPayMethodsAsync.pending.type,
    getP2PMarketRangePriceAsync.pending.type,
  ].includes(action.type)

export const P2PSlice = createSlice({
  name: 'p2p',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getP2POffersAsync.fulfilled, (state, action) => {
        state.p2pOffers = action.payload.p2pOffers
        state.isLoading = false
      })
      .addCase(getP2PMarketsAsync.fulfilled, (state, action) => {
        state.p2pMarkets = action.payload.p2pMarkets
        state.isLoading = false
      })
      .addCase(getP2PPayMethodsAsync.fulfilled, (state, action) => {
        state.payMethods = action.payload.payMethods
        state.isLoading = false
      })
      .addCase(getP2PMarketRangePriceAsync.fulfilled, (state, action) => {
        state.marketsRangePrice = action.payload.marketsRangePrice
        state.isLoading = false
      })
      .addMatcher<PendingAction>(isPendingAction, (state) => {
        state.isLoading = true
      })
  },
})

export default P2PSlice.reducer
