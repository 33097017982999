import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getFiatRates } from '@App/api/currencies'
import { RootState } from '@App/redux/store'

import { FiatRate } from './interfaces'

import type { PayloadAction } from '@reduxjs/toolkit'

const RatesSlice = createSlice({
  name: 'rates',
  initialState: {
    data: [],
    isLoading: false,
  } as {
    data: FiatRate[]
    isLoading: boolean
  },
  reducers: {
    setRates(state, action: PayloadAction<FiatRate[]>) {
      state.data = action.payload
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFiatRatesAsync.fulfilled, (state, action: any) => {
      state.data = action.payload
      state.isLoading = false
    })
    builder.addCase(getFiatRatesAsync.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getFiatRatesAsync.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const getFiatRatesAsync = createAsyncThunk(
  'rates/get',
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    const stateRates = (getState as () => RootState)().rates.data
    if (!stateRates.length) {
      const rates = await getFiatRates()

      if (typeof rates === 'string') {
        return rejectWithValue('error')
      }

      return fulfillWithValue(rates.data)
    }
    return fulfillWithValue(stateRates)
  },
)

export const isRatesLoading = (state: RootState) => state.rates.isLoading
export default RatesSlice.reducer
