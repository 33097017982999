import { $authHost } from '@App/api'
import { getDataWrapper } from '@App/api/helper'
import { ITransferData } from '@App/api/interfaces'
import requestByFilters, { ParamsByFilter } from '@App/helpers/requestByFilters'

import {
  IAllWallets,
  ICrossWallet,
  IIsolatedWalletsByMarket,
  IP2pWallet,
  IRFWithPagination,
  ISpotWallet,
} from '@Interfaces/CommonInterfaces'

export const getCrossWalletsDetails = async (
  filters: ParamsByFilter[] = [],
): Promise<IRFWithPagination<ICrossWallet[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''

  return await getDataWrapper($authHost.get(`api/wallets/cross-margin${filterString}`))
}

export const getIsolatedWalletsDetails = async (
  filters: ParamsByFilter[] = [],
): Promise<IRFWithPagination<IIsolatedWalletsByMarket[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''

  return await getDataWrapper($authHost.get(`api/wallets/isolated-margin${filterString}`))
}

export const getSpotWalletsDetails = async (
  filters: ParamsByFilter[] = [],
): Promise<IRFWithPagination<ISpotWallet[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''

  return await getDataWrapper($authHost.get(`api/wallets/spot${filterString}`))
}

export const getP2pWalletsDetails = async (
  filters: ParamsByFilter[] = [],
): Promise<IRFWithPagination<IP2pWallet[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''

  return await getDataWrapper($authHost.get(`api/wallets/p2p${filterString}`))
}

export const getAllWalletsDetails = async (): Promise<IAllWallets> => {
  return await getDataWrapper($authHost.get('api/wallets/all'))
}

export const repayMarginDebt = async ({ walletId, amount }: { walletId: string; amount: string }) => {
  return await getDataWrapper(
    $authHost.post(`api/wallets/margin/${walletId}/repayment`, {
      amount: amount,
    }),
  )
}

export const marginTransfer = async (transferData: ITransferData) => {
  return await $authHost.post(`api/wallets/margin/transfer`, transferData)
}
