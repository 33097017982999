import { IExchangeRequestMessage, ISpotOrderRequest, ISpotOrderRequestMessage } from '@App/redux/interfaces'
import { SubscriberFunctionInterface } from '@App/sockets/basic_connection'

import { connections } from './connections'
import { generateRequestKey } from './helpers'
import { ISocketStatusConnection, SocketState } from './types'

export const FrontServiceName = 'FrontService'

export const ChannelExchangeOrder = 'exchange_order_request.raw'
export const ChannelSpotOrder = 'spot_order_request.raw'
export const EventUserRequest = 'service:user_request'
export const RefreshTokenResponse = 'service:refresh_token_response'

const ApiSpotOrderService = 'go.spot_order_service'
const ApiExchangeOrderService = 'go.exchange_order_service'
const ApiMarginOrderService = 'go.margin_order_service'

const MarginSystem = 'margin'
const IsolatedMarginSystem = 'isolated_margin'

const spotOrderApiBySystem: any = {
  [MarginSystem]: ApiMarginOrderService,
  [IsolatedMarginSystem]: ApiMarginOrderService,
}

class FrontServiceConnection {
  public token: string = ''
  protected apiUrl: string = ''

  public name: string = FrontServiceName
  public path: string = ''

  protected connection: WebSocket | any
  protected state: string = SocketState.Ready
  protected initialize: boolean = false

  protected is_force_close: boolean = false
  protected is_connected: boolean = false

  public queue: any[] = []

  protected maximum_reconnect_attempts: number = 3
  protected count_to_reconnect: number = 0
  protected reconnect_attempts: number = 0
  protected reconnect_timeout: any = 0

  public subscribers: SubscriberFunctionInterface[] = []

  setApiUrl = (api_url: string) => {
    if (this.apiUrl !== api_url) {
      this.apiUrl = api_url
      this.initialize = true
      if (this.queue && this.queue.length) {
        this.queue.forEach((fn) => fn())
      }
    }
  }

  connected(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.is_connected) {
        resolve(true)
        return
      }

      const int = setInterval(() => {
        if (this.is_connected) {
          resolve(true)
          clearInterval(int)
          return
        }
      })
    })
  }

  isConnected = () => {
    return this.is_connected
  }

  addSubscriber(fn: SubscriberFunctionInterface) {
    this.subscribers.push(fn)
  }

  removeSubscriber = (fn: SubscriberFunctionInterface) => {
    this.subscribers = this.subscribers.filter((fns) => {
      return fns !== fn
    })
  }

  subscribe(fn: SubscriberFunctionInterface) {
    this.addSubscriber(fn)
  }

  unsubscribe(fn: SubscriberFunctionInterface) {
    this.removeSubscriber(fn)
  }

  closeSocket = () => {
    if (this.isConnected()) {
      const that = this
      if (this.connection && this.connection.close) {
        return new Promise<void>((resolve) => {
          const close_connection = this.connection.close(1000, 'Close connect')
          if (close_connection) {
            close_connection.then(() => {
              that.is_force_close = true
              that.is_connected = false
              that.state = SocketState.Closed
              that.connection = null
              resolve()
            })
          }
        })
      }
    }
  }

  forceCloseSocket = () => {
    this.is_force_close = true
    const that = this

    if (this.connection && this.connection.close) {
      return new Promise<void>((resolve) => {
        const close_connection = this.connection.close(1000, 'Close connect')
        if (close_connection) {
          console.log('connection closed1')
          close_connection
            .then(() => {
              that.connection = null
              that.is_connected = false
              that.state = SocketState.Closed
              resolve()
            })
            .catch((e: any) => {
              console.log(e.message)
              resolve()
            })
        }
      })
    } else {
      console.log('connection closed2')
      that.connection = null
      that.is_connected = false
      that.state = SocketState.Closed
      return Promise.resolve()
    }
  }

  protected getPingPongMessage(): string {
    return JSON.stringify({
      event: 'service:ping',
      payload: {
        time: new Date().getTime(),
      },
    })
  }

  refreshIntervalId: any = null

  tryToPingPong = (interval: number) => {
    if (this.isConnected() && this.getConnection()) {
      this.getConnection().send(this.getPingPongMessage())

      if (this.refreshIntervalId) {
        clearInterval(this.refreshIntervalId)
      }

      this.refreshIntervalId = setInterval(() => {
        if (this.getConnection()) {
          this.waitForConnection(() => this.getConnection().send(this.getPingPongMessage()))
        } else {
          clearInterval(this.refreshIntervalId)
        }
      }, interval * 1000)
    }
  }

  waitForConnection = (callback: () => any, interval: number = 50) => {
    if (this.connection && this.connection.readyState === 1) {
      callback()
    } else {
      const that = this
      setTimeout(function () {
        that.waitForConnection(callback, interval)
      }, interval)
    }
  }

  safeConnectionWrapper = (fn: () => any) => {
    if (this.isConnected() && this.getConnection()) {
      fn()
    } else {
      if (this.apiUrl) {
        this.connection = this.establishConnection()
        this.connected().then(() => {
          fn()
        })
      }
    }
  }

  startPingPong(interval: number) {
    this.waitForConnection(() => this.tryToPingPong(interval))
  }

  log(message: MessageEvent) {
    console.log('==== log === ')
    console.log(message)
  }

  processMessage(message: MessageEvent | ISocketStatusConnection): boolean | void {
    try {
      const json = JSON.parse(message.data)
      const payload = json.payload

      switch (json.event) {
        case 'service:settings':
          if (payload['ping']) {
            this.startPingPong(payload['ping'])
          }
          return true
      }

      this.subscribers.forEach((subscriber) => subscriber(json))
    } catch (e) {}
  }

  establishConnection(): WebSocket {
    const socket = new WebSocket(this.apiUrl)

    socket.onopen = () => {
      this.is_connected = true
      this.reconnect_attempts = 0

      const socketStatusData = JSON.stringify({
        event: 'socket_status_connection',
        status: SocketState.Ready,
      })

      const socketStatusConnection: ISocketStatusConnection = {
        data: socketStatusData,
      }

      this.processMessage(socketStatusConnection)
    }

    socket.onmessage = (message: MessageEvent) => {
      this.processMessage(message)
    }

    socket.onclose = () => {
      console.log('[STATUS] Socket closed: ' + this.apiUrl + ': ' + this.reconnect_attempts)
      this.is_connected = false
      this.connection = null

      const socketStatusData = JSON.stringify({
        event: 'socket_status_connection',
        status: SocketState.Closed,
      })
      const socketStatusConnection: ISocketStatusConnection = {
        data: socketStatusData,
      }

      if (!this.is_force_close) {
        this.reconnect(2)
      } else {
        this.is_force_close = false
      }

      this.processMessage(socketStatusConnection)
    }

    return socket
  }

  reconnect = (maximum_reconnect_attempts: number = 0) => {
    if (this.state === SocketState.Closed) {
      return false
    }

    const reconnect_timeout = setInterval(() => {
      console.log(
        '[STATUS] Socket reconnecting: ' + this.apiUrl + ': ' + this.reconnect_attempts + ': ' + this.reconnect_timeout,
      )

      this.reconnect_attempts++

      const real_max_reconnect = maximum_reconnect_attempts
        ? maximum_reconnect_attempts
        : this.maximum_reconnect_attempts

      if (real_max_reconnect && this.reconnect_attempts >= real_max_reconnect) {
        this.state = SocketState.Closed

        console.log('[STATUS] Socket connection finished: ' + this.apiUrl)
        clearInterval(reconnect_timeout)
        this.reconnect_attempts = 0
        return false
      }

      this.connection = this.establishConnection()

      if (this.connection) {
        if (reconnect_timeout) {
          clearInterval(reconnect_timeout)
        }
        return true
      }
    }, 1000)
  }

  private checkConnection = () => {
    if (!this.getConnection()) {
      this.connection = this.establishConnection()
    }
  }

  async connect(): Promise<WebSocket> {
    this.checkConnection()

    return new Promise((resolve) => {
      this.connection.onopen = () => resolve(this.connection)
    })
  }

  send = (message: any) => {
    if (this.is_connected && this.connection && this.connection.send) {
      this.connection.send(JSON.stringify(message))
    }
  }

  getConnection(): WebSocket {
    return this.connection
  }

  private getAuthMessage(token: string | null): any {
    return {
      event: 'service:auth_request',
      request_key: generateRequestKey(),
      token: token,
      payload: {
        token: token,
      },
    }
  }

  public auth(token: string | null) {
    try {
      if (this.getConnection()) {
        this.connected().then(() => {
          this.send(this.getAuthMessage(token))
        })
      } else {
        if (this.apiUrl) {
          this.connection = this.establishConnection()
          this.connected().then(() => {
            this.send(this.getAuthMessage(token))
          })
        } else {
          const that = this
          this.queue.push(() => that.auth(token))
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  private getExchangeRequestMessage(requestMessage: IExchangeRequestMessage): string {
    return JSON.stringify({
      event: EventUserRequest,
      request_key: requestMessage.request_id,
      api: ApiExchangeOrderService,
      // channel: requestMessage.message,
      token: requestMessage.token,
      user_id: requestMessage.user_id,
      payload: {
        amount: requestMessage.amount,
        source_currency: requestMessage.source_currency,
        target_currency: requestMessage.target_currency,
        wallet_type: requestMessage.wallet_type,
      },
    })
  }

  public makeExchangeRequest(requestMessage: IExchangeRequestMessage) {
    this.checkConnection()

    this.getConnection()?.send(this.getExchangeRequestMessage(requestMessage))
  }

  private getSpotOrderRequestMessage(requestMessage: ISpotOrderRequestMessage): string {
    const messageToProccess: ISpotOrderRequest = {
      event: EventUserRequest,
      request_key: requestMessage.request_id,
      api: ApiSpotOrderService,
      token: requestMessage.token,
      user_id: requestMessage.user_id,
      payload: {
        amount: Number(requestMessage.amount),
        source_currency: requestMessage.source_currency,
        target_currency: requestMessage.target_currency,
        action: requestMessage.action,
        rate: requestMessage.rate,
      },
    }

    if (requestMessage.system) {
      messageToProccess.payload.system = spotOrderApiBySystem[requestMessage.system]
      messageToProccess.api = spotOrderApiBySystem[requestMessage.system]
    }

    return JSON.stringify(messageToProccess)
  }
  // {"event": "make_request","request_key": "w", "uuid": "33212323362", "token":"token","user_id": 5,
  // "payload": {"system": "margin", "amount": 20,"source_currency": "BTC","target_currency": "USDT",
  //  "rate": 111, "user_id": "3", "action": "sell"}

  public makeSpotOrderRequest(requestMessage: ISpotOrderRequestMessage) {
    this.checkConnection()

    this.getConnection()?.send(this.getSpotOrderRequestMessage(requestMessage))
  }
}

export default new FrontServiceConnection()
