import {
  IExtendedLoan,
  IAssetOverviewObject,
  ILoanRepayment,
  ILoan,
  ILoanLiquidation,
  LoanRepayCalculation,
  LTVAdjustmentType,
  ILoanHistory,
} from '@Redux/interfaces/loan'

import { $authHost } from '@App/api'
import { getDataWrapper } from '@App/api/helper'
import { IDataResponse, IMeta } from '@App/api/interfaces'
import requestByFilters, { ParamsByFilter } from '@App/helpers/requestByFilters'

import { IRFWithPagination } from '@Interfaces/CommonInterfaces'

const paginationInitialState = {
  from: 0,
  last_page: 0,
  current_page: 0,
  per_page: 0,
  to: 0,
  total: 0,
  path: '',
}

export const getLoansData = async (filters: ParamsByFilter[] = []): Promise<{ data: ILoan[]; pagination: IMeta }> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''
  const data = await getDataWrapper<IDataResponse<ILoan[]>>($authHost.get(`api/me/loans${filterString}`))

  if (data?.data) {
    return {
      data: data.data,
      pagination: data.meta,
    }
  } else {
    return {
      data: [],
      pagination: paginationInitialState,
    }
  }
}

export const getAssetOverviewData = async (): Promise<IDataResponse<IAssetOverviewObject>> =>
  await getDataWrapper($authHost.get('api/me/loans/asset-overview'))

export const getLoan = async (id: string): Promise<IDataResponse<IExtendedLoan>> =>
  await getDataWrapper($authHost.get(`api/me/loans/${id}`))

export const renew = async (id: string): Promise<IDataResponse<any>> =>
  await getDataWrapper($authHost.post(`api/me/loans/${id}/renew`))

export const createLoanAsync = async (data: object): Promise<IDataResponse<any>> =>
  await getDataWrapper($authHost.post(`api/me/loans/create`, data))

export const getLoanRepayCalcAsync = async (
  id: string,
  params: object,
): Promise<IDataResponse<LoanRepayCalculation> | { error: string }> => {
  try {
    return await getDataWrapper(
      $authHost.get(`/api/me/loans/${id}/calc/repay`, {
        params: params,
      }),
    )
  } catch (e: any) {
    return {
      error: e.response.data.message,
    }
  }
}

export const getLoanRepayAsync = async (
  id: string,
  params: object,
): Promise<IDataResponse<LoanRepayCalculation> | { error: string }> => {
  try {
    return await getDataWrapper($authHost.post(`/api/me/loans/${id}/repay`, params))
  } catch (e: any) {
    return {
      error: e.response.data.message,
    }
  }
}

export const getLoanAdjustLtvCalcAsync = async (
  loan_id: string,
  params: object,
): Promise<IDataResponse<LTVAdjustmentType> | { error: string }> => {
  try {
    return await getDataWrapper(
      $authHost.get(`/api/me/loans/${loan_id}/calc/adjust-ltv`, {
        params: params,
      }),
    )
  } catch (e: any) {
    return {
      error: e.response.data.message,
    }
  }
}
export const loanAdjustLtvAsync = async (
  loan_id: string,
  params: object,
): Promise<IDataResponse<LTVAdjustmentType> | { error: string }> => {
  try {
    return await getDataWrapper($authHost.post(`/api/me/loans/${loan_id}/adjust-ltv`, params))
  } catch (e: any) {
    return {
      error: e.response.data.message,
    }
  }
}

export const getLoanRepaymentReportData = async (filters: ParamsByFilter[] = []) => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''
  const data = await getDataWrapper<IDataResponse<ILoanRepayment[]>>(
    $authHost.get(`api/me/loans/report/repayment${filterString}`),
  )

  if (data?.data) {
    return {
      data: data.data,
      pagination: data.meta,
    }
  } else {
    return {
      data: [],
      pagination: paginationInitialState,
    }
  }
}

export const getLoanLtvAdjustmentHistory = async (
  filters: ParamsByFilter[],
): Promise<IRFWithPagination<LTVAdjustmentType[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''

  return await getDataWrapper($authHost.get(`/api/me/loans/report/ltv-adjustment${filterString}`))
}

export const getLoanLiquidationReportData = async (filters: ParamsByFilter[] = []) => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''
  const data = await getDataWrapper<IDataResponse<ILoanLiquidation[]>>(
    $authHost.get(`api/me/loans/report/liquidation${filterString}`),
  )

  if (data?.data) {
    return {
      data: data.data,
      pagination: data.meta,
    }
  } else {
    return {
      data: [],
      pagination: paginationInitialState,
    }
  }
}

export const getLoansHistory = async (filters: ParamsByFilter[] = []) => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''
  const data = await getDataWrapper<IDataResponse<ILoanHistory[]>>(
    $authHost.get(`api/me/loans/report/history${filterString}`),
  )

  if (data?.data) {
    return {
      data: data.data,
      pagination: data.meta,
    }
  } else {
    return {
      data: [],
      pagination: paginationInitialState,
    }
  }
}
