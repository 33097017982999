import { useEffect } from 'react'

import { NotificationsIcon } from '@Static/icons/HeaderIcon'
import moment from 'moment/moment'

import { useAppDispatch, useAppSelector } from '@App/hooks'

import { MODAL_TYPES } from '@Redux/slices/modals/enums'
import { setModal } from '@Redux/slices/modals/ModalSlice'
import { getNotificationsAsync } from '@Redux/slices/profile/notification/NotificationSlice'

import classes from './Notification.module.scss'

export const Notification = () => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector((state) => state?.user_notification.notifications)
  const unread_notifications_count = useAppSelector(
    (state) => state?.user?.user_info && state?.user?.user_info?.unread_notifications_count,
  )

  useEffect(() => {
    if (unread_notifications_count) {
      dispatch(getNotificationsAsync([{ name: 'read_at', filterType: 'is_null', value: null }]))
    }
  }, [unread_notifications_count])

  const now = moment()
  const getHowOld = (date: Date) => {
    const duration = moment.duration(now.diff(moment.unix(date.getTime() / 1000)))

    if (duration.years()) {
      return `${duration.days()} years ago`
    }

    if (duration.months()) {
      return `${duration.days()} months ago`
    }

    if (duration.days()) {
      return `${duration.days()} days ago`
    }

    if (duration.hours()) {
      return `${duration.hours()} hours ago`
    }

    if (duration.seconds()) {
      return `${duration.seconds()} seconds ago`
    }

    return `Now`
  }

  return (
    <div className={classes.notificationContainer}>
      <div className={classes.iconContainer}>
        <NotificationsIcon width={24} height={24} />
        {!!unread_notifications_count && <div className={classes.countNotifications}>{unread_notifications_count}</div>}
      </div>
      {!!unread_notifications_count && (
        <div className={classes.dropDownMenu}>
          <div className={classes.dropDownMenuTop}>
            <div className={classes.dropDownMenuInfo}>
              <div>{notifications?.length ?? 0} pending</div>
              <div>Delete all</div>
              <div>View all</div>
            </div>
            <div className={classes.dropDownMenuItems}>
              <div className={classes.dropDownMenuLabelBlock}>
                {!!notifications &&
                  notifications?.map((notification, index) => (
                    <div
                      key={index}
                      className={classes.notify}
                      onClick={() =>
                        dispatch(
                          setModal({
                            modalType: MODAL_TYPES.NOTIFICATION_INFO,
                            data: {
                              notifications,
                              currentNotification: notification.id,
                            },
                          }),
                        )
                      }
                    >
                      <div>{notification?.data?.title}</div>
                      <div>{notification?.data?.message}</div>
                      <div>{getHowOld(new Date(notification?.created_at))}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
