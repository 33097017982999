import React from 'react'

import { ITradesEntity, WalletTypes, IOrderBookOffer } from '@Redux/interfaces'

export interface IInputProps {
  className?: string | undefined
  type?: string | undefined
  name?: string | undefined
  is_error?: boolean | undefined
  is_success?: boolean | undefined
  disabled?: boolean | undefined
  value: string | undefined
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void
  auto_complete?: string | undefined
  auto_focus?: boolean | undefined
  placeholder?: string | undefined
  native_placeholder?: string | undefined
  hint?: string | undefined
  show?: boolean | undefined
  tab_index?: number | undefined
  required?: boolean | undefined
}

export interface ICountryCodeProps extends IInputProps {
  dial_code?: string | undefined
  dial_phone?: string | undefined
  disabled_code_select?: string | undefined
  country?: string | undefined
  countries?: any | undefined
}

export interface ILoaderProps {
  type?: string | undefined
  fixed?: string | undefined
  centered?: boolean | undefined
  theme?: string | undefined
  className?: string | undefined
}

export interface ICurrenciesSourceTarget {
  source_currency?: string
  target_currency?: string
  event: string
}

// export interface IActualRate {
//   rate: number
//   key: string
// }

export interface IActualOrderBook {
  asks: IOrderBookOffer[]
  bids: IOrderBookOffer[]
  market: string
  source_currency: string
  target_currency: string
}
export interface IActualTradesHistory {
  trades: ITradesEntity[]
  source_currency: string
  target_currency: string
}

export interface ITickerData {
  entities: ITickerEntity
  period: string
}

export interface ITickerEntity {
  [key: string]: ITickerEntityData
}

export interface ITickerEntityData {
  open: number
  close: number
  high: number
  low: number
  volume_source_currency: number
  volume_target_currency: number
  source_currency: string
  target_currency: string
}

export interface IRates {
  [key: string]: IRate
}

interface IRate {
  rate: number
  check_time: number
  time: string
  source_currency: string
  target_currency: string
}

export interface IActualRate {
  key: string
  rate: number
  time: string
  source_currency: string
  target_currency: string
}

export interface ISimpleResponse {
  status: boolean
  message: string | undefined
}

export interface IMakeExchangeRateRequest {
  message: string
  token: string
  user_id: number
  amount: number
  source_currency: string
  target_currency: string
  wallet_type: string
}

export interface IMakeSpotOrderRequest {
  token: string
  user_id: number
  amount: number
  source_currency: string
  target_currency: string
  action: string
  rate: number
  system?: 'margin' | 'isolated_margin'
}

export interface ILoginRequest {
  username: string
  password: string
  verification_code?: number | null
}

export interface IDepositRequest {
  currency: string
  value: number
}

export interface IWithdrawalRequest {
  currency: string
  value: number
}

export interface IWallet {
  amount: number
  currency_code: string
  enabled: number
  id: string
  reserve_amount: number
  system: number
  type: string
  user_id: number
  available: number
  isMarginCrossWallet: () => boolean
  isMarginIsolatedWallet: () => boolean
  isFiatCoinIsolatedWallet: () => boolean
  isP2PWallet: () => boolean
}

export class Wallet implements IWallet {
  amount: number
  available: number
  currency_code: string
  enabled: number
  id: string
  reserve_amount: number
  system: number
  type: string
  user_id: number

  public constructor(raw_wallet: any) {
    this.amount = raw_wallet.amount
    this.currency_code = raw_wallet.currency_code
    this.enabled = raw_wallet.enabled
    this.id = raw_wallet.id
    this.reserve_amount = raw_wallet.reserve_amount
    this.system = raw_wallet.system
    this.type = raw_wallet.type
    this.user_id = raw_wallet.user_id
    this.available = raw_wallet.available ?? raw_wallet.amount
  }

  isFiatCoinIsolatedWallet(): boolean {
    return this.type == WalletTypes.TYPE_COIN || this.type == WalletTypes.TYPE_FIAT
  }

  isFiatWallet(): boolean {
    return this.type == WalletTypes.TYPE_FIAT
  }

  isMarginCrossWallet(): boolean {
    return this.type == WalletTypes.TYPE_MARGIN_CROSS
  }

  isMarginIsolatedWallet(): boolean {
    return this.type == WalletTypes.TYPE_MARGIN_ISOLATED
  }

  isP2PWallet(): boolean {
    return this.type == WalletTypes.TYPE_P2P
  }
}

export class CrossWallet extends Wallet implements ICrossWallet {
  created: boolean
  fee: number
  liability: number
  loan: number
  total: number
  in_order: number
  debt: number

  public constructor(raw_wallet: any) {
    super(raw_wallet)
    this.created = raw_wallet.created
    this.fee = raw_wallet.fee
    this.liability = raw_wallet.liability
    this.loan = raw_wallet.loan
    this.total = raw_wallet.total
    this.in_order = raw_wallet.in_order
    this.debt = raw_wallet.debt
  }
  isMarginCrossWallet(): boolean {
    return true
  }
}

export class IsolatedWallet extends Wallet implements IIsolatedWalletsByMarket {
  margin_isolated_rate: number
  margin_level: number
  source: ICrossWallet
  source_currency: string
  target: ICrossWallet
  target_currency: string
  title: string
  created: boolean

  public constructor(raw_wallet: any) {
    super(raw_wallet)
    this.margin_isolated_rate = raw_wallet.margin_isolated_rate
    this.margin_level = raw_wallet.margin_level
    this.source = new CrossWallet(raw_wallet.source)
    this.source_currency = raw_wallet.source_currency
    this.target = new CrossWallet(raw_wallet.target)
    this.target_currency = raw_wallet.target_currency
    this.title = raw_wallet.title
    this.created = raw_wallet.created
  }

  isMarginIsolatedWallet(): boolean {
    return true
  }
}

export interface ICrossWallet {
  available: number
  created: boolean
  currency_code: string
  fee: number
  id: string
  liability: number
  loan: number
  total: number
  in_order: number
  debt: number
}

export interface IIsolatedWalletsByMarket {
  created: boolean
  margin_isolated_rate: number
  margin_level: number
  source: ICrossWallet
  source_currency: string
  target: ICrossWallet
  target_currency: string
  title: string
  currency_code: string | undefined
}

export interface IMainWallet {
  id: number | null
  amount: number
  currency_code: string
  enabled: boolean
  reserve_amount: number
  system: number
}

export interface ISpotWallet extends IMainWallet {
  type: 'coin' | 'fiat'
}

export interface IExtendedSpotWallet extends ISpotWallet {
  image: string
  hasMarkets: boolean
}

export interface IP2pWallet extends IMainWallet {
  type: 'p2p'
}

export interface IExtendedP2pWallet extends IP2pWallet {
  image: string
  hasMarkets: boolean
}

export interface ILinks {
  first: string
  last: string
  next: null
  prev: null
}

export interface IMetaLink {
  url: string | null
  label: string
  active: boolean
}

export interface IMeta {
  current_page: number
  from: number
  last_page: number
  path: string
  per_page: number
  to: number
  total: number
  links: IMetaLink[]
}

export interface IRFWithPagination<T = unknown> {
  data: T
  links: ILinks | null
  meta: IMeta | null
}

export interface IRFWithPaginationAndLoading<T = unknown> extends IRFWithPagination<T> {
  isLoading: boolean
}

export interface IAllWallets {
  cross: ICrossWallet[]
  isolated: IIsolatedWalletsByMarket[]
  spot: ISpotWallet[]
  p2p: IP2pWallet[]
}

export interface User {
  id: string
  username: string
  mobile_phone: string
  email: string
  permissions: string[]
  ip: string
  registration_ip: string
  language: string
  country: string
  front_id: string
  test: boolean
  update_time: string
  last_login_time: string
  last_action_time: string
  registration_time: string
  role: string
  nickname?: string
  two_fa_enabled: boolean
  wallets: Wallet[]
  groups?: { [index: string]: Group }
  unread_notifications_count?: number
}

export interface Group {
  name: string
  type: string
}

export interface IJwtDecodeData {
  id: number
  exp: number
  username: string
  role: 'admin' | 'operator'
}

export interface IConfigCurrencyInterface {
  code: string
  create_by_default?: number
  deleted: number | boolean | undefined
  deposit_enabled: number | boolean
  enabled: number | boolean
  tax_deposit?: number
  tax_withdrawal?: number
  title: string
  type: string
  withdrawal_enabled: number | boolean
}

export interface IStatusConnection {
  data: string
}

export interface IResetDataRequest {
  email: string
  password: string
  password_confirmation: string
  token: string
}

export interface IChangePasswordDataRequest {
  current_password: string
  new_password: string
  new_password_confirmation: string
}
export interface ObjectInterface {
  [key: string]: string
}

export interface ITermsAndConditionsAvailableUrl {
  value: string
}

export interface ITermsAndConditions {
  id: number
  title: string
  content: string
  status_id: number | null
  status: string | null
  available_urls: ITermsAndConditionsAvailableUrl[]
}

export type IPagination = {
  page?: string
  per_page?: string
}

export type ISortParams = {
  [key: string]: { value: 'asc' | 'desc' | null }
}

export type IRequestParam = {
  [key: string]: { value: string | null; filterType?: string | null }
}

export type TableColumn = {
  [key: string]: {
    sort?: boolean
    title?: string | JSX.Element
  }
}
