import { RootState } from '@Redux/store'
import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

import {
  changePassword,
  check,
  login,
  logoutPost,
  refreshToken,
  register,
  resetPass,
  resetPassLink,
} from '@App/api/auth'
import { changeNickname, createPaymentIntent, deposit, disable2FA, enable2FA, withdrawal } from '@App/api/user'
import authCookieManager from '@App/cookies/auth_cookie_manager'
import localStorageManager from '@App/cookies/local_storage_manager'
import type { IUser } from '@App/redux/interfaces'
import { getPaymentGatewaysAsync } from '@App/redux/slices/profile/payment/PaymentGatewaysSlice'
import { authToFrontServiceCommon } from '@App/redux/slices/sockets/CommonConnectionSlice'
import { generateRequestKey } from '@App/sockets/helpers'

import { closeFrontServiceSocket } from '@Redux/slices/sockets/FrontServiceConnectionSlice'
import {
  closeProxyServiceSocket,
  setTokenProxyServiceConnection,
  updateTokenProxyServiceConnection,
} from '@Redux/slices/sockets/ProxyServiceConnectionSlice'
import { getTermsAndConditionsAsync } from '@Redux/slices/terms_and_conditions/TermsAndConditionsSlice'

import {
  IDepositRequest,
  ILoginRequest,
  User,
  IWithdrawalRequest,
  IJwtDecodeData,
  IResetDataRequest,
  IChangePasswordDataRequest,
  Wallet,
} from '@Interfaces/CommonInterfaces'

import {
  addErrorNotify,
  addSuccessNotify,
  createNotificationObject,
  SimpleNotify,
} from '../notifications/NotificationSlice'

import { ILoginData, ILoginDataError, IRegisterData, IRegisterResponse } from './types'

import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: IUser = {
  login: false,
  user_id: 0,
  username: localStorageManager.get('username'),
  user_info: {
    id: '',
    username: '',
    nickname: '',
    mobile_phone: '',
    email: '',
    permissions: [],
    ip: '',
    registration_ip: '',
    language: '',
    country: '',
    front_id: '',
    test: false,
    update_time: '',
    last_login_time: '',
    last_action_time: '',
    registration_time: '',
    role: '',
    two_fa_enabled: false,
    wallets: [],
    groups: {},
  },
  is_loading: false,
  is_success: false,
  check_load: false,
  theme: localStorageManager.get('theme') || 'dark',
  curr_wallet: localStorageManager.get('curr_wallet') || 'USD',
  favorite_ticker_id: JSON.parse(localStorageManager.get('favoriteItems') ?? '[]'),
  required_two_fa: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<string>) => {
      state.login = true
      state.username = action.payload
    },
    setLoadingLogin: (state) => {
      state.is_loading = true
    },
    logout: (state) => {
      state.login = false
      state.username = ''
      state.user_info = null

      logoutPost()
    },
    clientLogout: (state) => {
      state.login = false
      state.username = ''
      state.user_info = null
    },

    checkUser: (state) => {
      state.login = true
    },
    changeTheme: (state) => {
      state.theme = state.theme === 'dark' ? 'light' : 'dark'
    },
    setCurrWallet: (state, action: PayloadAction<string>) => {
      state.curr_wallet = action.payload
      localStorageManager.set('curr_wallet', `${action.payload}`)
    },
    setFavoriteTickerId: (state) => {
      state.favorite_ticker_id = JSON.parse(localStorageManager.get('favoriteItems') ?? '[]')
    },
    clearVerificationState: (state) => {
      state.required_two_fa = false
      window.history.replaceState({}, document.title)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, action: any) => {
        state.login = true
        state.username = action.payload.username
        state.user_id = action.payload.id
        state.is_loading = false
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.is_loading = false
        if (action.payload === 'TWO_FA_REQUIRED') {
          state.required_two_fa = true
        }
      })
      .addCase(checkAsync.pending, (state) => {
        state.check_load = true
      })
      .addCase(checkAsync.fulfilled, (state, action: any) => {
        const data: IJwtDecodeData = jwtDecode(action.payload.token)

        state.check_load = false
        if (!state.login) {
          state.login = true
          state.username = data.username
          state.user_id = data.id
          state.user_info = action.payload.user_info

          if (state.user_info) {
            console.log(action.payload)
            state.user_info.wallets = action.payload.user_info.wallets.map((w: any) => new Wallet(w))
          }
        }
      })
      .addCase(checkAsync.rejected, (state) => {
        state.check_load = false
        if (state.login) {
          state.login = false
          state.username = ''
          state.user_info = null
          state.user_id = 0
        }
      })
      .addCase(meAsyncAfterLogin.pending, (state) => {
        state.check_load = true
      })
      .addCase(meAsyncAfterLogin.fulfilled, (state, action: any) => {
        const data: IJwtDecodeData = jwtDecode(action.payload.token)

        state.check_load = false

        state.login = true
        state.username = data.username
        state.user_id = data.id
        state.user_info = action.payload.user_info
        if (state.user_info) {
          state.user_info.wallets = action.payload.user_info.wallets.map((w: any) => new Wallet(w))
        }
      })
      .addCase(meAsyncAfterLogin.rejected, (state) => {
        state.check_load = false

        state.login = false
        state.username = ''
        state.user_info = null
        state.user_id = 0
      })
      .addCase(setUserInfo.fulfilled, (state, action: any) => {
        if (action.payload.user_info) {
          state.user_info = action.payload.user_info
          if (state.user_info) {
            state.user_info.wallets = action.payload.user_info.wallets.map((w: any) => new Wallet(w))
          }
        }
      })
      .addCase(asyncLogout.fulfilled, (state) => {
        state.login = false
        state.username = ''
        state.user_info = null
        localStorageManager.clear()
      })

      .addCase(asyncResetPassLink.pending, (state) => {
        state.is_loading = true
      })
      .addCase(asyncResetPassLink.fulfilled, (state) => {
        state.is_loading = false
      })
      .addCase(asyncResetPassLink.rejected, (state) => {
        state.is_loading = false
      })

      .addCase(asyncResetPass.pending, (state) => {
        state.is_loading = true
        state.is_success = false
      })
      .addCase(asyncResetPass.fulfilled, (state) => {
        state.is_loading = false
        state.is_success = true
      })
      .addCase(asyncResetPass.rejected, (state) => {
        state.is_loading = false
      })

      .addCase(asyncEnable2FA.pending, (state) => {
        state.is_loading = true
      })
      .addCase(asyncEnable2FA.fulfilled, (state) => {
        state.is_loading = false

        if (state.user_info) {
          state.user_info.two_fa_enabled = true
        }
      })
      .addCase(asyncEnable2FA.rejected, (state) => {
        state.is_loading = false
      })

      .addCase(asyncDisable2FA.pending, (state) => {
        state.is_loading = true
      })

      .addCase(asyncDisable2FA.fulfilled, (state) => {
        state.is_loading = false
        state.required_two_fa = false

        if (state.user_info) {
          state.user_info.two_fa_enabled = false
        }
      })
      .addCase(asyncDisable2FA.rejected, (state) => {
        state.is_loading = false
      })

      .addCase(changeNicknameAsync.fulfilled, (state, action) => {
        if (action.payload.nickname) {
          if (state.user_info) {
            state.user_info.nickname = action.payload.nickname
          }
        }
      })
  },
})
let timeout_update_token: null | number = null

export const refreshTokenAsync = createAsyncThunk('user/refresh', async (_, { dispatch }) => {
  const refresh_token = authCookieManager.getRefreshToken()
  let result
  if (refresh_token) {
    result = await refreshToken(refresh_token)

    if (result.data.access_token && result.data.refresh_token) {
      dispatch(createNotificationObject({ message: 'Refresh token', status: 'info', id: generateRequestKey('global') }))

      dispatch(setUserExpTimeForToken())

      dispatch(updateTokenProxyServiceConnection(result.data.access_token))

      dispatch(authToFrontServiceCommon(result.data.access_token))
    }
  } else {
    dispatch(asyncLogout())
  }

  return result
})

export const loginAsync = createAsyncThunk('user/login', async (data: ILoginRequest, { dispatch, rejectWithValue }) => {
  const result: ILoginData | ILoginDataError = await login(data.username, data.password, data.verification_code)

  if ('username' in result) {
    const token = result.access_token ?? ''

    dispatch(setTokenProxyServiceConnection(token))
    dispatch(authToFrontServiceCommon(token))
    dispatch(setUserExpTimeForToken())
    dispatch(
      createNotificationObject({ message: 'Success login', id: generateRequestKey('global'), status: 'success' }),
    )
    dispatch(meAsyncAfterLogin())
    return result
  } else {
    if ('data' in result && result.data?.error_code === 'TWO_FA_REQUIRED') {
      return rejectWithValue(result.data?.error_code)
    } else {
      dispatch(
        createNotificationObject({
          message: result.message ? result.message : 'Try login later',
          id: generateRequestKey('global'),
          status: 'error',
        }),
      )
    }

    return rejectWithValue('')
  }
})

export const registerAsync = createAsyncThunk(
  'user/register',
  async (payload: IRegisterData, { dispatch, rejectWithValue }) => {
    const res: IRegisterResponse | string = await register(payload)

    if (typeof res === 'string') {
      dispatch(
        createNotificationObject({
          message: res ?? 'Try login later',
          id: generateRequestKey('global'),
          status: 'error',
        }),
      )
      return rejectWithValue('')
    }
    dispatch(loginAsync({ username: payload.username, password: payload.password }))
  },
)

export const checkAsync = createAsyncThunk('user/check', async (_, { dispatch }) => {
  if (!authCookieManager.existsTokens()) {
    throw new Error('Invalid token')
  }

  const data: { token?: string | null; user_info: User | null } = await check()

  if (!data.token) {
    throw new Error('Invalid token')
  }

  dispatch(setTokenProxyServiceConnection(data.token))
  dispatch(authToFrontServiceCommon(data.token))
  dispatch(getTermsAndConditionsAsync())
  // dispatch(getPaymentGatewaysAsync())

  return data
})

export const meAsyncAfterLogin = createAsyncThunk('user/me', async (_, { dispatch }) => {
  const data: { token?: string | null; user_info: User | null } = await check()

  if (!data.token) {
    throw new Error('Invalid token')
  }

  dispatch(setTokenProxyServiceConnection(data.token))
  dispatch(authToFrontServiceCommon(data.token))
  dispatch(getTermsAndConditionsAsync())
  // dispatch(getPaymentGatewaysAsync())

  return data
})

export const changeNicknameAsync = createAsyncThunk('api/clients/update', async (params: { nickname: string }) => {
  await changeNickname(params.nickname)

  return { nickname: params.nickname }
})

export const setUserInfo = createAsyncThunk('user/setUserInfo', async (user_info: User) => {
  return { user_info }
})

export const setUserExpTimeForToken = createAsyncThunk('user/setUserExpTimeForToken', async (_, { dispatch }) => {
  if (authCookieManager.existsTokens()) {
    const token = authCookieManager.getAccessTokenWithDefault()
    // @ts-ignore
    const data: { exp: number } = jwtDecode(token)

    const time_left = data.exp * 1000 - new Date().getTime() - 60 * 1000

    if (time_left <= 0) {
      await refreshTokenAsync()
    } else {
      if (timeout_update_token) {
        clearTimeout(timeout_update_token)
      }
      timeout_update_token = window.setTimeout(() => dispatch(refreshTokenAsync()), time_left)
    }
  } else {
    dispatch(asyncLogout())
  }
  return
})

export const asyncLogout = createAsyncThunk('user/asyncLogout', async (_, { dispatch }) => {
  dispatch(closeFrontServiceSocket())
  dispatch(closeProxyServiceSocket())
  if (timeout_update_token) {
    clearTimeout(timeout_update_token)
  }

  return _
})

export const depositAsync = createAsyncThunk('user/deposit', async (data: any) => {
  const result: any = await deposit(data)
  return result
})

export const createPaymentIntentAsync = createAsyncThunk('user/createIntent', async (data: any) => {
  const result: any = await createPaymentIntent(data)
  return result
})

export const withdrawalAsync = createAsyncThunk('user/withdrawal', async (data: IWithdrawalRequest) => {
  const result: any = await withdrawal(data.currency, data.value)
  return result
})

export const asyncResetPassLink = createAsyncThunk('user/resetPassLink', async (email: string, { dispatch }) => {
  const response: any = await resetPassLink(email)
  response.status === false
    ? dispatch(addErrorNotify({ message: response.data.message }))
    : dispatch(
        addSuccessNotify({
          message: 'Link to reset password has been successfully sent. Please, check your email to continue',
        }),
      )
})

export const asyncResetPass = createAsyncThunk(
  'user/resetPass',
  async (data: IResetDataRequest, { dispatch, rejectWithValue }) => {
    const err: any = await resetPass(data)
    err
      ? dispatch(addErrorNotify({ message: err }))
      : dispatch(
          addSuccessNotify({
            message: 'Your password has been successfully changed',
          }),
        )

    if (err) return rejectWithValue('')
  },
)

export const asyncChangePass = createAsyncThunk(
  'user/changePass',
  async (data: IChangePasswordDataRequest, { dispatch, rejectWithValue }) => {
    const err: any = await changePassword(data)
    err
      ? dispatch(addErrorNotify({ message: err }))
      : dispatch(
          addSuccessNotify({
            message: 'Your password has been successfully changed',
          }),
        )

    if (err) return rejectWithValue('')
  },
)

export const asyncEnable2FA = createAsyncThunk(
  'user/two-factor/enable',
  async (params: { code: string; secretKey: string }, { dispatch, rejectWithValue }) => {
    const err: any = await enable2FA(params.code, params.secretKey)
    if (err) {
      dispatch(addErrorNotify({ message: err }))
      return rejectWithValue('')
    }
  },
)

export const asyncDisable2FA = createAsyncThunk(
  'user/two-factor/disable',
  async (params: { code: string }, { dispatch, rejectWithValue }) => {
    const err: any = await disable2FA(params.code)
    if (err) {
      dispatch(addErrorNotify({ message: err }))
      return rejectWithValue('')
    }
  },
)

export const isLogin = (state: RootState) => (state.user ? state.user.login : false)
export const isPending = (state: RootState) => (state.user ? state.user.is_loading : false)
export const isSuccess = (state: RootState) => (state.user ? state.user.is_success : false)
export const requiredTwoFA = (state: RootState) => (state.user ? state.user.required_two_fa : false)
export const userState = (state: RootState) => state.user
export const userWalletsFiat = (state: RootState) => {
  const wallets = state.user.user_info?.wallets ? state.user.user_info.wallets : []

  return wallets.filter((wallet) => wallet.type === 'fiat')
}

export const {
  setLogin,
  logout,
  clientLogout,
  checkUser,
  setLoadingLogin,
  changeTheme,
  setCurrWallet,
  setFavoriteTickerId,
  clearVerificationState,
} = userSlice.actions
export default userSlice.reducer
