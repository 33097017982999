import { WalletTypes } from '@Redux/interfaces'
import { RootState } from '@Redux/store'

export const currentWallet = (state: RootState) => (state.user ? state.user.curr_wallet : '')

export const marginWallets = (state: RootState) =>
  state.user
    ? state.user.user_info?.wallets.filter(
        (w) => w.type === WalletTypes.TYPE_MARGIN_CROSS || w.type === WalletTypes.TYPE_MARGIN_ISOLATED,
      )
    : []

export const fiatCoinWallets = (state: RootState) => {
  return state.user
    ? state.user.user_info?.wallets.filter((w) => w.type === WalletTypes.TYPE_FIAT || w.type === WalletTypes.TYPE_COIN)
    : []
}

export const coinWallets = (state: RootState) => {
  return state.user ? state.user.user_info?.wallets.filter((w) => w.type === WalletTypes.TYPE_COIN) : []
}

export const exactWallet = (state: RootState, currency_code: string, walletType?: string) =>
  state.user
    ? state.user.user_info?.wallets.find(
        (wallet) => wallet.currency_code === currency_code && (walletType ? wallet.type == walletType : true),
      )
    : null
