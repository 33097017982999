import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getTimezones } from '@App/api/config'
import { RootState } from '@App/redux/store'

import { ICountry, ITimeZone } from './interfaces'

import type { PayloadAction } from '@reduxjs/toolkit'

const timeZonesSlice = createSlice({
  name: 'timezones',
  initialState: {
    data: [],
    isLoading: false,
  } as {
    data: ITimeZone[]
    isLoading: boolean
  },
  reducers: {
    settimezones(state, action: PayloadAction<ITimeZone[]>) {
      state.data = action.payload
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimezonesAsync.fulfilled, (state, action: any) => {
      state.data = action.payload
      state.isLoading = false
    })
    builder.addCase(getTimezonesAsync.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTimezonesAsync.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const getTimezonesAsync = createAsyncThunk(
  'timezones/get',
  async (_, { getState, rejectWithValue, fulfillWithValue }) => {
    const stateTimezones = (getState as () => RootState)().timezones.data

    if (!stateTimezones.length) {
      const timezones = await getTimezones()

      if (typeof timezones === 'string') {
        return rejectWithValue('error')
      }

      return fulfillWithValue(timezones)
    }
    return fulfillWithValue(stateTimezones)
  },
)

export default timeZonesSlice.reducer
