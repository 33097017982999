import AAVE from './aave.png'
import ADA from './ada.png'
import ALGO from './algo.jpeg'
import AU from './au.png'
import AVAX from './avax.png'
import BAT from './bat.png'
import BCH from './bch.png'
import BIDR from './bidr.png'
import BNB from './bnb.png'
import BTC from './btc.png'
import DOGE from './doge.png'
import DOT from './dot.png'
import ENJ from './enj.png'
import ERD from './erd.jpeg'
import ETC from './etc.png'
import ETH from './eth.png'
import EUR from './eur.png'
import FIAT from './fiat.png'
import FTM from './ftm.png'
import GBP from './gbp.png'
import LEND from './lend.png'
import LINK from './link.png'
import LTC from './ltc.png'
import MANA from './mana.png'
import MATIC from './matic.png'
import PAX from './pax.png'
import RUB from './rub.png'
import SHIB from './shib.png'
import SOL from './sol.jpg'
import SXP from './sxp.png'
import TRX from './trx.png'
import TRY from './try.png'
import USD from './usd.png'
import USDT from './usdt.png'
import XLM from './xlm.png'
import XMR from './xmr.png'
import XRP from './xrp.png'

export interface ICurrenciesImages {
  [key: string]: string
}

export const currenciesImages: ICurrenciesImages = {
  AAVE: AAVE,
  ADA: ADA,
  ALGO: ALGO,
  AVAX: AVAX,
  BAT: BAT,
  BCH: BCH,
  BIDR: BIDR,
  BNB: BNB,
  BTC: BTC,
  DOGE: DOGE,
  DOT: DOT,
  ENJ: ENJ,
  ERD: ERD,
  ETC: ETC,
  ETH: ETH,
  EUR: EUR,
  FIAT: FIAT,
  FTM: FTM,
  LEND: LEND,
  LINK: LINK,
  LTC: LTC,
  MANA: MANA,
  MATIC: MATIC,
  PAX: PAX,
  RUB: RUB,
  SHIB: SHIB,
  SXP: SXP,
  TRX: TRX,
  TRY: TRY,
  USD: USD,
  USDT: USDT,
  XLM: XLM,
  XRP: XRP,
  XMR: XMR,
  SOL: SOL,
  GBP: GBP,
  AU: AU,
}
