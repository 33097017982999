import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getInvoicesData } from '@App/api/profile/invoice'
import { ParamsByFilter } from '@App/helpers/requestByFilters'

interface InvoiceStateInterface {
  invoices: any
  pagination: {
    page: number
    per_page: number
    last_page: number
    from: number
    to: number
    total: number
  }
  isLoading: boolean
}

const initialState: InvoiceStateInterface = {
  invoices: [],
  pagination: {
    from: 0,
    last_page: 0,
    page: 0,
    per_page: 0,
    to: 0,
    total: 0,
  },
  isLoading: false,
}

export const getInvoiceAsync = createAsyncThunk('invoice/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getInvoicesData(filters)
  if (data?.data && data?.meta) {
    return {
      invoices: data?.data,
      pagination: { ...data?.meta },
    }
  } else {
    return {
      invoices: [],
      pagination: {
        from: 0,
        last_page: 0,
        page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  }
})

export const InvoicesSlice = createSlice({
  name: 'Invoices',
  initialState,
  reducers: {
    getInvoices: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoiceAsync.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(getInvoiceAsync.fulfilled, (state, action: any) => {
      state.invoices = action.payload.invoices
      state.pagination = action.payload.pagination
      state.isLoading = false
    })
  },
})

export const { getInvoices } = InvoicesSlice.actions

export default InvoicesSlice.reducer
