import cn from 'classnames'

import c from './LoaderBlocks.module.scss'

export const LoaderBlocks = ({ className = '', size = '' }) => {
  return (
    <div className={`${cn(c[className], c[size], c.preloader)}`}>
      <div className={c.stick} />
      <div className={c.stick} />
      <div className={c.stick} />
      <div className={c.stick} />
    </div>
  )
}
