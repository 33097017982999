import { SetStateAction, Suspense, useEffect, useState } from 'react'

import { lazily } from 'react-lazily'

import { useAppDispatch, useAppSelector } from '@App/hooks'
import { MODAL_STATE, MODAL_TYPES } from '@App/redux/slices/modals/enums'
import { closeModal } from '@App/redux/slices/modals/ModalSlice'

import TermAndConditionsModal from '@UI/crypto_exchange/desktop/components/modals/TermAndConditionsModal'

import { LoaderBlocks } from '../../common/Loader/components/LoaderBlocks'

const {
  BorrowRepayModal,
  EmailChangeVerificationModal,
  MarginTransferModal,
  SelectCurrencyModal,
  ChangeNicknameModal,
  DeleteTrustDeviceModal,
  RequestApprovedModal,
  FundAccountModal,
  ConfirmModal,
  SimpleEarnModal,
  OfferConfirmModal,
  OfferCreatedModal,
  SelectP2PPayMethodModal,
  SelectP2PAccountsModal,
  AddP2PPayAccountModal,
  PaymentConfirmationModal,
  NotificationInfoModal,
  LoanBorrowModal,
  RenewalModal,
  LoanRepayModal,
  LoanAdjustLTVModal,
} = lazily(() => import('@UI/crypto_exchange/desktop/components/modals'))

export default function ModalContainer() {
  const dispatch = useAppDispatch()

  const modalData = useAppSelector((state) => state.modals)

  const modalOpen = modalData.isOpen === MODAL_STATE.OPEN

  const isModalOpen = useAppSelector((state) => state.modals.isOpen)

  const [scrollBarWidth, setScrollBarWidth] = useState<SetStateAction<number | null>>(null)

  const handleCloseModal = () => dispatch(closeModal())

  const showScroll = () => {
    document.body.classList.remove('overflow')
    document.body.style.paddingRight = ''
  }

  const hideScroll = () => {
    document.body.classList.add('overflow')
    document.body.style.paddingRight = `${scrollBarWidth}px`
  }

  useEffect(() => {
    if (isModalOpen === MODAL_STATE.OPEN) {
      setTimeout(() => hideScroll(), 10)
    } else {
      setTimeout(() => showScroll(), 10)
    }
  }, [isModalOpen])

  let modal = null

  switch (modalData.modalType) {
    case MODAL_TYPES.BORROW_REPAY:
      modal = <BorrowRepayModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.CHANGE_NICKNAME:
      modal = <ChangeNicknameModal isOpen={modalOpen} setIsOpen={handleCloseModal} />
      break
    case MODAL_TYPES.DELETE_TRUST_DEVICE:
      modal = <DeleteTrustDeviceModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.EMAIL_CHANGE_VERIFICATION:
      modal = <EmailChangeVerificationModal isOpen={modalOpen} setIsOpen={handleCloseModal} />
      break
    case MODAL_TYPES.MARGIN_TRANSFER:
      modal = <MarginTransferModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.SELECT_CURRENCY:
      modal = <SelectCurrencyModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.REQUEST_APPROVED:
      modal = <RequestApprovedModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.FUND_ACCOUNT:
      modal = <FundAccountModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.CONFIRM_MODAL:
      modal = <ConfirmModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.SIMPLE_EARN_MODAL:
      modal = <SimpleEarnModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.P2P_OFFER_CONFIRM_MODAL:
      modal = <OfferConfirmModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.P2P_OFFER_CREATED_MODAL:
      modal = <OfferCreatedModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.SELECT_P2P_PAY_METHOD:
      modal = <SelectP2PPayMethodModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.SELECT_P2P_ACCOUNT:
      modal = <SelectP2PAccountsModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.ADD_P2P_PAY_ACCOUNT:
      modal = <AddP2PPayAccountModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.P2P_PAYMENT_CONFIRMATION:
      modal = <PaymentConfirmationModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.NOTIFICATION_INFO:
      modal = <NotificationInfoModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.LOAN_BORROW_MODAL:
      modal = <LoanBorrowModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.RENEWAL_MODAL:
      modal = <RenewalModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.LOAN_REPAY_MODAL:
      modal = <LoanRepayModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.LOAN_ADJUST_LTV_MODAL:
      modal = <LoanAdjustLTVModal isOpen={modalOpen} setIsOpen={handleCloseModal} {...modalData.data} />
      break
    case MODAL_TYPES.TERMS_AND_CONDITIONS:
      modal = (
        <TermAndConditionsModal
          isOpen={modalOpen}
          setIsOpen={handleCloseModal}
          withOutsideClick={false}
          currentTerm={modalData.data}
        />
      )
      break
    default:
      return null
  }

  return <Suspense fallback={<LoaderBlocks />}>{modal}</Suspense>
}
