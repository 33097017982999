import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  getAllWalletsDetails,
  getCrossWalletsDetails,
  getIsolatedWalletsDetails,
  getP2pWalletsDetails,
  getSpotWalletsDetails,
} from '@App/api/profile/wallet'
import { ParamsByFilter } from '@App/helpers/requestByFilters'

import {
  CrossWallet,
  ICrossWallet,
  IIsolatedWalletsByMarket,
  IsolatedWallet,
  IP2pWallet,
  ISpotWallet,
  IRFWithPaginationAndLoading,
  IRFWithPagination,
} from '@Interfaces/CommonInterfaces'

interface WalletsStateInterface {
  cross_wallets: IRFWithPaginationAndLoading<ICrossWallet[]>
  isolated_wallets: IRFWithPaginationAndLoading<IIsolatedWalletsByMarket[]>
  spot_wallets: IRFWithPaginationAndLoading<ISpotWallet[]>
  p2p_wallets: IRFWithPaginationAndLoading<IP2pWallet[]>
}

type VariantWallets = ICrossWallet | IIsolatedWalletsByMarket | ISpotWallet | IP2pWallet

class WalletDefValue {
  data: any[] = []
  links = null
  meta = null
  isLoading = false

  setDataFormResponse(data: IRFWithPagination<VariantWallets[]>) {
    return {
      data: data.data.map((wallet: any) => {
        return wallet
      }),
      links: data.links,
      meta: data.meta,
    }
  }

  setData(data: VariantWallets[]) {
    this.data = data.map((wallet: any) => {
      return wallet
    })
  }
}

const initialState: WalletsStateInterface = {
  cross_wallets: new WalletDefValue(),
  isolated_wallets: new WalletDefValue(),
  spot_wallets: new WalletDefValue(),
  p2p_wallets: new WalletDefValue(),
}

export const getCrossWalletsAsync = createAsyncThunk('wallet/cross/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getCrossWalletsDetails(filters)
  return {
    cross_wallets: data?.data ? new WalletDefValue().setDataFormResponse(data) : new WalletDefValue(),
  }
})

export const getIsolatedWalletsAsync = createAsyncThunk(
  'wallet/isolated/get',
  async (filters: ParamsByFilter[] = []) => {
    const data = await getIsolatedWalletsDetails(filters)
    return {
      isolated_wallets: data?.data ? new WalletDefValue().setDataFormResponse(data) : new WalletDefValue(),
    }
  },
)

export const getSpotWalletsAsync = createAsyncThunk('wallet/spot/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getSpotWalletsDetails(filters)
  return {
    spot_wallets: data?.data ? new WalletDefValue().setDataFormResponse(data) : new WalletDefValue(),
  }
})

export const getP2pWalletsAsync = createAsyncThunk('wallet/p2p/get', async (filters: ParamsByFilter[] = []) => {
  const data = await getP2pWalletsDetails(filters)
  return {
    p2p_wallets: data?.data ? new WalletDefValue().setDataFormResponse(data) : new WalletDefValue(),
  }
})

export const getWalletsAsync = createAsyncThunk('wallets/get', async () => {
  const data = await getAllWalletsDetails()

  const newWallets = {
    cross_wallets: new WalletDefValue(),
    isolated_wallets: new WalletDefValue(),
    spot_wallets: new WalletDefValue(),
    p2p_wallets: new WalletDefValue(),
  }

  if (data) {
    newWallets.cross_wallets.setData(data.cross)
    newWallets.spot_wallets.setData(data.spot)
    newWallets.isolated_wallets.setData(data.isolated)
    newWallets.p2p_wallets.setData(data.p2p)
  }
  return newWallets
})

export const WalletSlice = createSlice({
  name: 'Wallets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWalletsAsync.pending, (state) => {
        state.cross_wallets.isLoading = true
        state.isolated_wallets.isLoading = true
        state.spot_wallets.isLoading = true
        state.p2p_wallets.isLoading = true
      })
      .addCase(getWalletsAsync.fulfilled, (state, action: any) => {
        state.cross_wallets = {
          data: action.payload.cross_wallets.data.map((w: any) => new CrossWallet(w)),
          links: action.payload.cross_wallets.links,
          meta: action.payload.cross_wallets.meta,
          isLoading: false,
        }
        state.isolated_wallets = {
          data: action.payload.isolated_wallets.data.map((w: any) => new IsolatedWallet(w)),
          links: action.payload.isolated_wallets.links,
          meta: action.payload.isolated_wallets.meta,
          isLoading: false,
        }
        state.spot_wallets = action.payload.spot_wallets
        state.p2p_wallets = action.payload.p2p_wallets
      })

      .addCase(getCrossWalletsAsync.pending, (state) => {
        state.cross_wallets.isLoading = true
      })
      .addCase(getCrossWalletsAsync.fulfilled, (state, action: any) => {
        state.cross_wallets = {
          data: action.payload.cross_wallets.data.map((w: any) => new CrossWallet(w)),
          links: action.payload.cross_wallets.links,
          meta: action.payload.cross_wallets.meta,
          isLoading: false,
        }
      })

      .addCase(getIsolatedWalletsAsync.pending, (state) => {
        state.isolated_wallets.isLoading = true
      })
      .addCase(getIsolatedWalletsAsync.fulfilled, (state, action: any) => {
        state.isolated_wallets = {
          data: action.payload.isolated_wallets.data.map((w: any) => new IsolatedWallet(w)),
          links: action.payload.isolated_wallets.links,
          meta: action.payload.isolated_wallets.meta,
          isLoading: false,
        }
      })

      .addCase(getSpotWalletsAsync.pending, (state) => {
        state.spot_wallets.isLoading = true
      })
      .addCase(getSpotWalletsAsync.fulfilled, (state, action: any) => {
        state.spot_wallets = action.payload.spot_wallets
      })

      .addCase(getP2pWalletsAsync.pending, (state) => {
        state.p2p_wallets.isLoading = true
      })
      .addCase(getP2pWalletsAsync.fulfilled, (state, action: any) => {
        state.p2p_wallets = action.payload.p2p_wallets
      })
  },
})

export default WalletSlice.reducer
