import {
  IP2POfferCreateRequest,
  IP2POrderRequest,
  P2PMarket,
  P2PMarketRangePrice,
  IP2POfferRequest,
  P2POrder,
  AdvertiserOffers,
  P2POffer,
  PayMethod,
} from '@Redux/interfaces/p2p'

import { $authHost, $host } from '@App/api'
import { getDataJWTWrapper, getDataWrapper } from '@App/api/helper'
import { IDataResponse } from '@App/api/interfaces'
import requestByFilters, { ParamsByFilter } from '@App/helpers/requestByFilters'

export const getP2POffersData = async (filters: ParamsByFilter[] = []): Promise<IDataResponse<P2POffer[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''
  return await getDataWrapper($host.get(`api/p2p/offers${filterString}`))
}

export const getP2POfferInfo = async (id: number): Promise<IDataResponse<P2POffer>> =>
  await getDataWrapper($authHost.get(`api/p2p/offers/${id}`))

export const createP2POffers = async (data: IP2POfferCreateRequest): Promise<IDataResponse<P2POffer>> =>
  await getDataWrapper($authHost.post('api/p2p/offers', data))

export const updateP2POffer = async (id: number, data: IP2POfferRequest) =>
  await getDataWrapper($authHost.put(`api/p2p/offers/${id}`, data))

export const deleteP2POffer = async (id: number) => await getDataJWTWrapper($authHost.delete(`api/p2p/offers/${id}`))

export const createP2POrders = async (offer_id: number, data: IP2POrderRequest): Promise<IDataResponse<P2POrder>> =>
  await getDataWrapper($authHost.post(`api/p2p/offers/${offer_id}/orders`, data))

export const getP2PMarkets = async (): Promise<IDataResponse<P2PMarket[]>> =>
  await getDataWrapper($host.get(`api/p2p/markets`))

export const getP2PPayMethods = async (filters: ParamsByFilter[] = []): Promise<IDataResponse<PayMethod[]>> => {
  const filterString = filters.length ? `?${requestByFilters(filters)}` : ''

  return await getDataWrapper($authHost.get(`api/p2p/pay-methods${filterString}`))
}

export const getP2PAdvertiserOffers = async (id: string): Promise<IDataResponse<AdvertiserOffers>> =>
  await getDataWrapper($host.get(`api/p2p/offers/user/${id}`))

export const getP2PMarketRangePrice = async (): Promise<IDataResponse<P2PMarketRangePrice[]>> =>
  await getDataWrapper($host.get(`api/p2p/markets/price-ranges`))
